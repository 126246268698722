
hr.divider {
	padding: 0;
	border: none;
	border-block-start: solid 1px #eaebf7;
	color: #333;
	text-align: center;
	margin: .5rem 0;
	&:after {
		content: "or";
		display: inline-block;
		position: relative;
		top: -.9em;
		font-size: 1.2em;
		padding: 0 .25em;
		background: $white;
	}
}

.z-index-10 {
	z-index: 10;
}

.error {
	color: #f31212;
}

.error-image {
	background: url(../images/media/21.jpg);
	background-position: center;
	background-size: cover;
	background-attachment: fixed;
	&:before {
		position: fixed;
		content: '';
		width: 100%;
		height: 100%;
		display: block;
		z-index: 1;
		inset-inline-start: 0;
		 inset-inline-end: 0;
	}
}

.construction-image {
	background: url(../images/media/21.jpg);
	background-position: center;
	background-size: cover;
	background-attachment: fixed;
	&:before {
		position: fixed;
		content: '';
		width: 100%;
		height: 100%;
		display: block;
		z-index: 1;
		inset-inline-start: 0;
		 inset-inline-end: 0;
		background: linear-gradient(-225deg, rgba(72, 1, 255, 0.9) 0, rgba(121, 24, 242, 0.9) 48%, rgba(172, 50, 228, 0.9) 100%);
	}
}

.construction {
	z-index: 1;
	.btn {
		&.btn-icon {
			border: 1px solid $black-1;
			text-align: center;
			padding: 0;
			background: 0 0;
			font-size: 15px;
			color: $white-9;
			margin: 0 5px;
			border-radius: 50%;
			background: $black-1;
		}
		border-radius: 4px;
	}
	.form-control {
		border: 1px solid #c4c5c9;
		border-radius: 4px;
		padding-inline-start: 20px;
		width: 60%;
	}
	.input-group-1 {
		margin: 0 auto;
	}
	h3 {
		color: $white;
		font-size: 3.2rem;
		font-weight: 800 !important;
		margin-block-end: 0.4em !important;
		letter-spacing: 0;
		padding-block-end: 0;
		line-height: 1.2;
	}
	p {
		margin-block-end: 0;
		font-weight: 400;
		font-size: .9375em;
		letter-spacing: .06667em;
		color: $white-7;
	}
}

.construction-image .card {
	background: rgb(255, 255, 255);
}