.text-monospace {
	font-family: Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-justify {
	text-align: justify !important;
}

.text-nowrap {
	white-space: nowrap !important;
}

.text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.text-start {
	text-align: left !important;
}

.text-end {
	text-align: right !important;
}

.text-center {
	text-align: center !important;
}

@media (min-width: 576px) {
	.text-sm-left {
		text-align: left !important;
	}
	.text-sm-right {
		text-align: right !important;
	}
	.text-sm-center {
		text-align: center !important;
	}
}

@media (min-width: 768px) {
	.text-md-left {
		text-align: left !important;
	}
	.text-md-right {
		text-align: right !important;
	}
	.text-md-center {
		text-align: center !important;
	}
}

@media (min-width: 992px) {
	.text-lg-left {
		text-align: left !important;
	}
	.text-lg-right {
		text-align: right !important;
	}
	.text-lg-center {
		text-align: center !important;
	}
}

@media (min-width: 1280px) {
	.text-xl-left {
		text-align: left !important;
	}
	.text-xl-right {
		text-align: right !important;
	}
	.text-xl-center {
		text-align: center !important;
	}
}

.text-lowercase {
	text-transform: lowercase !important;
}

.text-uppercase {
	text-transform: uppercase !important;
}

.text-capitalize {
	text-transform: capitalize !important;
}

.font-weight-light {
	font-weight: 300 !important;
}

.font-weight-normal {
	font-weight: 400 !important;
}

.font-weight-semibold {
	font-weight: 500 !important;
}

.font-weight-semibold1 {
	font-weight: 600 !important;
}

.font-weight-bold {
	font-weight: 700 !important;
}

.font-weight-extrabold {
	font-weight: 800 !important;
}

.font-italic {
	font-style: italic !important;
}

.text-white {
	color: $white !important;
}

a.text-secondary {
	&:focus,
	&:hover {
		color: $secondary !important;
	}
}

.text-success {
	color: $success !important;
}

a.text-success {
	&:focus,
	&:hover {
		color: #3adfab !important;
	}
}

.text-info {
	color: $info !important;
}

a.text-info {
	&:focus,
	&:hover {
		color: #1594ef !important;
	}
}

.text-warning {
	color: $yellow !important;
}

a.text-warning {
	&:focus,
	&:hover {
		color: #f59713 !important;
	}
}

.text-danger {
	color: $danger !important;
}

a.text-danger {
	&:focus,
	&:hover {
		color: #f34a4a !important;
	}
}

.text-light {
	color: #f8f9fa !important;
}

a.text-light {
	&:focus,
	&:hover {
		color: #e8e9f3 !important;
	}
}

.text-dark {
	color: $color !important;
}

.text-body {
	color: #3d4e67 !important;
}

.text-muted {
	color: #636d8c !important;
}

.text-black-50 {
	color: $black-5 !important;
}

.text-white-50 {
	color: $white-5 !important;
}

.text-white-80 {
	color: $white-8 !important;
}

.text-hide {
	font: 0/0 a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0;
}

.text-wrap {
	font-size: 14px;
	line-height: 2;
	> {
		 :first-child {
			margin-block-start: 0;
		}
		 :last-child {
			margin-block-end: 0;
		}
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			margin-block-start: 1em;
		}
	}
}

.text-inherit {
	color: inherit !important;
}

.text-default {
	color: #4e5d78 !important;
}

.text-muted-dark {
	color: #5f6877 !important;
}

.tracking-tight {
	letter-spacing: -0.05em !important;
}

.tracking-normal {
	letter-spacing: 0 !important;
}

.tracking-wide {
	letter-spacing: 0.05em !important;
}

.leading-none {
	line-height: 1 !important;
}

.leading-tight {
	line-height: 1.3 !important;
}

.leading-normal {
	line-height: 1.5 !important;
}

.leading-loose {
	line-height: 2 !important;
}

.text-blue {
	color: #4049ec !important;
}

.text-indigo {
	color: $indigo !important;
}

.text-purple {
	color: #6d33ff !important;
}

.text-lightpink-red {
	color: #ff7088 !important;
}

.text-lightgreen {
	color: #26eda2 !important;
}

.text-pink {
	color: #f5127b !important;
}

.text-red {
	color: $danger !important;
}

.text-orange {
	color: #e67605 !important;
}

.text-yellow {
	color: $yellow !important;
}

.text-green {
	color: $success !important;
}

.text-teal {
	color: $teal !important;
}

.text-cyan {
	color: #17a2b8 !important;
}

.text-white {
	color: $white !important;
}

.text-gray {
	color: #858d97 !important;
}

.text-gray-dark {
	color: $dark !important;
}

.text-azure {
	color: $info !important;
}

.text-lime {
	color: #63e600 !important;
}
.heading-inverse {
	background-color: #333;
	color: $white;
	padding: 5px;
}

.heading-primary {
	color: $white;
	padding: 5px;
}

.heading-success {
	background-color: $success;
	color: $white;
	padding: 5px;
}

.heading-info {
	background-color: $info;
	color: $white;
	padding: 5px;
}

.heading-warning {
	background-color: $yellow;
	color: $white;
	padding: 5px;
}

.heading-danger {
	background-color: $danger;
	color: $white;
	padding: 5px;
}

.list-style-1 {
    list-style: inherit;
    padding-inline-start: inherit;
}

.list-style-1 ul {
    list-style: inherit;
    padding-inline-start: 30px;
}

.list-style-1 li {
    line-height: 30px;
}

.list-style2 {
    list-style: decimal;
    padding-inline-start: inherit;
}

.list-style2 ul {
    list-style: decimal;
    padding-inline-start: 30px;
}

.list-style2 li {
    line-height: 30px;
}

.list-style3 {
    list-style: circle !important;
    padding-inline-start: inherit;
}

.list-style3 ul {
    list-style: decimal;
    padding-inline-start: 30px;
}

.list-style3 li {
    line-height: 30px;
}

.list-style4 {
    list-style: lower-alpha;
    padding-inline-start: inherit;
}

.list-style4 ul {
    list-style: decimal;
    padding-inline-start: 30px;
}

.list-style4 li {
    line-height: 30px;
}

.list-style5 {
    list-style: square;
    padding-inline-start: inherit;
}

.list-style5 ul {
    list-style: decimal;
    padding-inline-start: 30px;
}

.list-style5 li {
    line-height: 30px;
}

.list-style6 {
    list-style: lower-roman;
    padding-inline-start: inherit;
}

.list-style6 ul {
    list-style: decimal;
    padding-inline-start: 30px;
}

.list-style6 li {
    line-height: 30px;
}

.order-list li {
    line-height: 30px;
}
