/*----Pricing Tables style ----*/

.pricingTable {
	.title:after {
		border-inline-start: 26px solid rgba(255, 56, 43, 0.1);
	}
	&:hover,
	&.advance-pricing {
		border: 1px solid rgb(255, 56, 43);
	}
}

.pri-header {
	padding: .75rem 1.25rem;
	margin-block-end: 0;
	background-color: #f1f1ff;
	border-block-end: 1px solid #efefff;
}

.pricing-table {
	text-align: center;
	-webkit-transition: .3s ease-in;
	-o-transition: .3s ease-in;
	transition: .3s ease-in;
	position: relative;
	z-index: 0;
	overflow: hidden;
	background: $white;
	border: 1px solid #dbddf1;
	margin-block-end: 30px;
	box-shadow: 0 0 12px 0 rgba(21, 17, 148, 0.06);
	border-radius: 4px;
	.price-header {
		position: relative;
		padding: 30px 0 25px 0;
		background-color: $background;
		-webkit-transition: .3s ease-in;
		-o-transition: .3s ease-in;
		transition: .3s ease-in;
		.permonth {
			position: absolute;
			inset-inline-start: 0;
			 inset-inline-end: 0;
			bottom: -20px;
			background-color: $white;
			padding: 10px 35px;
			border-radius: 25px;
			-webkit-box-shadow: 0px 0px 11px 0px rgba(27, 30, 58, 0.1);
			box-shadow: 0px 0px 11px 0px rgba(27, 30, 58, 0.1);
			font-size: 14px;
			font-weight: 500;
			margin: 0 3rem;
		       color:$color;
		}
	}
	&:hover .price-header .title {
		color: $white;
	}
	.price-header .title {
		color: #282828;
		font-size: 21px;
		line-height: 26px;
		text-transform: uppercase;
		font-weight: 400;
		margin-block-start: 20px;
		-webkit-transition: .3s ease-in;
		-o-transition: .3s ease-in;
		transition: .3s ease-in;
		margin-block-end: 15px;
	}
	&:hover .price {
		color: $white;
	}
	.price {
		font-size: 50px;
		color: #282828;
		font-weight: 500;
		-webkit-transition: .3s ease-in;
		-o-transition: .3s ease-in;
		transition: .3s ease-in;
		text-align: center;
		position: relative;
		transition: .3s ease-in;
		margin-block-end: 15px;
		.dollar {
			font-size: 33px;
			line-height: 33px;
			position: relative;
			top: -12px;
		}
	}
	.price-body ul {
		margin: 0;
		padding: 0;
		margin-block-start: 40px;
		li {
			&:last-child {
				border-block-end: none;
				margin-block-end: 0;
				padding-block-end: 0;
			}
			list-style: none;
			display: block;
			color: #282828;
			padding: 15px 0;
			-webkit-transition: .3s ease-in;
			-o-transition: .3s ease-in;
			transition: .3s ease-in;
			border-block-end: 1px solid $border;
			&:first-child {
				margin-block-start: 0;
			}
		}
	}
	.price-footer {
		margin-block-start: 40px;
		margin: 40px 20px 0 20px;
		.order-btn {
			margin-block-end: 30px;
		}
	}
}

.price-1 .list-unstyled li {
	line-height: 2;
}

.pricing-head {
	inset-inline-start: 0px;
	 inset-inline-end: 0px;
	position: absolute;
	top: 13%;
	font-size: 40px;
	color: $white;
}


/*---Pricing Tables--*/

.pricing {
	border-radius: 20px;
	box-shadow: 0 10px 40px 0 rgba(62, 57, 107, 0.1), 0 2px 9px 0 rgba(62, 57, 107, 0.1);
}

.pricing1 {
	color: #1f252d;
	border-radius: 4px;
}

.pricing .card-category {
	padding: 10px 0;
	border-radius: 4px;
	background: #eaebf7;
	color: $white;
}

.horizontal-line {
	margin-block-start: 1rem;
	margin-block-end: 1rem;
	width: 80%;
}

.pricing {
	.btn {
		color: $white !important;
		border-width: 5px !important;
		border-radius: 100px;
		font-size: 16px;
		font-weight: 700 !important;
	}
	.price {
		font-weight: 600;
		font-size: 50px;
		color: #434258 !important;
	}
	.list-unstyled li {
		border-block-end: 1px solid rgba(232, 235, 243, 0.7);
		padding: 7px 0;
	}
}

.pricing1 {
	.list-unstyled li {
		border-block-end: 1px solid $border;
		padding: 7px 0;
	}
	.card-category {
		background: $white-3;
		padding: 20px 0;
		color: $white;
	}
}

.pricing-table {
	width: 100%;
	margin: 0 auto;
	transition: all .3s ease;
	-o-transition: all .3s ease;
	-moz-transition: all .3s ease;
	-webkit-transition: all .3s ease;
	border-radius: 0;
	&:hover {
		box-shadow: 0 0 30px rgba(21, 17, 148, 0.1);
		> {
			.panel-footer-landing {
				box-shadow: 0 0 30px rgba(126, 142, 177, 0.2) inset;
				-webkit-transition: all .3s ease;
			}
			.panel> {
				.controle-header {
					background: #eef2f9 !important;
					-webkit-transition: all .3s ease;
				}
				.panel-footer {
					background: #eef2f9 !important;
					-webkit-transition: all .3s ease;
					text-align: center;
					>.btn {
						border: solid 1px $white !important;
						-webkit-transition: all .3s ease;
					}
				}
			}
		}
	}
}

.btn-price:hover {
	background: $white !important;
	color: #eef2f9 !important;
	-webkit-transition: all .3s ease;
}

.pricing-table {
	&:hover {
		>.panel>.controle-header>.panel-title-landing {
			color: $white !important;
			-webkit-transition: all .3s ease;
			font-size: 35px;
		}
	}
	>.panel>.controle-header>.panel-title-landing {
		color: $black !important;
		font-size: 35px;
	}
}

.col-sm-4 .pricing-table .table td {
	padding: .75rem;
	vertical-align: top;
	text-align: start;
}


.price .list-group-item {
	border-block-end: 1px solid rgba(250, 250, 250, 0.5);
}

.panel.price {
	.list-group-item {
		&:last-child {
			border-end-end-radius: 0;
			border-end-start-radius: 0;
		}
		&:first-child {
			border-start-end-radius: 0;
			border-start-start-radius: 0;
		}
	}
	margin-block-end: 1.5rem;
}

.price {
	.panel-footer {
		border-block-end: 0;
		background-color: $white;
	}
	&.panel-color>.panel-body {
		background-color: $white;
	}
}

.price .panel-body,
.panel-collapse .panel-body {
	padding: 15px;
}


.pricingTable {
	padding: 25px 0;
	border: 1px solid rgba(0, 40, 100, 0.12);
	text-align: center;
	.price-value {
		font-size: 50px;
		font-weight: 800;
		color: #0d3b66;
		letter-spacing: 3px;
		margin-block-end: 30px;
	}
	.month {
		display: block;
		font-size: 17px;
		font-weight: 400;
		color: #0d3b66;
		letter-spacing: 2px;
		line-height: 10px;
		text-transform: uppercase;
	}
	.title {
		display: inline-block;
		padding: 15px 40px;
		margin: 0 0 20px 0;
		background: rgba(233, 30, 99, 0.1);
		font-size: 20px;
		text-transform: capitalize;
		letter-spacing: 1px;
		position: relative;
		transition: all .3s ease 0s;
	}
	&:hover .title {
		color: $white;
		box-shadow: 0 10px 40px 0 rgba(62, 57, 107, 0.2), 0 2px 9px 0 rgba(62, 57, 107, 0.2);
	}
	.title {
		&:after,
		&:before {
			content: "";
			border-inline-end: 26px solid rgba(233, 30, 99, 0.1);
			border-block-start: 26px solid transparent;
			border-block-end: 26px solid transparent;
			position: absolute;
			top: 0;
			inset-inline-start: -26px;
			transition: all .3s ease 0s;
		}
		&:after {
			border-inline-end: none;
			border-inline-start: 26px solid rgba(233, 30, 99, 0.1);
			inset-inline-start: auto;
			 inset-inline-end: -26px;
		}
	}
	.pricing-content {
		padding: 0;
		margin: 0 0 25px 0;
		list-style: none;
		li {
			font-size: 17px;
			line-height: 40px;
		}
	}
	.pricingTable-signup {
		display: inline-block;
		padding: 7px 30px;
		background: rgba(233, 30, 99, 0.1);
		font-size: 17px;
		font-weight: 700;
		text-transform: uppercase;
		position: relative;
		transition: all .3s ease 0s;
		&:after,
		&:before {
			content: "";
			position: absolute;
			top: 0;
			inset-inline-start: 0;
			bottom: 0;
			 inset-inline-end: 0;
			transition: all .5s ease 0s;
		}
		&:hover {
			&:before {
				background: $white-7;
				inset-inline-start: 60%;
				 inset-inline-end: 60%;
				z-index: 1;
			}
			&:after {
				background: $white-7;
				top: 60%;
				bottom: 60%;
			}
		}
	}
}

@media only screen and (max-width: 990px) {
	.pricingTable {
		margin-block-end: 30px;
	}
}


/*--Pricing table2--*/

.pricingTable2 {
	text-align: center;
	transition: all .4s ease-in-out 0s;
	background: $white;
	border: 1px solid rgba(0, 40, 100, 0.12);
}

.pricingTable2-header {
	padding: 25px 10px;
	background-color: $white;
}

.pricingTable2 .pricingTable2-header h3 {
	font-size: 21px;
	letter-spacing: 2px;
	color: $black;
	text-transform: uppercase;
	margin-block-start: 10px;
}

.pricingTable2-header span {
	display: block;
	font-size: 12px;
}

.pricing-plans {
	padding: 25px 0;
}

.price-value1 {
	font-size: 25px;
	line-height: 35px;
	color: $white;
	display: inline-block;
	position: relative;
	i {
		position: absolute;
		top: 3px;
		font-size: 22px;
	}
	span {
		font-size: 40px;
		color: $white;
		margin-inline-start: 17px;
	}
}

.pricing-plans .month {
	color: $white;
	display: inline-block;
	font-size: 15px;
	margin-block-start: 15px;
	font-weight: 100;
}

.pricingContent2 ul {
	padding: 0 15px;
	margin-block-end: 0;
	list-style: none;
	li {
		padding: 15px 0;
		text-transform: capitalize;
	}
}

.pricingTable2 {
	.pricingTable2-sign-up {
		padding: 25px 0;
		border-block-start: 1px solid rgba(0, 40, 100, 0.12);
	}
	.btn-block {
		width: 50%;
		margin: 0 auto;
		padding: 10px 0;
		color: $white;
		text-transform: uppercase;
		border-radius: 4px;
		transition: .3s ease;
	}
	&.blue .pricing-plans {
		background-color: #520995;
	}
	&.green .pricing-plans {
		background-color: $success;
	}
	&.info .pricing-plans {
		background-color: #0ab2e6;
	}
	&.danger .pricing-plans {
		background-color: $danger;
	}
}

@media screen and (max-width: 990px) {
	.pricingTable2 {
		margin-block-end: 30px;
	}
}

/*----Pricing Tables style ----*/