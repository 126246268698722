/*--Time Line--*/

.timeline {
	position: relative;
	margin: 0 0 2rem;
	padding: 0;
	list-style: none;
	&:before {
		background-color: #f3f3fd;
		position: absolute;
		display: block;
		content: '';
		height: 100%;
		top: 0;
		bottom: 0;
		inset-inline-start: 4px;
	}
}

.timeline-item {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	padding-inline-start: 2rem;
	margin: .5rem 0;
	&:first-child:before,
	&:last-child:before {
		content: '';
		position: absolute;
		background: $white;
		width: 1px;
		inset-inline-start: .25rem;
	}
	&:first-child {
		margin-block-start: 0;
		&:before {
			top: 0;
			height: .5rem;
		}
	}
	&:last-child {
		margin-block-end: 0;
		&:before {
			top: .5rem;
			bottom: 0;
		}
	}
}


/*--Time Line--*/