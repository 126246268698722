.list-unstyled,
.list-inline {
	padding-inline-start: 0;
	list-style: none;
}

.list-inline-item {
	display: inline-block;
	&:not(:last-child) {
		margin-inline-end: .5rem;
	}
}


/*-- List Group --*/

.list-group {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	padding-inline-start: 0;
	margin-block-end: 0;
}

.list-group-item-action {
	width: 100%;
	color: #2e384d;
	text-align: inherit;
	&:focus,
	&:hover {
		color: #2e384d;
		text-decoration: none;
		background-color: #f3f3fd;
	}
	&:active {
		color: #636d8c;
		background-color: #f3f3fd;
	}
}

.list-group-item {
	position: relative;
	display: block;
	padding: .75rem 1.25rem;
	margin-block-end: -1px;
	background-color: $white;
	border: 1px solid $border;
}

.listorder {
	position: relative;
	padding: .75rem 1.25rem;
	margin-block-end: -1px;
	background-color: $white;
	border: 1px solid $border;
	list-style-type: decimal;
	list-style-position: inside;
}

.listorder1 {
	position: relative;
	padding: .75rem 1.25rem;
	margin-block-end: -1px;
	background-color: $white;
	border: 1px solid $border;
	list-style-type: upper-alpha;
	list-style-position: inside;
}

.listunorder {
	position: relative;
	padding: .75rem 1.25rem;
	margin-block-end: -1px;
	background-color: $white;
	border: 1px solid $border;
	list-style-type: circle;
	list-style-position: inside;
}

.listunorder1 {
	position: relative;
	padding: .75rem 1.25rem;
	margin-block-end: -1px;
	background-color: $white;
	border: 1px solid $border;
	list-style-type: square;
	list-style-position: inside;
}

.list-group-item {
	&:first-child {
		border-start-start-radius: 4px;
		border-start-end-radius: 4px;
	}
	&:last-child {
		margin-block-end: 0;
		border-end-end-radius: 4px;
		border-end-start-radius: 4px;
	}
	&:focus,
	&:hover {
		z-index: 1;
		text-decoration: none;
	}
	&.disabled,
	&:disabled {
		color: #7c90b1;
		background-color: $white;
	}
	&.active {
		z-index: 2;
		background-color: #eef0f9;
		border: 1px solid $border;
		color: #2e384d;
	}
}

.list-group-flush {
	.list-group-item {
		border-inline-end: 0;
		border-inline-start: 0;
		border-radius: 0;
	}
	&:first-child .list-group-item:first-child {
		border-block-start: 0;
	}
	&:last-child .list-group-item:last-child {
		border-block-end: 0;
	}
}

.list-group-item-primary {
	color: #24426c;
	background-color: #cbdbf2;
}

.list-group-flush .list-group-item:hover {
	border: 1px solid $border;
}

.list-group-item-primary.list-group-item-action {
	&:focus,
	&:hover {
		color: #24426c;
		background-color: #b7cded;
	}
	&.active {
		color: $white;
		background-color: #24426c;
		border-color: #24426c;
	}
}

.list-group-item-secondary {
	color: #464a4e;
	background-color: #dddfe2;
	&.list-group-item-action {
		&:focus,
		&:hover {
			color: #464a4e;
			background-color: #cfd2d6;
		}
		&.active {
			color: $white;
			background-color: #464a4e;
			border-color: #464a4e;
		}
	}
}

.list-group-item-success {
	color: #008048;
	background-color: #ccffe9;
	&.list-group-item-action {
		&:focus,
		&:hover {
			color: #316100;
			background-color: #c5e7a4;
		}
		&.active {
			color: $white;
			background-color: #316100;
			border-color: #316100;
		}
	}
}

.list-group-item-info {
	color: #3ba2ff;
	background-color: #e6f1ff;
	&.list-group-item-action {
		&:focus,
		&:hover {
			color: #3ba2ff;
			background-color: #b3dcf9;
		}
		&.active {
			color: $white;
			background-color: #3ba2ff;
			border-color: #3ba2ff;
		}
	}
}

.list-group-item-warning {
	color: $yellow;
	background-color: #ffe9cc;
	&.list-group-item-action {
		&:focus,
		&:hover {
			color: $yellow;
			background-color: #ffe9cc;
		}
		&.active {
			color: $white;
			background-color: $yellow;
			border-color: $yellow;
		}
	}
}

.list-group-item-danger {
	color: $danger;
	background-color: #ffcfcc;
	&.list-group-item-action {
		&:focus,
		&:hover {
			color: $danger;
			background-color: #ffcfcc;
		}
		&.active {
			color: $white;
			background-color: $danger;
			border-color: $danger;
		}
	}
}

.list-group-item-light {
	color: #818182;
	background-color: #fdfdfe;
	&.list-group-item-action {
		&:focus,
		&:hover {
			color: #818182;
			background-color: #ececf6;
		}
		&.active {
			color: $white;
			background-color: #818182;
			border-color: #818182;
		}
	}
}

.list-group-item-dark {
	color: #1b1e21;
	background-color: #c6c8ca;
	&.list-group-item-action {
		&:focus,
		&:hover {
			color: #1b1e21;
			background-color: #b9bbbe;
		}
		&.active {
			color: $white;
			background-color: #1b1e21;
			border-color: #1b1e21;
		}
	}
}


/*-- List Group --*/

.list-media {
	.media-img {
		position: relative;
		float: $float-right;
		width: 48px;
		margin-inline-end: 20px;
	}
	.info {
		padding-inline-start: 55px;
		min-height: 40px;
		height: auto;
		position: relative;
		h4 a {
			font-size: 16px;
			margin-block-start: 10px;
		}
		p {
			font-size: 13px;
		}
		.text-end {
			 inset-inline-end: 0;
			color: #8a8a8a;
			top: 50%;
			font-size: 12px;
			transform: translateY(-50%);
			-webkit-transform: translateY(-50%);
			-moz-transform: translateY(-50%);
			-o-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			margin-block-start: -28px;
		}
	}
}

.list-inline-dots .list-inline-item+.list-inline-item:before {
	content: '· ';
	margin-inline-start: -2px;
	margin-inline-end: 3px;
}

.list-separated-item {
	padding: 1rem 0;
	&:first-child {
		padding-block-start: 0;
	}
}

.list-group-item {
	&.active .icon {
		color: inherit !important;
	}
	.icon {
		color: #636d8c !important;
	}
}

.list-group-transparent .list-group-item {
	background: 0 0;
	border: 0;
	padding: .5rem 1rem;
	&.active {
		background: #4771db;
		font-weight: 600;
	}
}

.list-catergory ul li {
	line-height: 2;
}

.list-group.lg-alt .list-group-item {
	border: 0;
}