/*-- Alerts --*/

.alert {
	position: relative;
	padding: .75rem 1.25rem;
	margin-block-end: 1rem;
	border: 1px solid transparent;
	border-radius: 4px;
	font-size: .9375rem;
	&:first-child {
		margin-block-end: 1rem !important;
	}
	&:last-child {
		margin-block-end: 0;
	}
}

.alert-heading {
	color: inherit;
}
@-webkit-keyframes progress-bar-stripes {
	from {
		background-position: 1rem 0;
	}
	to {
		background-position: 0 0;
	}
}

@keyframes progress-bar-stripes {
	from {
		background-position: 1rem 0;
	}
	to {
		background-position: 0 0;
	}
}

.alert-avatar {
	padding-inline-start: 3.75rem;
	.avatar {
		position: absolute;
		top: .5rem;
		inset-inline-start: .75rem;
	}
}


/*-- Alerts --*/