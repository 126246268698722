/*-- Select2 Styles --*/

.select2-lg {
	.select2-container .select2-selection--single {
		height: 2.93rem !important;
	}
	.select2-container--default .select2-selection--single .select2-selection__rendered {
		line-height: 45px !important;
	}
}

.select2-sm {
	.select2-container .select2-selection--single {
		height: 1.775rem !important;
	}
	.select2-container--default .select2-selection--single {
		.select2-selection__rendered {
			line-height: 27px !important;
		}
		.select2-selection__arrow b {
			top: 50% !important;
			inset-inline-start: 70%;
		}
	}
}

select.form-control {
	&.select-lg {
		height: 46px !important;
		padding: 10px 16px !important;
		font-size: 17px !important;
		line-height: 1.3333333 !important;
		border-radius: 4px !important;
	}
	&.select-sm {
		height: 30px !important;
		padding: 0px 10px 1px 10px !important;
		line-height: 1 !important;
		border-radius: 4px !important;
	}
}

.select2-container--default {
	.select2-selection--single {
		background-color: $white;
		border: 1px solid #d6d6ef  !important;
		border-radius: 4px;
	}
	&.select2-container--focus .select2-selection--multiple {
		color: #3d4e67;
		background-color: $white;
		border-color: #d6d6ef ;
		outline: 0;
	}
	.select2-selection--multiple {
		background-color: $white;
		border: 1px solid #d6d6ef  !important;
		border-radius: 4px;
		cursor: text;
	}
	.select2-search--dropdown .select2-search__field {
		border: 1px solid $border !important;
	}
	.select2-results__option--highlighted[aria-selected] {
		background-color: #f3f3fd;
	}
	.select2-results__option[aria-selected=true] {
		background-color: #f3f3fd !important;
	}
	.select2-selection--multiple .select2-selection__choice {
		background-color: #f3f3fd !important;
		border: 1px solid $border !important;
	}
}

select.form-control:not([size]):not([multiple]) {
	height: 2.425rem;
}

.select2-container .select2-selection--single {
	height: 2.375rem;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
	color: #444;
	line-height: 39px !important;
}

select.form-control:focus::-ms-value {
	color: #3d4e67;
	background-color: $white;
}

.select-languages {
	color: #2e384d;
	border-radius: 4px;
	&:focus {
		color: #3d4e67;
		background-color: $white;
	}
}

.img-flag {
	width: 25px;
	height: 12px;
	margin-block-start: -4px;
}

.select-languages,
.select-currency {
	.select2-container .select2-selection--single {
		height: 1.4rem !important;
		color: #636d8c !important;
		border-radius: 4px;
		background: 0 0 !important;
		border: 0 !important;
	}
	.select2-container--default .select2-selection--single {
		.select2-selection__rendered {
			line-height: 20.5px !important;
		}
		.select2-selection__arrow {
			top: -2px !important;
		}
	}
	.select2-container {
		width: 138px !important;
	}
	.select2-results {
		color: #636d8c !important;
	}
}

.select-country {
	.select2-container {
		.select2-selection--single {
			.select2-selection__rendered {
				padding-inline-start: 3px;
			}
			height: 1.4rem !important;
			color: #636d8c !important;
			border-radius: 4px;
			background: 0 0 !important;
			border: 0 !important;
		}
		width: 150px !important;
	}
	.select2-container--default .select2-selection--single {
		.select2-selection__rendered {
			line-height: 21px !important;
		}
		//.select2-selection__arrow {
		//	top: -3px !important;
		//}
	}
	.select2-results {
		color: #636d8c !important;
	}
	.select2-container .select2-selection--single .select2-selection__rendered {
		padding-inline-start: 3px;
	}
}

.select2-results__option {
	font-size: 13px;
}

.select2-container .select2-selection--single {
	height: 100% !important;
}

.select2.select2-container.select2-container--default.select2-container--below.select2-container--open {
	width: 100%;
}

//.select2-container--default .select2-selection--single .select2-selection__arrow {
//	height: 100% !important;
//}

.select2-container--default .select2-selection--single .select2-selection__rendered {
	color: #2e384d !important;
}

@media (min-width: 992px) {
	.search-background .form.no-gutters .select2-container--default .select2-selection--single{
 	 border-radius:0px !important;

	}
}


/*-- Select2 Styles --*/