
:root {

	--primary-bg-color: #6b66f8;
    --primary-bg-hover: #617ff8;
    --primary-bg-border: #6b66f8;
	--primary-bg-transparentcolor:rgba(107, 102, 248, 0.2);
	--primary-shadow:rgba(107, 102, 248, 0.5);
    --primary-2: rgba(107, 102, 248, 0.2);
    --primary-3: rgba(107, 102, 248, 0.3);
    --primary-4: rgba(107, 102, 248, 0.4);
    --primary-5: rgba(107, 102, 248, 0.5);
    --primary-6: rgba(107, 102, 248, 0.6);
    --primary-7: rgba(107, 102, 248, 0.7);
    --primary-8: rgba(107, 102, 248, 0.8);
    --primary-9: rgba(107, 102, 248, 0.9);

	--secondary-color: #fb5c3b;
	--secondary-hover: #dd4526;
	--secondary-transparent:rgba(251, 92, 59, 0.2);
	--secondary-shadow: rgba(251, 92, 59, 0.5);
    --secondary-1: rgba(251, 92, 59, 0.1);
    --secondary-2: rgba(251, 92, 59, 0.2);
    --secondary-3: rgba(251, 92, 59, 0.3);
    --secondary-4: rgba(251, 92, 59, 0.4);
    --secondary-5: rgba(251, 92, 59, 0.5);
    --secondary-6: rgba(251, 92, 59, 0.6);
    --secondary-7: rgba(251, 92, 59, 0.7);
    --secondary-8: rgba(251, 92, 59, 0.8);
    --secondary-9: rgba(251, 92, 59, 0.9);


	--dark-body: #2a2e3f;
	--dark-theme: #212332;
	--dark-theme2: rgba(0, 0, 0, 0.3);
	--dark-border: rgba(255, 255, 255, 0.1);
	--dark-color:#fff;
	--dark-shadow:rgba(36, 38, 48, 0.2);
    --float-left: left;
    --float-right: right;
}


$background:#f5f6fd;
$color:#0a1f44;
$border:#c8c8e1;

/*Color variables*/
$primary1 : var(--primary-bg-color);
$primary-hover : var(--primary-bg-hover);
$primary-border : var(--primary-bg-border);
$primary-shadow :var(--primary-bg-shadow);
$primary-transparent: var(--primary-bg-transparentcolor);
$secondary :var(--secondary-color);
$secondary-hover :var(--secondary-hover);
$secondary-shadow: var(--secondary-shadow);
$secondary-transparent: var(--secondary-transparent);

/*transparent primary and secondary*/
$primary2 : var(--primary-2);
$primary3 : var(--primary-3);
$primary4 : var(--primary-4);
$primary5 : var(--primary-5);
$primary6 : var(--primary-6);
$primary7 : var(--primary-7);
$primary8 : var(--primary-8);
$primary9 : var(--primary-9);
$secondary-1: var(--secondary-1);

$info:#0b94f7;
$success:#01d277;
$warning:#fb9505;
$danger:#f84242;
$blue:#096cf7;
$yellow:#ffa22b;
$teal:#05e6e6;
$purple:#8b3ce0;
$dark:#343a40;
$indigo:#6574cd;
$white:#fff;
$black:#161635;
$pink:#df1570;

/*white variables*/

$white-1:rgba(255, 255, 255, 0.1);
$white-2:rgba(255, 255, 255, 0.2);
$white-3:rgba(255, 255, 255, 0.3);
$white-4 :rgba(255, 255, 255, 0.4);
$white-5 :rgba(255, 255, 255, 0.5);
$white-6 :rgba(255, 255, 255, 0.6);
$white-7 :rgba(255, 255, 255, 0.7);
$white-8 :rgba(255, 255, 255, 0.8);
$white-9 :rgba(255, 255, 255, 0.9);
$white-05:rgba(255, 255, 255, 0.05);
$white-08:rgba(255, 255, 255, 0.08);
$white-75:rgba(255, 255, 255, 0.075);

/*black variables*/

$black-1:rgba(0, 0, 0, 0.1);
$black-2:rgba(0, 0, 0, 0.2);
$black-3:rgba(0, 0, 0, 0.3);
$black-4:rgba(0, 0, 0, 0.4);
$black-5:rgba(0, 0, 0, 0.5);
$black-6:rgba(0, 0, 0, 0.6);
$black-7:rgba(0, 0, 0, 0.7);
$black-8:rgba(0, 0, 0, 0.8);
$black-9:rgba(0, 0, 0, 0.9);
$black-05:rgba(0, 0, 0, 0.05);


$dark-body: var(--dark-body);
$dark-theme: var(--dark-theme);
$dark-theme2: var(--dark-theme2);
$dark-border: var(--dark-border);
$dark-color: var(--dark-color);
$dark-shadow: var(--dark-shadow);
$border: #e6e6eb;

$float-left:var(--float-left);
$float-right:var(--float-right);

[dir="rtl"] {
  --float-left:right;
  --float-right:left;
}