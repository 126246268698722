
/* ###### Rtl  ###### */
body.rtl {
  text-align: right !important;
  direction: rtl !important;
}
.rtl{
	// .select2-results,.select2-container{
	//   direction: rtl;
  //    text-align: start ;
  //  }
   .select2-container .select2-dropdown{
	  direction: ltr !important;
   }
   [dir=rtl] .tooltip.show {
    direction: ltr;
  }
  .sp-container{
    direction: ltr;
  }
   [type=email], [type=number], [type=tel], [type=url]{
    direction: rtl;
   }
  .demo_changer .form_holder {
    border-radius:0 10px 10px 0;
  }
  .carousel-control-next {
    transform: rotate(180deg);
  }
  .carousel-control-prev {
    transform: rotate(180deg);
  }
	.slider-wrap {
		.thumb-carousel-control-next {
			transform: rotate(180deg) translateY(50%) !important;
		}
		.thumb-carousel-control-prev {
			transform: rotate(180deg) translateY(50%) !important;
		}
	}
  .power-ribbon-top-left span,.ribbon-top-left span {
      transform: rotate(45deg);
  }
    .select2-container--default[dir=rtl] .select2-selection--single .select2-selection__arrow {
      inset-inline-end: 1px;
      inset-inline-start: auto;
  }
  .fa-angle-right:before {
    content: "\f104";
  }
  .fa-angle-left:before {
    content: "\f105";
  }
  .dropdown-menu-arrow.dropdown-menu-end:after, .dropdown-menu-arrow.dropdown-menu-end:before {
    inset-inline-end: auto;
    inset-inline-start: 6px;
  }
  @media (min-width: 1366px){
    .illustrate-img.owl-carousel {
        transform: translateX(0px);
    }
  }
  .nice-select:after {
      inset-inline-start: 12px;
  }
}
  
   
/* ###### Rtl End ###### */