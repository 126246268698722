
a.mail-dropdown {
	background: none repeat scroll 0 0 #80d3d9;
	border-radius: 4px;
	color: #01a7b3;
	font-size: 10px;
	margin-block-start: 20px;
	padding: 3px 5px;
}

.btn-compose {
	background: none repeat scroll 0 0 #ff6c60;
	color: $white;
	padding: 12px 0;
	text-align: center;
	width: 100%;
	&:hover {
		background: none repeat scroll 0 0 #f5675c;
		color: $white;
	}
}

ul.inbox-nav {
	display: inline-block;
	margin: 0;
	padding: 0;
	width: 100%;
}

.inbox-divider {
	border-block-end: 1px solid #d5d8df;
}

ul {
	&.inbox-nav li {
		display: inline-block;
		line-height: 45px;
		width: 100%;
		a {
			color: #6a6a6a;
			display: inline-block;
			line-height: 45px;
			padding: 0 20px;
			width: 100%;
			&:focus,
			&:hover {
				background: none repeat scroll 0 0 #d5d7de;
				color: #6a6a6a;
			}
		}
		&.active a {
			background: none repeat scroll 0 0 #d5d7de;
			color: #6a6a6a;
		}
		a {
			i {
				color: #6a6a6a;
				font-size: 16px;
				padding-inline-end: 10px;
			}
			span.label {
				margin-block-start: 13px;
			}
		}
	}
	&.labels-info li {
		h4 {
			color: #5c5c5e;
			font-size: 13px;
			padding-inline-start: 15px;
			padding-inline-end: 15px;
			padding-block-start: 5px;
			text-transform: uppercase;
		}
		margin: 0;
		a {
			border-radius: 0;
			color: #6a6a6a;
			&:focus,
			&:hover {
				background: none repeat scroll 0 0 #d5d7de;
				color: #6a6a6a;
			}
			i {
				padding-inline-end: 10px;
			}
		}
	}
}

.inbox-body .modal .modal-body {
	input,
	textarea {
		border: 1px solid #e6e6e6;
		box-shadow: none;
	}
}

.heading-inbox h4 {
	border-block-end: 1px solid #ddd;
	color: #444;
	font-size: 18px;
	margin-block-start: 20px;
	padding-block-end: 10px;
}

.sender-info {
	margin-block-end: 20px;
	img {
		height: 30px;
		width: 30px;
	}
}

.sender-dropdown {
	background: none repeat scroll 0 0 #eaebf7;
	color: #777;
	font-size: 10px;
	padding: 0 3px;
}

.view-mail a {
	color: #ff6c60;
}
.files .progress {
	width: 200px;
}

@media (max-width: 767px) {
	.files {
		.btn span {
			display: none;
		}
		.preview * {
			width: 40px;
		}
		.name * {
			display: inline-block;
			width: 80px;
			word-wrap: break-word;
		}
		.progress {
			width: 20px;
		}
		.delete {
			width: 60px;
		}
	}
}

ul {
	list-style-type: none;
	padding: 0;
	margin: 0;
}
.user p {
	&.u-name {
		margin: 0;
		font-size: 14px;
		font-weight: 600;
		line-height: 18px;
		color: #636d8c;
	}
	&.u-designation {
		margin: 0;
		font-size: 11px;
		color: #636d8c;
	}
}

div.online-status .status.online {
	background: rgba(0, 128, 0, 0.68);
}

.online-status .status {
	&.offline {
		background: rgba(255, 0, 0, 0.7);
	}
	height: 10px;
	width: 10px;
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
	-ms-border-radius: 100%;
	border-radius: 100%;
	display: inline-flex;
	justify-content: flex-start;
	transform: translateX(-60px) translateY(2px);
}

.online {
	margin-inline-start: 20px;
	margin-block-start: -3px;
}
