/** Custome Styles*/

@media (max-width: 576px) {
	.header-text h1 {
		font-size: 2rem;
	}
	.section-title h2 {
		font-size: 1.5rem;
	}
	.content-text .display-5 {
		font-size: 1.8rem;
	}
	.owl-nav .owl-prev{
		inset-inline-start: 0px !important;
	}
	.owl-nav .owl-next{
		inset-inline-end:0px !important;
	}
}

.visible {
	visibility: visible !important;
}

.invisible {
	visibility: hidden !important;
}

@media print {
	*,
	 ::after,
	 ::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
	a:not(.btn) {
		text-decoration: underline;
	}
	abbr[title]::after {
		content: " (" attr(title) ")";
	}
	pre {
		white-space: pre-wrap !important;
	}
	blockquote,
	pre {
		border: 1px solid #adb5bd;
		page-break-inside: avoid;
	}
	thead {
		display: table-header-group;
	}
	img,
	tr {
		page-break-inside: avoid;
	}
	h2,
	h3,
	p {
		orphans: 3;
		widows: 3;
	}
	h2,
	h3 {
		page-break-after: avoid;
	}
	body,
	.container {
		min-width: 992px !important;
	}
	.navbar {
		display: none;
	}
	.badge {
		border: 1px solid $black;
	}
	.table,
	.text-wrap table {
		border-collapse: collapse !important;
	}
	.table {
		td,
		th {
			background-color: $white !important;
		}
	}
	.text-wrap table {
		td,
		th {
			background-color: $white !important;
		}
	}
	.table-bordered {
		td,
		th {
			border: 1px solid $border !important;
		}
	}
	.text-wrap table {
		td,
		th {
			border: 1px solid $border !important;
		}
	}
}

.lead {
	line-height: 1.4;
}

blockquote {
	color: #2e384d;
	padding-inline-start: 2rem;
	border-inline-start: 3px solid rgba(227, 237, 252, 0.5);
}

.blockquote-reverse {
	padding-inline-end: 15px;
	padding-inline-start: 0;
	text-align: end;
	border-inline-end: 3px solid rgba(227, 237, 252, 0.5);
	border-inline-start: 0;
}

blockquote {
	&.float-right {
		padding-inline-end: 15px;
		padding-inline-start: 0;
		text-align: end;
		border-inline-end: 3px solid rgba(227, 237, 252, 0.5);
		border-inline-start: 0;
	}
	p {
		margin-block-end: 1rem;
	}
	cite {
		display: block;
		text-align: end;
		&:before {
			content: '— ';
		}
	}
}

code {
	background: #eaebf7;
	border: 1px solid $border;
	border-radius: 4px;
	padding: 3px;
}

pre code {
	padding: 0;
	border-radius: 0;
	border: none;
	background: 0 0;
}


pre {
	color: $dark;
	padding: 1rem;
	overflow: auto;
	font-size: 85%;
	line-height: 1.45;
	background-color: #e2e4f1;
	border-radius: 4px;
	-moz-tab-size: 4;
	-o-tab-size: 4;
	tab-size: 4;
	-webkit-hyphens: none;
	-moz-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;
}

img {
	max-width: 100%;
}

.section-nav {
	background-color: #f8f9fa;
	margin: 1rem 0;
	padding: .5rem 1rem;
	border: 1px solid $border;
	border-radius: 4px;
	list-style: none;
	&:before {
		content: 'Table of contents:';
		display: block;
		font-weight: 600;
	}
}

@media print {
	.container {
		max-width: none;
	}
}

.icon i {
	vertical-align: -1px;
}

a.icon {
	text-decoration: none;
	cursor: pointer;
	&:hover {
		color: #1a1a1a !important;
	}
}

.o-auto {
	overflow: auto !important;
}

.o-hidden {
	overflow: hidden !important;
}

.shadow {
	box-shadow: 0 3px 18px 0 $black-05 !important;
}

.shadow-none {
	box-shadow: none !important;
}
/*--Loader --*/

#global-loader {
	position: fixed;
	z-index: 50000;
	background: $white;
	inset-inline-start: 0;
	top: 0;
	 inset-inline-end: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
	margin: 0 auto;
	overflow: hidden;
}

.loader-img {
	position: absolute;
	inset-inline-start: 0;
	 inset-inline-end: 0;
	text-align: center;
	top: 45%;
	margin: 0 auto;
}


/*--Loader --*/

.profie-img p {
	font-size: 15px;
	line-height: 21px;
}


/*-- Page Styles --*/

.page {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center;
	min-height: 100vh;
}

body.fixed-header .page {
	padding-block-start: 4.5rem;
}

@media (min-width: 1600px) {
	body.aside-opened .page {
		margin-inline-end: 22rem;
	}
}

.page-main {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
}

.page-content {
	margin: .75rem 0;
}

@media (min-width: 768px) {
	.page-content {
		margin: 1.5rem 0 !important;
	}
}

.overlay {
	position: absolute;
	top: 0;
	inset-inline-start: 0;
	height: 100%;
	width: 100%;
	opacity: .7;
	z-index: 1;
}

@media (max-width: 768px) {
	.item-det {
		ul.d-flex,
		.d-flex {
			display: block !important;
		}
		.rating-stars.d-flex {
			display: flex !important;
		}
	}
	.mrt-sm-2 {
		margin-block-start: 0.5rem !important;
	}
	.header-text1 h1 {
		font-size: 1.5rem;
	}
	.map-header-layer.h-400 {
		height: 300px !important;
	}
	.item-service:after,
	.item-service1:after {
		display: none;
	}
	.item-card9-imgs img{
		height: 100%;
	}
}

.page-header {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-wrap: wrap;
	justify-content: space-between;
	padding: 0;
	position: relative;
	min-height: 50px;
	background: $white;
}

.users-list .page-header {
	background: 0 0;
	padding: 0;
	border: 0;
	padding: 1rem !important;
}

.bannerimg .breadcrumb {
	display: inline-flex;
}

.page-title {
	margin: 0;
	font-size: 18px;
	font-weight: 500;
	line-height: 2.5rem;
}

.page-title-icon {
	color: #636d8c;
	font-size: 1.25rem;
}

.page-subtitle {
	font-size: .8125rem;
	color: #5f6877;
	position: relative;
	top: 19px;
	 inset-inline-end: 59rem;
	a {
		color: inherit;
	}
}

.page-options {
	margin-inline-start: auto;
}

.page-description {
	margin: .25rem 0 0;
	color: #5f6877;
	a {
		color: inherit;
	}
}

.page-single {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
}

@media (max-width: 480px) {
	.tabs-menu ul li {
		width: 100%;
		margin: 5px 15px;
		a {
			width: 100%;
		}
	}
	.cal1 .clndr .clndr-table tr {
		height: 50px !important;
	}
	.tabs-menu1 ul li {
		width: 100%;
		margin: 2px;
		a {
			width: 100%;
		}
	}
	.form-control.form-select.w-auto {
		display: none;
	}
	.mail-inbox .badge {
		margin: 0;
	}
	.construction .display-5 {
		font-size: 1.5rem;
	}
}

@media (max-width: 480px) {
	.app-header .header-brand {
		min-width: 2.8rem;
	}
	.app-header1 .header-brand {
		min-width: auto;
	}
	.menu-toggle-button {
		margin-block-start: 4px;
	}
}

@media (max-width: 767px) {
	
#quantity{
	width: 50px;
	padding: 0;
  }
	.avatar {
		max-width: inherit;
	}
	.card-tabs {
		display: block !important;
	}
	.header-brand {
		line-height: 2.7rem;
	}
	.header .input-icon.mt-2 {
		margin-block-start: 5px !important;
	}
	.footer .privacy {
		text-align: center !important;
	}
	.shop-dec .col-md-6 {
		&.pe-0 {
			padding-inline-end: 0.75rem !important;
		}
		&.ps-0 {
			padding-inline-start: 0.75rem !important;
		}
	}
	.app-header1 .header-brand {
		min-width: auto;
	}
}

@media (max-width: 375px) {
	.nav-link span {
		margin-block-start: .5rem;
	}
	.construction h3 {
		font-size: 2.8rem !important;
	}
}

@media (max-width: 411px) {
	.nav-link span{
		margin-block-start: .5rem;
	}
}

@media (max-width: 414px) {
	.nav-link span {
		margin-block-start: .6rem;
	}
}

@media (max-width: 768px) {
	.nav-tabs .nav-link {
		width: 100%;
	}
	.page-subtitle {
		display: none;
	}
	.richText .richText-toolbar ul li a {
		border: rgba(0, 40, 100, 0.12) solid 1px;
	}
	.ticket-card {
		.col-md-1 {
			width: 100%;
			display: block;
		}
		img {
			display: block;
			text-align: Center;
			margin: 0 auto;
		}
	}
	.dataTables_wrapper {
		.dataTables_info,
		.dataTables_paginate {
			margin-block-start: 10px;
		}
	}
	.page-title {
		line-height: 1.5rem;
	}
	.carousel-caption {
		display: none;
	}
	.temp-categories li {
		margin-block-end: 0.8rem;
	}
}

@media (max-width: 568px) and (min-width: 480px) {
	.messages-right {
		top: 10px !important;
		bottom: 71px !important;
	}
	.message-footer,
	.message-header {
		height: 50px !important;
	}
}

@media (max-width: 990px) and (min-width: 569px) {
	.message-header,
	.message-footer {
		height: 50px !important;
	}
	.messages-right {
		top: 10px !important;
		bottom: 80px !important;
	}
}

@media (max-width: 990px) {
	.header-toggler {
		margin: 6px;
	}
	.nav-tabs .nav-link {
		width: 100%;
	}
	form.convFormDynamic button.submit {
		margin: 4px !important;
	}
	.nav-tabs {
		z-index: 1000;
	}
}

.page-breadcrumb {
	background: 0 0;
	padding: 0;
	margin: 1rem 0 0;
	font-size: .875rem;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
	.breadcrumb-item {
		color: #636d8c;
		&.active {
			color: #5f6877;
		}
	}
}

@media (min-width: 768px) {
	.page-breadcrumb {
		margin: -.5rem 0 0;
	}
}

@media (max-width: 320px) {
	.fc-toolbar .fc-right {
		float: $float-right;
		width: 100%;
		text-align: center;
		margin: 10px 0;
	}
	.construction h3 {
		font-size: 2.3rem !important;
	}
}

.tilebox-one i {
	font-size: 62px;
}

.reg {
	text-align: center;
	font-size: 50px;
	color: #2e1170;
	float: $float-right;
}

.profie-img .flex-md-row img {
	width: 60px;
}

ul li .legend-dots {
	width: 1rem;
	height: 1rem;
	border-radius: 100%;
	display: inline-block;
	vertical-align: text-bottom;
	margin-inline-end: .5rem;
}

.legend li {
	padding: 5px;
	display: -webkit-inline-box;
}

.icon-size {
	font-size: 2.5rem !important;
}

.app-selector {
	box-shadow: 0 1px 2px 0 $black-05;
	min-width: 16rem;
}

/*--Custom Styles--*/

.link-overlay {
	position: relative;
	&:hover .link-overlay-bg {
		opacity: 1;
	}
}

.link-overlay-bg {
	position: absolute;
	top: 0;
	inset-inline-start: 0;
	 inset-inline-end: 0;
	bottom: 0;
	background: $black-5;
	display: -ms-flexbox;
	display: flex;
	color: $white;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	font-size: 1.25rem;
	opacity: 0;
	transition: .3s opacity;
}

textarea[cols] {
	height: auto;
}
textarea.form-control {
    height: auto !important;
}

.fs {
	font-size: 25px;
}

@keyframes fadeInDown {
	from {
		opacity: 0;
		transform: translate3d(0, -20%, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

.sparkline {
	display: inline-block;
	height: 2rem;
}
@-webkit-keyframes status-pulse {
	0%,
	100% {
		opacity: 1;
	}
	50% {
		opacity: .32;
	}
}

@keyframes status-pulse {
	0%,
	100% {
		opacity: 1;
	}
	50% {
		opacity: .32;
	}
}
.team i {
	margin-inline-start: 10px;
	float: $float-right;
	margin-block-start: 10px;
	color: #313148;
}
.stamp {
	color: $white;
	background: #868e96;
	display: inline-block;
	min-width: 2rem;
	height: 2rem;
	padding: 0 .25rem;
	line-height: 2rem;
	text-align: center;
	border-radius: 4px;
	font-weight: 600;
}

.stamp-md {
	min-width: 2.5rem;
	height: 2.5rem;
	line-height: 2.5rem;
}

.stamp-lg {
	min-width: 3rem;
	height: 3rem;
	line-height: 3rem;
}


/*--Custom Styles--*/


/*--Tag Styles--*/

.example {
	padding: 1.5rem;
	border: 1px solid $border;
	border-radius: 4px 3px 0 0;
	font-size: .9375rem;
}

.example-bg {
	background: #eaebf7;
}

.example-column {
	margin: 0 auto;
	>.card:last-of-type {
		margin-block-end: 0;
	}
}

.example-column-1 {
	max-width: 20rem;
}

.example-column-2 {
	max-width: 40rem;
}


/*--Tag Styles--*/


/*--Back to Top--*/

#back-to-top {
	color: $white;
	position: fixed;
	bottom: 20px;
	 inset-inline-end: 20px;
	z-index: 99;
	display: none;
	text-align: center;
	border-radius: 4px;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	-o-border-radius: 4px;
	z-index: 10000;
	height: 50px;
	width: 50px;
	background-repeat: no-repeat;
	background-position: center;
	transition: background-color .1s linear;
	-moz-transition: background-color .1s linear;
	-webkit-transition: background-color .1s linear;
	-o-transition: background-color .1s linear;
	i {
		padding-block-start: 15px;
		font-size: 16px;
	}
}


/*--Back to Top--*/

.features {
	overflow: hidden;
	h2 {
		font-weight: 600;
		margin-block-end: 12px;
		text-align: center;
		font-size: 2.2em;
	}
	h3 {
		font-size: 20px;
		font-weight: 500;
	}
	span {
		color: #43414e;
		display: block;
		font-weight: 400;
		text-align: center;
	}
}

.feature {
	.feature-svg {
		width: 25%;
	}
	.feature-svg3 {
		width: 12%;
	}
}

.col-sm-2 .feature {
	padding: 0;
	border: 0;
	box-shadow: none;
}

.feature {
	.fea-icon {
		position: relative;
		display: inline-block;
		width: 3rem;
		height: 3rem;
		vertical-align: middle;
		padding-block-start: 8px;
		border-radius: 50%;
		color: $white;
		i {
			font-size: 1.5rem;
		}
	}
	p {
		margin-block-end: 0;
	}
}

.box-shadow-0 {
	box-shadow: none !important;
}

/*--stylesheet--*/
.p-15 {
	padding: 15px !important;
}
.ms-body {
	background: $white;
	border-radius: 4px;
}

.toggle-switch {
	-webkit-user-select: none;
	-moz-user-select: none;
}
.text-end {
	text-align: end;
}
/*--stylesheet--*/

@media (min-width: 569px) and (max-width: 992px) {
	.item-card-img h3.text-white {
		font-size: 1rem;
	}
	.item-card2 h4.bg-light.p-3 {
		font-size: .8rem;
	}
}
.video-list-thumbs {
	>li {
		margin-block-end: 12px;
		>a {
			display: block;
			position: relative;
			color: #6b6f80;
			padding: 8px;
			border-radius: 4px;
			transition: all .5s ease-in-out;
			border-radius: 4px;
		}
	}
	h2 {
		bottom: 0;
		font-size: 14px;
		height: 33px;
		margin: 8px 0 0;
	}
	span {
		font-size: 50px;
		opacity: .8;
		position: absolute;
		 inset-inline-end: 0;
		inset-inline-start: 0;
		top: 42%;
		margin: 0 auto;
		text-align: center;
		transition: all .5s ease-in-out;
		cursor: pointer;
	}
	>li>a:hover .fa {
		color: $white;
		opacity: 1;
		text-shadow: 0 1px 3px $black-8;
	}
	.duration {
		background-color: rgb(0, 0, 0);
		border-radius: 0;
		color: $white;
		font-size: 13px;
		font-weight: 700;
		inset-inline-start: 12px;
		line-height: 9px;
		padding: 5px 8px;
		position: absolute;
		bottom: 12px;
		transition: all .5s ease;
	}
	>li>a:hover .duration {
		background-color: $black;
	}
}

@media (max-width: 768px) {
	.video-list-thumbs span {
		top: 29%;
	}
}

/*--Responsive--*/

@media (min-width: 320px) and (max-width: 480px) {
	.video-list-thumbs {
		.fa {
			font-size: 35px;
		}
		h2 {
			bottom: 0;
			font-size: 12px;
			height: 22px;
			margin: 8px 0 0;
		}
	}
}

@media (max-width: 991px) {
	.search-background {
		background: 0 0;
		border-radius: 0;
		.form-group {
			margin-block-end: 10px !important;
			border-radius: 4px !important;
		}
	}
	.support .border-end {
		border-inline-end: 0 !important;
	}
	.support-service {
		&.bg-dark {
			margin-block-end: 10px;
		}
		margin-block-end: 10px;
	}
	.wideget-user-info .wideget-user-warap .wideget-user-warap-r {
		margin-inline-start: 5rem !important;
	}
	.wideget-user-desc .user-wrap {
		margin-block-start: 0 !important;
		margin-inline-start: 10px !important;
	}
	dd {
		display: -webkit-box !important;
	}
	.item-single .item-single-desc .item-single-desc1 {
		display: block !important;
	}
	.header-search .header-nav .nav-cart .icon-cart span {
		display: none;
	}
	.header-nav .header-search-logo .header-logo .header-brand-img {
		height: auto;
		line-height: 2rem;
		vertical-align: bottom;
		margin-inline-end: .5rem;
		width: auto;
	}
	.header-search .header-search-logo {
		text-align: center;
	}
	.wideget-user .wideget-user-desc .user-wrap a.btn {
		margin-block-end: 5px;
	}
	.item-single .item-single-desc .input-group {
		margin-block-end: 10px;
	}
	.product-item2-desc .label-rating {
		margin-inline-end: 4px !important;
	}
	.wideget-user-info .wideget-user-warap {
		margin-block-start: 15px;
	}
	.header-search .header-inputs {
		.input-group-text,
		.input-group>.form-control {
			width: 100%;
			margin-block-end: 10px;
		}
		.input-group-text {
			width: 100%;
			margin-block-end: 15px;
			>.btn {
				width: 100%;
				display: block;
			}
		}
	}
	.header-main {
		.social-icons {
			text-align: center;
			float: none !important;
			li:last-child {
				margin-inline-end: 0;
			}
		}
	}
	.header-search .header-icons {
		.header-icons-link li {
			margin: 0 auto !important;
		}
		float: none !important;
		text-align: center !important;
	}
	.top-bar {
		text-align: center;
		.top-bar-left .contact {
			border-inline-start: 0 !important;
			margin-inline-start: 0 !important;
			padding-inline-start: 0 !important;
			li {
				margin: 0 !important;
			}
		}
	}
	.item-search-menu ul li {
		text-align: center;
		border-radius: 4px;
		a {
			border-radius: 4px !important;
		}
	}
	.item-search-tabs {
		.form-group {
			&:last-child {
				margin-block-end: 0 !important;
			}
			margin-block-end: 10px !important;
		}
		.bg-white {
			background: 0 0 !important;
		}
		.tab-content {
			background: 0 0 !important;
			border: 0 !important;
		}
	}
	.item1-card {
		.item1-card-btn,
		.item1-card-info {
			margin-block-start: 15px;
		}
	}
	.items-blog-tab-heading .items-blog-tab-menu li {
		text-align: center;
		margin-block-end: 10px;
		a {
			display: block;
		}
	}
	.item2-gl .item2-gl-nav select,
	.item2-gl-nav h6 {
		text-align: center;
	}
	.item-card .item-card-img img,
	.item-card2-img img,
	.item-card9-img img,
	.product-info .product-info-img img,
	.item-card4-img img,
	.item-card5-img img,
	.item-card7-img img,
	.item-card8-img img,
	.overview .overview-img img,
	.video-list-thumbs img {
		width: 100%;
	}
	.search1 {
		background: 0 0 !important;
		.form-group {
			margin-block-end: 15px !important;
		}
	}
	.search2 a.btn {
		margin-block-start: 15px;
	}
	.search3 {
		background: 0 0 !important;
		.form-group {
			margin-block-end: 15px !important;
		}
	}
	.item-user-icons {
		margin-inline-start: 10px;
	}
	.item1-card-tab .item1-tabs-menu ul li {
		width: 100%;
		text-align: center;
		margin-block-end: 15px;
		&:last-child {
			margin-block-end: 0;
		}
	}
	.header-main .top-bar {
		.contact,
		&.p-3 .contact {
			display: none;
		}
	}
	.bannerimg .header-text h1 {
		display: block;
		margin-block-end: .5rem;
		font-size: 2rem;
	}
	.all-categories .row .card-body {
		border-block-end: 0 !important;
	}
	.item-card9-desc a,
	.item-card2-footer a,
	.item7-card-desc a {
		font-size: 12px;
	}
	.carousel-control-next {
		 inset-inline-end: 0 !important;
	}
	.carousel-control-prev {
		inset-inline-start: 0 !important;
	}
	.app-btn a {
		width: 100%;
		margin-block-end: 1rem;
		&:last-child {
			margin-block-end: 0;
		}
	}
	.sub-newsletter {
		margin-block-end: 1rem;
		text-align: center;
		display: block !important;
	}
	.fea-ad .card,
	.cat-slide .card {
		margin-block-end: 1.5rem !important;
	}
	.advertisment-block img {
		width: 100%;
	}
	.usertab-list li {
		width: 100% !important;
	}
	.userprof-tab {
		.media {
			display: block !important;
		}
		.media-body {
			display: contents;
			width: 100%;
			.card-item-desc {
				margin-inline-start: 0 !important;
			}
		}
		.btn {
			margin-block-end: .3rem;
		}
	}
	.customerpage .card {
		margin-block-end: 1.5rem !important;
	}
	.ads-tabs .tabs-menus ul {
		li {
			a {
				padding: 8px 10px 8px 10px !important;
				text-align: center;
			}
			display: -webkit-box;
			text-align: center;
			margin-block-end: 1.5rem;
			&:last-child {
				margin-block-end: 0;
			}
		}
		text-align: center;
	}
	.smllogo-white{
		display: inline-block;
		img{
			margin-block-start: 10px;
	   }
	}
	.smllogo img{
		display: none;
	}
}

@media (max-width: 320px) {
	.product-item2-desc .label-rating {
		float: none !important;
		margin-block-start: 4px !important;
	}
}

@media (min-width: 569px) and (max-width: 767px) {
	.product-item2-desc .label-rating {
		float: none !important;
		margin-block-start: 4px !important;
	}
}

@media (min-width: 479px) and (max-width: 991px) {
	.page-header .form-control.page-select {
		width: 40% !important;
	}
}

@media (max-width: 580px) {
	.items-blog-tab-heading .items-blog-tab-menu li {
		width: 100%;
		text-align: center;
		margin-block-end: 10px;
	}
	.header-main .top-bar-right .custom {
		text-align: end;
		float: $float-right;
		margin-block-end: 0 !important;
	}
	.settings-tab .tabs-menu li {
		width: 100% !important;
	}
	.top-bar .top-bar-right {
		float: none !important;
		text-align: center;
		display: block !important;
		margin: 0 auto;
	}
	.header-main .top-bar-right .custom li {
		margin-block-end: 0;
	}
	.item2-gl-nav {
		display: block !important;
		.item2-gl-menu li {
			text-align: center;
			margin-block-end: 10px;
			margin: 0 auto;
			padding: 10px 0;
		}
		label {
			display: block;
			float: none;
			text-align: center;
		}
	}
	.header-main .top-bar-right .custom li a span {
		display: none;
	}
	.top-bar .top-bar-left .socials li {
		float: left !important;
		padding: 9px 12px;
	}
	.item2-gl .item2-gl-nav select {
		width: 100% !important;
	}
}

@media (min-width: 481px) and (max-width: 992px) {
	.owl-product .owl-productimg {
		width: 100% !important;
		height: 100% !important;
		margin: 0 auto;
	}
	.art-wideget .art-details {
		margin-block-start: 10px;
	}
	.header-search .header-icons .header-icons-link1 .main-badge1 {
		top: -2px !important;
	}
	.banner1 .header-text {
		h2 span,
		h3 span {
			padding: 0 !important;
		}
		top: 20% !important;
	}
	.item-card:hover .item-card-btn {
		inset-inline-start: 42%;
	}
	.product-img {
		margin: 0 auto;
		text-align: center;
	}
	.banner-2 {
		.header-text {
			top: 0.5rem !important;
		}
		img {
			height: 33rem;
		}
	}
	.item-search-tabs {
		margin-block-start: 1rem !important;
	}
	.userprof-tab {
		.media {
			display: block !important;
		}
		.media-body {
			display: contents;
			width: 100%;
			.card-item-desc {
				margin-inline-start: 0 !important;
			}
		}
		.btn {
			margin-block-end: .3rem;
		}
	}
	.wideget-user-tab {
		.tabs-menu1 ul li a {
			padding: 5px !important;
		}
		.tab-menu-heading .nav li a {
			font-size: 14px !important;
		}
	}
	.header-search .header-search-logo {
		margin-block-start: 5px;
	}
	.header-main .post-btn {
		margin-block-start: 3px;
	}
}

@media (min-width: 992px) and (max-width: 1024px) {
	.item-card .item-card-img img,
	.item-card2-img img,
	.item-card9-img img,
	.product-info .product-info-img img,
	.item-card4-img img,
	.item-card5-img img,
	.item-card7-img img,
	.item-card8-img img,
	.overview .overview-img img,
	.video-list-thumbs img {
		width: 100%;
	}
	.header-search .header-inputs {
		margin-block-end: 15px;
	}
	.header-main .social-icons {
		text-align: center;
		float: none !important;
		li:last-child {
			margin-inline-end: 0;
		}
	}
	.header-search .header-icons {
		.header-icons-link {
			float: $float-right;
		}
		float: none !important;
		text-align: center !important;
	}
	.top-bar {
		text-align: center;
		.top-bar-left {
			text-align: center;
			.contact {
				border-inline-start: 0 !important;
				margin-inline-start: 0 !important;
				padding-inline-start: 0 !important;
			}
		}
		.top-bar-right {
			float: none !important;
			text-align: center;
			display: block !important;
			margin: 0 auto;
		}
		.top-bar-left .contact li:first-child {
			margin-inline-end: 1.5rem !important;
		}
	}
	.product-multiinfo .product-ship p a:first-child{
		margin-block-end: 5px;
	}
	.banner-2 img {
		height: 27rem;
	}
	.items-blog-tab-heading .items-blog-tab-menu li {
		text-align: center;
		margin-block-end: 10px;
	}
	.header-main .top-bar-right .custom {
		display: -webkit-inline-box !important;
		margin-block-end: 0px;
		margin-block-start: 0px;
	}
	.icon-card li {
		font-size: 10px;
	}
	.support-service.bg-dark {
		margin-block-end: 10px;
	}
	.cat-slide .card,
	.fea-ad .card {
		margin-block-end: 1.5rem !important;
	}
	.advertisment-block img {
		width: 100%;
	}
	.header-main .post-btn {
		margin-block-start: 5px;
	}
	.customerpage .card {
		margin-block-end: 1.5rem !important;
	}
	.ads-tabs .tabs-menus ul li a {
		padding: 8px 10px 8px 10px !important;
	}
}

@media (max-width: 480px) {
	.item-all-card img.imag-service {
		width: 20% !important;
	}
	.section-title h1 {
		font-size: 2rem;
	}
	.item-all-cat .category-type .item-all-card img {
		width: 4rem !important;
		height: 4rem !important;
		padding: 1rem 0 !important;
	}
	.banner1 .header-text {
		h2 {
			span {
				padding: 0 !important;
			}
			font-size: 18px !important;
		}
		h3 {
			font-size: 14px !important;
			span {
				padding: 0 !important;
			}
		}
	}
	.card-pay .tabs-menu li {
		width: 100% !important;
	}
	.header-search .header-icons .header-icons-link1 .main-badge1 {
		top: -1px !important;
	}
	.art-wideget .art-details {
		margin-block-start: 10px;
	}
	.support .support-service {
		i {
			float: none !important;
			margin-inline-end: 0 !important;
			margin-block-end: 20px;
		}
		text-align: center;
	}
	.item-card7-desc ul li,
	.item-card2-desc ul li {
		font-size: 12px;
	}
	.banner-2 {
		.header-text {
			top: 0.5rem !important;
		}
		img {
			height: 33rem;
		}
	}
	.item-search-tabs {
		margin-block-start: 1rem !important;
	}
	.bannerimg .header-text {
		font-size: 1.5rem;
		.breadcrumb-item {
			font-size: 14px;
		}
	}
	.icons a {
		margin-block-end: .5rem;
	}
	.item-det ul li {
		font-size: 11px;
		margin-inline-end: 0.5rem !important;
	}
	.userprof-tab {
		.media {
			display: block !important;
		}
		.media-body {
			display: contents;
			width: 100%;
			.card-item-desc {
				margin-inline-start: 0 !important;
			}
		}
		.btn {
			margin-block-end: .3rem;
		}
	}
}

@media (max-width: 992px) {
	.horizontal-header .container {
		position: relative;
	}
}

@media (max-width: 320px) {
	.item-card7-desc ul li,
	.item-card2-desc ul li {
		font-size: 11px;
	}
	.item-card .item-card-desc .item-card-text {
		top: 5.5rem !important;
	}
	.item7-card-desc a {
		font-size: 11px;
	}
}

/*--Responsive--*/


/*--Styles--*/

.all-categories .row {
	.card-body {
		border-block-end: 1px solid $border;
	}
	&:last-child .card-body {
		border-block-end: 0 !important;
	}
}

.clear,
.current,
.ok {
	background: #e9ecfb;
	border-radius: 20px;
}

.today {
	background: #e9ecfb;
}

.graph canvas {
	width: 100% !important;
}

.trend {
	i {
		font-size: 10px !important;
		vertical-align: middle;
	}
	.media-body {
		margin-inline-start: 10px;
	}
}

.datebox {
	top: 0;
	inset-inline-start: 0;
	border-radius: 4px;
	background: $white;
	padding: 0 15px 0 15px;
}

.iconbage .badge {
	position: absolute;
	top: 14px;
	 inset-inline-end: 13px;
	padding: .2rem .25rem;
	min-width: 1rem;
	font-size: 13px;
}

.single-product .product-desc .product-icons .socialicons a {
	width: 2em;
	height: 2em;
	line-height: 2em;
	border-radius: 50%;
	font-size: 1em;
	display: inline-block;
	vertical-align: middle;
	border: 1px solid #eef2f9;
	color: #2e384d;
	margin: 0 auto;
	text-align: center;
}

.blogimg {
	width: 100px;
}

.subnews img {
	width: 25%;
	height: 25%;
}

.task-img img,
.status-img img {
	width: 70px;
	height: 70px;
	margin: 0 auto;
}

.product-hover {
	position: relative;
	.product-info-buttons {
		position: absolute;
		display: none;
		text-align: center;
		top: 45%;
		margin: 0 auto;
		inset-inline-start: 0;
		width: 100%;
		height: 100%;
		 inset-inline-end: 0;
	}
	&:hover {
		&.product-hover:before {
			content: "";
			background: $black-5;
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			inset-inline-start: 0;
			 inset-inline-end: 0;
			bottom: 0;
			z-index: 100;
		}
		.product-info-buttons {
			display: block;
			z-index: 1000;
		}
	}
}

.spacing {
	padding: 1.5rem;
}

.search-background {
	background: $white;
	border-radius: 4px;
}

.search1 {
	background: $white;
	border-radius: 4px;
	overflow: hidden;
}

.search2 {
	background: $white;
	padding: 30px;
	border-radius: 4px;
	overflow: hidden;
}

.search3 {
	background: $white;
	border-radius: 4px;
	a.btn {
		font-size: .8625rem;
	}
}

.page-header .page-select {
	width: 20%;
}

.social li {
	float: $float-right;
	margin-inline-end: 15px;
	display: inline-block;
	list-style: none;
	font-size: 15px;
	float: $float-right;
}

.social-icons li {
	a {
		color: $white;
	}
	margin-inline-end: 15px;
	display: inline-block;
	list-style: none;
	font-size: 15px;
	width: 40px;
	height: 40px;
	margin: 1px;
	border-radius: 50%;
	border: 1px solid $white-2;
	line-height: 40px;
}

.social li a {
	color: $white-6;
	line-height: 0;
}

.product-tags {
	a {
		float: $float-left;
		margin-inline-end: 5px;
		margin-block-end: 5px;
		padding: 5px 10px;
		border-radius: 4px;
		color: #636d8c;
		font-size: 12px;
		border: 1px solid $border;
	}
	li a:hover {
		border-radius: 4px;
		color: $white;
	}
}

.icon-bg i {
	font-size: 1.5rem;
}

.icon-service {
	display: inline-flex;
	width: 120px;
	height: 120px;
	text-align: center;
	border-radius: 50%;
	align-items: center;
	justify-content: center;
}

.support-service {
	padding: 1.5rem 1.5rem;
	background: #f2f3f8;
	border-radius: 4px;
	i {
		float: $float-right;
		margin-inline-end: 15px;
		font-size: 1.5rem;
		line-height: 40px;
		width: 45px;
		text-align: center;
		height: 45px;
		border-radius: 50%;
		background: $white-3;
		border: 1px solid $white-3;
	}
	h6 {
		font-size: 20px;
		font-weight: 400;
		margin-block-end: 0;
	}
	p {
		margin-block-end: 0;
		font-size: 15px;
		color: $white-5;
	}
}

.counter-icon {
	margin-block-end: 1rem;
	display: inline-flex;
	width: 4rem;
	height: 4rem;
	padding: 1.3rem 1.4rem;
	border-radius: 50%;
	text-align: center;
	i {
		font-size: 1.2rem;
	}
}

.filter-product-social {
	.social-icon2 li {
		color: #212529;
		margin-inline-end: 35px;
		display: inline-block;
		list-style: none;
		font-size: 20px;
	}
	.social-icons li a {
		color: #212529;
	}
}

.rated-products .media {
	padding-block-end: 15px;
	border-block-end: 1px solid $border;
	&:last-child {
		padding-block-end: 0;
		border-block-end: 0;
	}
	img {
		width: 80px;
		height: 80px;
	}
}

.rated-products1 {
	height: 150px;
	overflow: hidden;
	.media img {
		width: 50px;
		height: 50px;
	}
	li {
		margin-block-end: 20px;
		border-block-end: 1px solid $white-1;
		padding-block-end: 20px;
		&:last-child {
			margin-block-end: 0;
			border-block-end: 0;
			padding-block-end: 0;
		}
	}
}

.rated-products img {
	border: 1px solid $border;
	padding: 5px;
	background: #f6f7fb;
}

.product-info-img a {
	position: absolute;
	top: 35px;
	 inset-inline-end: 30px;
}

.product-filter {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	margin-block-end: 15px;
	.product-filter-img {
		width: 60px;
		height: 60px;
	}
}

.product-filter-desc .product-filter-icons a {
	width: 2.5em;
	height: 2.5em;
	line-height: 2.4em;
	border-radius: 50%;
	font-size: 1em;
	display: inline-block;
	vertical-align: middle;
	margin: 0px 3px;
	&:hover {
		color: $white;
	}
}

.info-box small {
	font-size: 14px;
}

.info-box-icon {
	display: block;
	float: $float-right;
	height: 90px;
	width: 90px;
	text-align: center;
	font-size: 45px;
	line-height: 90px;
	background: $black-2;
	border-start-start-radius: 2px;
	border-end-start-radius: 2px;
}

.info-box {
	display: block;
	min-height: 90px;
	background: $white;
	width: 100%;
	border-radius: 4px;
	margin-block-end: 15px;
	box-shadow: 0 0 0 1px #dce3ec, 0 8px 16px 0 #dce3ec;
}

.info-box-icon>img {
	max-width: 100%;
}

.info-box-content {
	padding: 1.5rem 1.5rem;
	margin-inline-start: 90px;
}

.info-box-number {
	display: block;
}

.info-box-text {
	display: block;
	font-size: 14px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-transform: uppercase;
}

.info-box-more {
	display: block;
}

.product-item {
	img {
		height: 180px;
		width: 180px;
	}
	span {
		top: 15px;
		inset-inline-start: 15px;
		position: absolute;
	}
}

.product-item-wrap .product-item-price {
	.newprice {
		font-size: 18px;
		font-weight: 600;
	}
	.oldprice {
		margin-inline-start: 5px;
		font-size: 18px;
		font-weight: 600;
	}
}

.product-item2 .product-item2-img {
	img {
		height: 200px;
		width: 200px;
	}
	padding: 1.5rem 1.5rem;
}

.product-item2-rating {
	margin-inline-end: 10px;
	display: inline-block;
	vertical-align: middle;
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
	line-height: 1;
	white-space: nowrap;
	clear: both;
}

.product-item2 {
	border-radius: 3px 3px 0 0;
	overflow: hidden;
	border-block-end: 1px solid $border;
}

.owl-productimg img {
	background: #e7e9f1;
	padding: 20px;
}

.product-item2-desc .label-rating {
	margin-inline-end: 10px;
	display: inline-block;
	vertical-align: middle;
}
dd {
	display: inline-block;
}

.oldprice {
	font-size: 25px;
	font-weight: 600;
}

.product-multiinfo {
	.card {
		box-shadow: none;
		margin-block-end: 0;
		border-block-end: 1px solid $border;
	}
	.product-item img {
		width: 120px;
		height: 120px;
	}
}

.single-productslide .product-gallery .product-item {
	margin-block-end: 10px;
	overflow: hidden;
	background-color: $white;
}

.product-gallery {
	.product-item img {
		height: 350px;
		width: auto;
		display: inline-block;
		cursor: -webkit-zoom-in;
		cursor: zoom-in;
	}
	.product-sm-gallery {
		text-align: center;
		.item-gallery {
			img {
				max-width: 100%;
				max-height: 100%;
				-o-object-fit: cover;
				object-fit: cover;
				border-radius: 4px;
				cursor: -webkit-zoom-in;
				cursor: zoom-in;
			}
			width: 80px;
			height: 80px;
			border: 1px solid $border;
			padding: 5px;
			display: inline-block;
			overflow: hidden;
			line-height: 4.5;
		}
	}
}

.single-productslide .product-gallery-data {
	padding: 30px;
}

.product-gallery-data .product-gallery-data1 dt,
dd {
	display: inline-block;
}

.product-gallery-data {
	dl {
		margin-block-end: 5px;
	}
	.product-gallery-data1 {
		dt {
			width: 100px;
			float: $float-right;
		}
		dd {
			margin-inline-start: 2rem;
			margin-block-end: 0;
			vertical-align: baseline;
		}
	}
}

.product-gallery-rating {
	margin-inline-end: 10px;
	display: inline-block;
	vertical-align: middle;
	list-style: none;
	margin: 0;
	font-size: 18px;
	padding: 0;
	position: relative;
	line-height: 1;
	white-space: nowrap;
	clear: both;
}

.product-gallery-rats {
	margin-block-start: 20px;
}

.product-gallery-data {
	.label-rating {
		margin-inline-end: 10px;
		display: inline-block;
		vertical-align: middle;
	}
	.product-gallery-quty dt {
		display: inline-block;
	}
}

dd,
.product-gallery-data .product-gallery-size dt,
dd {
	display: inline-block;
}

.product-gallery-data .product-gallery-size .product-gallery-checks label {
	display: inline-block;
	margin-inline-start: 10px;
}

.product-card-img img {
	height: 180px;
	width: 180px;
}

.product-card-icons {
	position: absolute;
	top: 15px;
	inset-inline-start: 15px;
	 inset-inline-end: 15px;
	display: inline-block;
}

.product-card-footer .product-footer-wrap .footer-wrap-price {
	font-size: 16px;
	font-weight: 600;
	del {
		margin-inline-start: 5px;
	}
}

.product-card4-footer .product-card4-wrap .product-card4-price h6,
.product-card5-footer .product-card5-price h6 {
	font-size: 25px;
	font-weight: 600;
}

.product-card6-footer {
	.product-card6-price h6 {
		font-size: 25px;
		font-weight: 600;
	}
	.product-card6-info del {
		margin-inline-end: 5px;
	}
}

.product-card7-footer .product-card7-price {
	h6 {
		font-size: 25px;
		font-weight: 600;
	}
	del,
	span {
		margin-inline-start: 5px;
	}
}

.team-section .team-img img {
	max-width: 100px;
	box-shadow: 0 0 0 1px #eaebf7, 0 8px 16px 0 $background;
}

.checklist-task .checklist-desc .check-data {
	span {
		font-size: 13px;
	}
	.check-icon {
		margin-block-start: 0;
	}
}

.banner1 {
	.carousel-inner img {
		width: 100%;
		max-height: 460px;
	}
	.carousel-item:before {
		content: '';
		display: block;
		position: absolute;
		background: $black-6;
		width: 100%;
		height: 100%;
		 inset-inline-end: 0;
		top: 0;
	}
	.carousel-control {
		width: 0;
		&.left,
		&.right {
			opacity: 1;
			background-image: none;
			background-repeat: no-repeat;
			text-shadow: none;
		}
		&.left span,
		&.right span {
			padding: 15px;
		}
		.glyphicon-chevron-left,
		.glyphicon-chevron-right,
		.icon-next,
		.icon-prev {
			position: absolute;
			top: 45%;
			z-index: 5;
			display: inline-block;
		}
		.glyphicon-chevron-left,
		.icon-prev {
			inset-inline-start: 0;
		}
		.glyphicon-chevron-right,
		.icon-next {
			 inset-inline-end: 0;
		}
		&.left span,
		&.right span {
			background: $white-1;
			color: $white;
		}
		&.left span:hover,
		&.right span:hover {
			background: $white-3;
		}
	}
	.header-text {
		position: absolute;
		inset-inline-start: 0;
		 inset-inline-end: 0;
		color: $white;
	}
	.slider .header-text {
		top: 29%;
	}
	.header-text {
		h2 {
			font-size: 40px;
		}
		h3 {
			font-size: 25px;
		}
		h2 span {
			padding: 10px;
		}
		h3 span {
			padding: 15px;
		}
	}
}

@media (max-width: 480px) {
	.banner1 {
		.slider {
			.header-text {
				top: 30% !important;
			}
			img {
				height: 500px;
			}
		}
		&.slider-images .header-text {
			top: 15% !important;
		}
	}
	.sptb-tab.sptb-2.pt-10 {
		padding-block-start: 6.5rem !important;
	}
}

@media (min-width: 481px) and (max-width: 767px) {
	.banner1 .slider {
		.header-text {
			top: 35% !important;
		}
		img {
			height: 500px;
		}
	}
}

@media (min-width: 768px) and (max-width: 992px) {
	.banner1 .slider {
		.header-text {
			top: 32% !important;
		}
		img {
			height: 570px;
		}
	}
	.sptb-5 h1 {
		font-size: 2rem;
	}
	.item-card9 {
		p {
			display: none;
		}
		.d-flex.mt-4 {
			margin-block-start: 2rem !important;
		}
	}
	.software-cat {
		margin: 10px !important;
	}
}

@media (min-width: 993px) and (max-width: 1240px) {
	.banner1 .slider .header-text {
		top: 23% !important;
	}
	.item-card9 {
		p {
			display: none;
		}
		.d-flex.mt-4 {
			margin-block-start: 2rem !important;
		}
	}
}

.input-indec {
	.quantity-left-minus.btn {
		border-start-end-radius: 0;
		border-end-end-radius: 0;
		border-inline-end: 0;
	}
	.quantity-right-plus.btn {
		border-start-start-radius: 0;
		border-end-start-radius: 0;
		border-inline-start: 0;
	}
}

.cat-item {
	position: relative;
	a {
		position: absolute;
		top: 0;
		inset-inline-start: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
	.cat-img img {
		width: 60px;
		height: 60px;
		margin: 0 auto;
	}
	.cat-desc {
		margin-block-start: 15px;
		color: #212529;
	}
}

.about img {
	width: 60px;
	height: 60px;
	text-align: center;
}

.Category .item-card .item-card-desc .item-card-text {
	position: absolute;
	top: 40%;
}

.business-item .item-card .item-card-desc .item-card-text {
	top: 40%;
}

@media (max-width: 1300px) and (min-width: 400px) {
	.classifieds-content .item-search-menu ul li a,
	.item-search-menu ul li a {
		display: block;
		margin: 3px;
		border-radius: 4px;
	}
}

.accent-2 {
	width: 30px;
}

.sptb {
	padding-block-start: 3rem;
	padding-block-end: 3rem;
}

.sptb-1 {
	padding-block-start: 6.5rem;
	padding-block-end: 4.5rem;
}

.sptb-2 {
	padding-block-start: 5rem;
	padding-block-end: 10rem;
}

.sptb-3 {
	padding-block-start: 7rem;
	padding-block-end: 10rem;
}

.sptb-4 {
	padding-block-start: 5rem;
	padding-block-end: 11rem;
}

.sptb-5 {
	padding-block-start: 8.5rem;
	padding-block-end: 4.5rem;
}

.sptb-8 {
	padding-block-start: 7rem;
	padding-block-end: 12rem;
}

.categories-1 {
	margin-block-start: -120px;
}

.section-title {
	p {
		text-align: center;
		font-size: 18px;
		margin-block-end: 0;
		color: #656f90;
	}
	padding-block-end: 2rem;
	h1 {
		margin-block-end: .5rem;
		color: #2e384d;
		font-size: 36px;
		font-weight: 500;
		z-index: 1;
		font-family: 'Quicksand', sans-serif;
	}
	h2 {
		font-family: 'Quicksand', sans-serif;
		font-size: 2rem;
		font-weight: 500;
	}
}

.section-title2 {
	p {
		font-size: 16px;
		margin-block-end: 0;
	}
	padding-block-end: 2rem;
	h1 {
		margin-block-end: .5rem;
		color: #2e384d;
		font-size: 36px;
		font-weight: 400;
		z-index: 1;
		font-family: 'Work Sans', sans-serif;
	}
}

.index-search-select .select2 {
	width: 100% !important;
}

.servic-data {
	h4 {
		font-size: 19px;
	}
	p {
		font-size: 14px;
		line-height: 21px;
	}
}

@media (min-width: 992px) {
	.select2-container .select2-selection--single {
		border-radius: 0 !important;
	}
	.slider-images img {
		height: 400px !important;
		background: cover;
		inset-inline-start: 0;
		 inset-inline-end: 0;
		width: 100% !important;
	}
	.banner1 .slider .header-text {
		top: 29%;
	}
}

@media (max-width: 991px) {
	.select2-container .select2-selection--single {
		border-radius: 4px !important;
	}
	.sptb-4 {
		padding-block-start: 4.3rem;
		padding-block-end: 10rem;
	}
	.sptb-3 {
		padding-block-start: 4.2rem;
		padding-block-end: 10rem;
	}
	.banner-1 .item-search-tabs .search-background .form-control {
		border-radius: 4px !important;
		border-block-end: 1px !important;
	}
	.item-card9-img {
		height: 100% !important;
	}
	.blog-list-1 {
		height: 100% !important;
	}
	.banner-1 .search-background {
		.form-control {
			border-radius: 4px !important;
		}
		.form1 {
			border-radius: 0;
			border-inline-start: 1px solid $border !important;
		}
	}
	.item-search-tabs a.btn {
		border-start-start-radius: 4px !important;
		border-end-start-radius: 4px !important;
		border-radius: 4px !important;
		 inset-inline-end: 0;
	}
	.content-text .display-5 {
		font-size: 1.8rem;
	}
	.counter-status.status {
		margin-block-start: 1.5rem;
	}
	.margin-block-start {
		margin-block-start: 4.5rem !important;
	}
	.desktoplogo-1 {
		display: none;
	}
	.search-background {
		background: 0 0 !important;
		border-radius: 0;
	}
	.padding-block-end {
		padding-block-end: 0 !important;
	}
	.banner-2 .form-control,
	.item-search-tabs .form-control.border {
		border-radius: 4px !important;
	}
	.banner-1 .search-background .btn-lg {
		border-radius: 4px !important;
		 inset-inline-end: 0 !important;
	}
	.banner1 .slide-header-text {
		top: 5% !important;
	}
	.sptb-2 {
		padding-block-start: 3rem;
		padding-block-end: 8.5rem;
	}
	.sticky-wrapper.is-sticky {
		.horizontal-main {
			background: 0 0;
		}
		.desktoplogo-1 {
			display: none !important;
		}
	}
	.bannerimg {
		padding: 6.5rem 0 3.2rem 0 !important;
		background-size: cover;
	}
	.sticky-wrapper {
		height: 0 !important;
	}
	.select2.select2-container.select2-container--default.select2-container--focus {
		width: 100% !important;
		overflow: hidden;
	}
	.item2-gl .item2-gl-nav .select2.select2-container.select2-container--default.select2-container--focus,
	.item2-gl-nav .select2.select2-container.select2-container--default.select2-container--focus {
		width: 150px !important;
		text-align: start;
	}
	.map1 {
		height: 300px !important;
	}
	.hor-menu-nav {
		background: transparent !important;
		border: 0 !important;
		.navbar-toggler {
			font-size: 16px;
			width: 100%;
			text-align: start;
			padding: 15px;
			display: flex;
		}
		i {
			margin-inline-start: 8px;
			vertical-align: middle;
			margin-inline-start: auto;
		}
		.navbar-nav {
			background: $white;
			box-shadow: 14px 14px 40px 0 rgba(118, 126, 173, 0.08);
			border: 1px solid $border;
			border-radius: 0 0 4px 4px;
		}
		li a {
			border-inline-start: 0 !important;
			border-block-end: 0;
		}
	}
	.header-icons {
		.header-right-icons {
			margin: 0 auto !important;
		}
		.nav-link span {
			margin-block-start: auto !important;
		}
	}
	.hor-menu-nav li.active a {
		border-block-end: 0 !important;
	}
}

.banners-image .horizontalMenu>.horizontalMenu-list>li>a {
	color: #2e384d !important;
}

.sticky-wrapper.absolute.banners {
	.horizontal-main {
		background: $white;
		.horizontalMenu>.horizontalMenu-list>li>a {
			color: #2e384d !important;
			>.fa {
				color: #2e384d !important;
			}
		}
	}
	.horizontalMenu>.horizontalMenu-list>li {
		&:hover>a .fa,
		>a.active .fa {
			color: $white !important;
		}
	}
}

.banner-1 {
	.item-search-tabs .search-background .form-control {
		border-radius: 4px 0 3px 0;
		border-block-end: 0 !important;
	}
	.search-background {
		.form-control {
			border-radius: 4px;
			border: 0;
		}
		.form1 {
			border-radius: 4px 0 3px 0;
			border-inline-start: 1px solid $border !important;
		}
		.btn-lg {
			border-start-start-radius: 0px;
			border-strat-end-radius: 3px;
			border-end-end-radius: 3px;
    			border-end-strat-radius: 0px;
			position: relative;
		}
		.select2-container--default .select2-selection--single {
			border-radius: 0;
			border-inline-end: 0 !important;
		}
	}
	.header-text,
	.header-text1 {
		inset-inline-start: 0;
		 inset-inline-end: 0;
		color: $white;
	}
	.header-text h1 {
		margin-block-end: .3rem;
		font-weight: 500;
		font-family: 'Quicksand', sans-serif;
	}
}

.header-text h1 {
	font-family: 'Quicksand', sans-serif;
	font-weight: 500;
}

.banner-1 .header-text p {
	margin-block-end: 1.5rem;
	font-size: 20px;
	margin-block-start: 0.4rem;
	font-weight: 300;
}

.banner-2 {
	.form-control {
		border-start-start-radius: 2px;
		border-start-end-radius: 2px;
		border: 0 !important;
	}
	.header-text {
		inset-inline-start: 0;
		 inset-inline-end: 0;
		color: $white;
		p {
			margin-block-end: 0;
			font-size: 16px;
		}
	}
}

.bannerimg {
	padding: 7.5rem 0 3.7rem 0;
	background-size: cover;
	.header-text h1 {
		margin-block-end: .5rem;
		font-size: 2.2rem;
	}
}

.about-1 {
	position: relative;
	.header-text {
		inset-inline-start: 0;
		 inset-inline-end: 0;
		color: $white;
	}
}

.country .dropdown-menu {
	height: 233px;
	overflow: scroll;
}

.test-carousel {
	.carousel-control-next i,
	.carousel-control-prev i {
		font-size: 2rem;
		color: $black;
		padding: .5rem;
	}
}

.support-list li {
	a {
		margin-block-end: .5rem;
		display: flex;
	}
	i {
		margin-inline-end: .7rem;
		margin-block-start: 4px;
	}
}

.profile-pic-img {
	position: relative;
	width: 5rem;
	height: 5rem;
	line-height: 5rem;
	font-size: 2rem;
	text-align: center;
	margin: 0 auto;
	span {
		position: absolute;
		width: 1rem;
		height: 1rem;
		border-radius: 50px;
		 inset-inline-end: -1px;
		top: .5rem;
		border: 2px solid $white;
	}
}

.sub-newsletter {
	display: inline-block;
}

.user-tabel span {
	margin: 0 auto;
}

.settings-tab .tabs-menu {
	margin-block-end: 25px;
	li {
		width: 33.3%;
		display: block;
		a {
			padding: .75rem 1rem;
			background: #eaeaf5;
			color: $black;
			display: block;
			text-align: center;
			margin: 5px;
			border-radius: 4px;
			&.active {
				color: $white;
				border-radius: 4px;
				text-align: center;
			}
		}
	}
}

.ads-tabs .tabs-menus {
	margin-block-end: 2rem;
	ul li {
		a {
			border: 1px solid $border;
			color: #2e384d;
			padding: 10px 20px 11px 20px;
			border-radius:3px;
		}
		margin-inline-end: 1rem;
		&:last-child {
			margin-inline-end: 0;
		}
	}
}

#price {
	border: 0;
	font-weight: 500;
	font-size: 18px;
}

.description p {
	font-size: 16px;
	line-height: 22px;
}

.blog-list {
	p {
		font-size: 16px;
		line-height: 21px;
	}
	h3 {
		font-size: 1.3rem !important;
	}
}

.blog-grid {
	p {
		font-size: 16px;
		line-height: 22px;
	}
	h3 {
		font-size: 1.3rem !important;
	}
}

.blog-detail p {
	font-size: 14px;
	line-height: 22px;
}

.fea-ad .card,
.cat-slide .card {
	margin-block-end: 0;
}

.fade.in {
	opacity: 1;
}

.owl-carousel {
	position: relative;
	.owl-item {
		position: relative;
		cursor: url(../plugins/owl-carousel/cursor.png), move;
		overflow: hidden;
	}
}

.owl-nav {
	display: block;
}

#small-categories .owl-nav {
	.owl-next,
	.owl-prev {
		top: 58%;
	}
}

.slider .owl-nav {
	.owl-prev {
		inset-inline-start: 15px;
	}
	.owl-next {
		 inset-inline-end: 15px;
	}
}

.owl-nav {
	.owl-prev {
		position: absolute;
		top: 50%;
		inset-inline-start: -25px;
		 inset-inline-end: -1.5em;
		margin-block-start: -1.65em;
	}
	.owl-next {
		position: absolute;
		top: 50%;
		 inset-inline-end: -25px;
		margin-block-start: -1.65em;
	}
}

@media (max-width: 480px) {
	.owl-nav {
		.owl-prev {
			inset-inline-start: -10px;
		}
		.owl-next {
			 inset-inline-end: -10px;
		}
	}
	.countdown-timer-wrapper {
		.timer .timer-wrapper .time {
			width: 70px;
			height: 70px;
			font-size: 1.3rem !important;
			margin-block-end: -25px;
		}
		margin: 39px auto 15px auto !important;
	}
	.categories-1 .banner-1 .header-text p {
		margin-block-end: .5rem;
		font-size: 14px;
	}
}

.owl-nav button {
	display: block;
	font-size: 1.3rem !important;
	line-height: 2em;
	border-radius: 50%;
	width: 3rem;
	height: 3rem;
	text-align: center;
	background: $white-5 !important;
	border: 1px solid $border !important;
	z-index: 97;
	box-shadow: 0 4px 15px rgba(67, 67, 67, 0.15);
	color: $white;
	opacity: 0.5;
	&:before {
		content: "";
		position: absolute;
		z-index: -1;
		top: 0;
		inset-inline-start: 0;
		 inset-inline-end: 0;
		bottom: 0;
		background: #2098d1;
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
		-webkit-transform-origin: 50% 0;
		transform-origin: 50% 0;
		-webkit-transition-property: transform;
		transition-property: transform;
		-webkit-transition-duration: .5s;
		transition-duration: .5s;
		-webkit-transition-timing-function: ease-out;
		transition-timing-function: ease-out;
	}
}

.owl-carousel:hover .owl-nav button {
	background: rgb(255, 255, 255) !important;
	-webkit-transition-property: transform;
	transition-property: transform;
	-webkit-transition-duration: .5s;
	transition-duration: .5s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
	animation: sonarEffect 1.3s ease-out 75ms;
	color: $white;
}

.owl-nav>div i {
	margin: 0;
	color: $white;
}

.owl-theme .owl-dots {
	text-align: center;
	-webkit-tap-highlight-color: transparent;
	position: absolute;
	bottom: .65em;
	inset-inline-start: 0;
	 inset-inline-end: 0;
	z-index: 99;
	.owl-dot {
		display: inline-block;
		zoom: 1;
		span {
			width: 1em;
			height: 1em;
			margin: 5px 7px;
			background: $black-3;
			display: block;
			-webkit-backface-visibility: visible;
			transition: opacity .2s ease;
			border-radius: 30px;
		}
	}
}

.testimonial-owl-carousel img {
	margin: 0 auto;
	text-align: center;
}

.center-block {
	float: none;
	text-align: center;
	margin-inline-start: auto;
	margin-inline-end: auto;
}

.owl-carousel.owl-drag .owl-item {
	inset-inline-start: 0 !important;
	 inset-inline-end: 0;
}

.wishlist {
	background: #2e384d;
	&.active {
		background: #e62a72;
	}
}

.constuction-logo {
	border-block-end: 3px solid;
	border-radius: 4px;
}

.coupon-code .coupon {
	background: rgba(242, 187, 26, 0.1);
	border: 2px dashed #f2bb1a;
	text-align: center;
	border-radius: 4px;
}

@media (max-width: 767px) {
	.icon-card li {
		&.mb-0 {
			&:last-child {
				margin-block-end: 0 !important;
			}
			margin-block-end: 0.5rem !important;
		}
		width: 100% !important;
	}
	.item-cards7-ic li {
		width: 100% !important;
	}
	.item-card7-desc ul.d-flex li {
		margin-block-end: 10px;
		width: 100% !important;
	}
	.counter-status.status-1 {
		margin-block-start: 1.5rem;
	}
	.item-card7-desc ul.d-flex {
		display: block !important;
	}
	.item-card2-desc ul.d-flex li {
		margin-block-end: 10px;
	}
	.item7-card-desc.d-flex,
	.item-card2-desc ul.d-flex {
		display: block !important;
	}
}

.horizontalMenu>.horizontalMenu-list>li>a.btn:hover {
	background: #fb9512;
}

@media (max-width: 768px) {
	.tabs-menu1 ul li a {
		display: block;
		width: 100%;
		border: 1px solid $border;
		margin: 1px;
		border-radius: 4px;
	}
	.wideget-user-tab .tabs-menu1 ul li .active {
		border: 1px solid $border;
		border-radius: 4px;
	}
}

.profile-pic {
	text-align: center;
	.d-md-flex {
		text-align: start;
	}
}

@media (max-width: 480px) {
	.d-flex.ad-post-details {
		display: block !important;
	}
	.tabs-menu1 ul li a {
		margin: 2px;
	}
	.list-id .col {
		display: block;
		width: 100%;
	}
	.ads-tabs .tabs-menus ul li {
		a {
			width: 100%;
			display: block;
		}
		margin-block-end: 10px;
		margin-inline-end: 0;
		width: 100%;
	}
	.zoom-container .arrow-ribbon2 {
		font-size: 20px;
		&:before {
			 inset-inline-end: -17px;
		}
	}
}

@media (min-width: 561px) and (max-width: 767px) {
	.tabs-menu1 ul li {
		display: block;
		/* width: 49%; */
		/* margin: 1px; */
	}
}

.owl-carousel {
	.owl-dots {
		margin: 0 auto;
		text-align: center;
	}
	button.owl-dot {
		margin: 10px 10px 0 10px;
		border-radius: 50%;
		width: 10px;
		height: 10px;
		text-align: center;
		display: inline-block;
		border: none;
	}
}

.owl-carousel-icons5 .owl-nav {
	.owl-prev {
		position: absolute;
		top: 42%;
		inset-inline-start: auto;
		 inset-inline-end: -24px;
		margin-block-start: -1.65em;
	}
	.owl-next {
		position: absolute;
		top: 58%;
		inset-inline-start: auto;
		 inset-inline-end: -24px;
		margin-block-start: -1.65em;
	}
}

.owl-carousel-icons4.owl-carousel .owl-item img {
	margin: 0 auto;
}

#exzoom {
	width: 100%;
}

:focus {
	outline: 0 !important;
}

#defaultCarousel {
	.item p {
		font-size: 14px;
		font-weight: 300;
	}
	.item7-card-desc a {
		font-size: 13px;
	}
}

.news p {
	font-size: 16px;
	line-height: 21px;
}
.header-menu1 {
	float: none !important;
}

@media (max-width: 1024px) and (min-width: 992px) {
	.owl-carousel-icons6 .product-item2-desc .product-item2-rating {
		display: block;
	}
}
@media (min-width: 992px) and (max-width: 1350px) {
	.header-menu1 .input-group {
		width: 140px;
	}
}

@media (min-width: 568px) and (max-width: 767px) {
	#container1 {
		min-height: 460px !important;
	}
	.software-cat {
		margin: 10px !important;
	}
}

@media (max-width: 991px) {
	#container1 {
		min-height: 515px !important;
	}
	.header-icons {
		.dropdown-menu-arrow.dropdown-menu-end {
			&:after,
			&:before {
				display: none;
			}
		}
		.dropdown {
			position: inherit !important;
		}
		.dropdown-menu {
			inset-inline-start: 7px !important;
			 inset-inline-end: 7px !important;
			transform: inherit !important;
			width: inherit !important;
			top: 55px !important;
			border-start-start-radius: 0px;
			border-start-end-radius: 0px;
			border-end-end-radius: 4px;
    			border-end-start-radius: 4px;
		}
	}
	.software-cat {
		margin: 10px !important;
	}
}

@media (max-width: 992px) {
	.header-menu1 {
		.input-group {
			padding: 10px !important;
		}
		a.btn {
			width: 220px;
			text-align: center !important;
			margin: 0 auto !important;
			float: none !important;
		}
	}
	.testimonial-owl-carousel .owl-nav {
		.owl-prev,
		.owl-next {
			top: 20% !important;
		}
	}
}

.product-item2-desc p {
	font-size: 16px;
	line-height: 21px;
}

@media (max-width: 320px) {
	.list-inline.wizard {
		display: flex;
	}
}

#homeVideo button.btn.btn-default {
	background: rgba(34, 40, 74, 0.7);
	position: absolute;
	 inset-inline-end: 5px;
	top: 5px;
	border-radius: 25px;
	padding: 6px 3px;
	line-height: 25px;
	z-index: 5;
	color: $white;
	&:hover {
		background: rgb(34, 40, 74);
	}
}

.vertical-scroll {
	.news-item {
		border: 1px solid $border !important;
		margin-block-end: 15px;
		padding: 10px;
		td:last-child {
			width: 76%;
		}
	}
	.item {
		border: 1px solid $border !important;
		margin-block-end: 5px;
		margin-block-start: 5px;
		.p-5 {
			padding: 1rem !important;
		}
	}
	margin-block-end: 0;
}

.vertical-scroll1 .item {
	border: 1px solid $border !important;
	margin-block-end: 15px;
	background: $white;
	a {
		text-decoration: intial;
	}
}

.vertical-scroll .item2 {
	border-block-end: 1px solid $border !important;
	padding: 12px 25px;
}

@media (max-width: 480px) {
	.employers-details {
		display: none;
	}
	.hide-details {
		height: 128px !important;
	}
}

@media (max-width: 400px) {
	.employers-btn,
	.distracted {
		display: none;
	}
	.media.testimonia{
     display: block !important ;
	}
	.media-left.owl-mediaimg{
       margin-block-end: 10px;
	}
}

.pt-10 {
	padding-block-start: 8rem !important;
}

@media (max-width: 767px) {
	.rating-stars .rating-stars-container .rating-star.sm {
		font-size: 13px;
	}
}

@media (max-width: 568px) {
	.items-gallery .items-blog-tab-heading {
		padding: 15px 0;
		display: block;
	}
	.items-blog-tab-heading .items-blog-tab-menu li a {
		margin-inline-start: 0;
		margin-inline-end: 0;
	}
}

code {
	font-size: 14px;
	background: #eaeaf5;
	padding: 3px 5px;
	border-radius: 4px;
	border: 1px solid $border;
	font-size: 85%;
	color: inherit;
	word-break: break-word;
}

button#ihavecookiesBtn {
	margin-inline-start: 0 !important;
}

#g.pe-cookie-message {
	position: fixed;
	z-index: 1003;
	inset-inline-start: 0;
	bottom: 30px;
	max-width: 375px;
	background-color: rgb(255, 255, 255);
	padding: 20px;
	border-radius: 4px;
	border: 1px solid $border;
	box-shadow: 0 8px 20px 0 rgba(21, 17, 148, 0.2);
	margin-inline-start: 30px;
	text-align: start;
	display: block !important;
	h4 {
		font-size: 18px;
		font-weight: 500;
		margin-block-end: 10px;
	}
	h5 {
		font-size: 15px;
		font-weight: 500;
		margin-block-end: 10px;
	}
	p,
	ul {
		color: #2e384d;
		font-size: 15px;
		line-height: 1.5em;
	}
	p:last-child {
		margin-block-end: 0;
		text-align: start;
	}
	li {
		width: 49%;
		display: -webkit-inline-box;
	}
	a {
		text-decoration: none;
		font-size: 15px;
		padding-block-end: 2px;
		transition: all .3s ease-in;
	}
	button {
		border: none;
		color: $white;
		font-size: 15px;
		padding: 7px 18px;
		border-radius: 4px;
		margin-block-start: 4px;
		cursor: pointer;
		transition: all .3s ease-in;
	}
}

button {
	&#ihavecookiesBtn {
		border: none;
		color: $white;
		font-size: 15px;
		padding: 7px 18px;
		border-radius: 4px;
		margin-block-start: 4px;
		cursor: pointer;
		transition: all .3s ease-in;
	}
	&#g.pe-cookie-advanced {
		margin-inline-start: 4px;
	}
}

@media (max-width: 390px) {
	button#g.pe-cookie-advanced {
		margin-inline-start: 0 !important;
	}
	#g.pe-cookie-message p button {
		width: 100%;
	}
}

@media (max-width: 480px) {
	#g.pe-cookie-message {
		margin: 0 15px;
		display: block;
		 inset-inline-end: 0;
		box-shadow: 0 0 20px 0 rgba(40, 37, 89, 0.9);
		display: none !important;
	}
}

#g.pe-cookie-message {
	button:disabled {
		opacity: .3;
	}
	input[type=checkbox] {
		float: none;
		margin-block-start: 0;
		margin-inline-end: 5px;
	}
}


.ui-widget-header {
	border: 1px solid #2c4359;
	color: #e1e463;
	font-weight: 700;
	a {
		color: #e1e463;
	}
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
	border: 1px solid $border;
	font-weight: 700;
	color: #333;
}

#particles-js {
	position: absolute;
	height: 100%;
	inset-inline-start: 0;
	 inset-inline-end: 0;
	width: 100%;
	top: 0;
	bottom: 0;
	z-index: 1;
}

h1.animated-text {
	font-family: monospace;
	overflow: hidden;
	border-inline-end: .15em solid orange;
	white-space: nowrap;
	margin: 0 auto;
	letter-spacing: .15em;
	animation: typing 3.5s steps(30, end), blinking-cursor 0.5s step-end infinite;
}

@keyframes typing {
	from {
		width: 0;
	}
	to {
		width: 100%;
	}
}

@keyframes blinking-cursor {
	from,
	to {
		border-color: transparent;
	}
	50% {
		border-color: orange;
	}
}

.banner1 .slide-header-text {
	top: 0%;
	z-index: 98 !important;
}

.slider-header .item:before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	inset-inline-start: 0;
	 inset-inline-end: 0;
	display: block;
	z-index: 1;
	top: 0;
}

a.typewrite {
	color: $white !important;
	font-size: 2.5rem;
	font-family: 'Work Sans', sans-serif;
	font-weight: 400;
}

.pattern {
	background: url(../images/patterns/6.png);
	&:before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		inset-inline-start: 0;
		 inset-inline-end: 0;
		display: block;
		z-index: 0;
		top: 0;
	}
}

.pattern-1 {
	&:before {
		background: linear-gradient(-225deg, rgba(72, 1, 255, 0.7) 0, rgba(121, 24, 242, 0.7) 48%, rgba(172, 50, 228, 0.7) 100%);
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		inset-inline-start: 0;
		 inset-inline-end: 0;
		display: block;
		z-index: 0;
		top: 0;
	}
	background: url(../images/media/21.jpg) center;
	border-start-start-radius: 4px;
	border-start-end-radius: 4px;
	overflow: hidden;
	background-size: cover;
}

.user-pattern {
	background: url(../images/banners/banner3.jpg) center;
	border-start-start-radius: 4px;
	border-start-end-radius: 4px;
	overflow: hidden;
	background-size: cover;
}

.single-page {
	button.dark {
		border-color: #ff4931;
		background: #ff4931;
	}
	.move button.dark {
		border-color: #e0b722;
		background: #e0b722;
	}
	.splits p {
		font-size: 18px;
	}
	button {
		&:active {
			box-shadow: none;
		}
		&:focus {
			outline: 0;
		}
	}
	>.wrapper3 {
		width: 100%;
		padding-block-start: 8rem;
	}
}

.single-page {
	.wrapper {
		&.wrapper2 {
			background: $white;
			width: 100%;
			transition: all .5s;
			color: $white;
			overflow: hidden;
			border-radius: 4px;
			text-align: center;
			top: -37%;
			inset-inline-start: 4%;
		}
		background: $white;
		box-shadow: 0 0 12px 0 rgba(21, 17, 148, 0.06);
		transition: all .5s;
		color: #838ab6;
		overflow: hidden;
		border-radius: 4px;
		border: 1px solid #dbddf1;
		>form {
			width: 100%;
			transition: all .5s;
			background: $white;
			width: 100%;
		}
		.card-body {
			padding: 30px;
		}
		>form:focus {
			outline: 0;
		}
		#login {
			visibility: visible;
		}
		#register {
			transform: translateY(-80%) translateX(100%);
			visibility: hidden;
		}
		&.move {
			#register {
				transform: translateY(-80%) translateX(0);
				visibility: visible;
			}
			#login {
				transform: translateX(-100%);
				visibility: hidden;
			}
		}
		>form>div {
			position: relative;
			margin-block-end: 15px;
		}
		label {
			position: absolute;
			top: -7px;
			font-size: 12px;
			white-space: nowrap;
			background: $white;
			text-align: start;
			inset-inline-start: 15px;
			padding: 0 5px;
			color: #2e384d;
			//pointer-events: none;
		}
		h3,
		h4 {
			margin-block-end: 25px;
			font-size: 22px;
			color: #2e384d;
		}
		input {
			height: 40px;
			padding: 5px 15px;
			width: 100%;
			border: solid 1px #eaebf7;
			&:focus {
				outline: 0;
				border-color: #eaebf7;
			}
		}
	}
	>.wrapper.move {
		inset-inline-start: 45%;
		input:focus {
			border-color: #e0b722;
		}
	}
	width: 100%;
	margin: 0 auto;
	display: table;
	position: relative;
	border-radius: 4px;
	&.single-pageimage {
		min-height: 387px;
		&::before {
			content: '';
			background: $white-5;
		}
	}
	>.log-wrapper {
		display: table-cell;
		vertical-align: middle;
		text-align: end;
		color: $white;
	}
}

@media (min-width: 1024px) {
	.single-pageimage .log-wrapper {
		margin-block-start: 100px;
	}
}

.customerpage .btn-icon {
	border: 1px solid rgba(96, 94, 126, 0.2);
}

.imagecheck-figure {
	height: 100%;
}

.feature .icons {
	position: relative;
	display: inline-block;
	width: 3em;
	height: 3em;
	line-height: 3em;
	vertical-align: middle;
	border-radius: 50%;
	border: 1px solid $white-1;
}

.activity {
	position: relative;
	border-inline-start: 1px solid #dbddf1;
	margin-inline-start: 16px;
	.img-activity {
		width: 42px;
		height: 42px;
		text-align: center;
		line-height: 34px;
		border-radius: 50%;
		position: absolute;
		inset-inline-start: -18px;
		-webkit-box-shadow: 0 0 0 0.5px #f8f9fa;
		box-shadow: 0 0 0 0.5px #f8f9fa;
	}
	.item-activity {
		margin-inline-start: 40px;
		margin-block-end: 19px;
	}
}

.status-border {
	border: 1px solid $border !important;
	&:hover {
		box-shadow: 0 5px 12px rgba(126, 142, 177, 0.2);
	}
}

.fa-1 {
	font-size: 1.5em;
}

@media (min-width: 1280px) {
	.container {
		max-width: 1200px;
	}
	.input-group.input-indec{
	   width: 65%;
	}
}

body .modal-open {
	padding-inline-end: 0 !important;
	overflow: hidden;
}

.info .counter-icon {
	border: 1px solid rgba(255, 255, 255, 0.03) !important;
	background: rgba(251, 246, 246, 0.17);
}

.blog-list .item7-card-img {
	height: 196px !important;
}

.blog-list-1 {
	height: 196px;
}

@media screen and (max-width: 1279px) {
	.blog-list .item7-card-img {
		height: 100% !important;
	}
	.item-card2 p.leading-tight,
	.item-card9 p.leading-tight {
		-webkit-line-clamp: 1 !important;
		-moz-line-clamp: 1 !important;
	}
}

.testimonia .owl-controls,
.testimonia1 .owl-controls {
	margin-block-end: 20px;
	margin-block-start: 0;
}


/* background animations*/

.animation-zidex {
	z-index: 1000;
}

@keyframes animate {
	0% {
		transform: translateY(0) rotate(0deg);
		opacity: 1;
		border-radius: 0;
	}
	100% {
		transform: translateY(-1000px) rotate(720deg);
		opacity: 0;
		border-radius: 4px;
	}
}
i.car {
	line-height: 18px;
}
/*Detail-page*/

.video-list-thumbs .class-icon {
	font-size: 4rem;
	opacity: .8;
	position: absolute;
	 inset-inline-end: 0;
	inset-inline-start: 0;
	top: 42%;
	margin: 0 auto;
	text-align: center;
	transition: all .5s ease-in-out;
	cursor: pointer;
}

/*Banner*/

.bannner-owl-carousel1 {
	&.banner-height {
		height: auto;
	}
	.slider-img {
		height: 100%;
		width: 100%;
		position: absolute;
		background-size: cover;
	}
	.banner-title {
		text-transform: capitalizee;
		font-weight: 400;
		margin-block-end: 0.2rem;
		font-family: 'Work Sans', sans-serif;
	}
	.banner-subtitle,
	.banner-desc {
		font-weight: 300;
	}
	.owl-nav button {
		border: 1px solid rgba(255, 255, 255, 0.12) !important;
		background: $white-1 !important;
		border-radius: 3px !important;
		color: $white;
	}
	&.owl-carousel:hover .owl-nav button {
		background: $white-3 !important;
	}
	.owl-nav button span {
		color: $white;
	}
}

@media (min-width: 992px) {
	.bannner-owl-carousel1 .item {
		height: 600px !important;
	}
	.headerstyle1 .sticky-wrapper.is-sticky .desktoplogo{
		display: block;
		margin-block-start: 8px;
	}
}

@media (min-width: 1367px) {
	.bannner-owl-carousel1 {
		.banner-text {
			position: absolute;
			top: 250px;
			z-index: 2;
			width: 100%;
			color: $white;
			text-align: center;
		}
		.banner-title {
			font-size: 2.7rem;
		}
		.banner-subtitle {
			font-size: 2rem;
		}
		.banner-desc {
			font-size: 1.2rem;
			line-height: 28px;
			margin-block-start: 16px;
		}
	}
}

@media (max-width: 567px) {
	.bannner-owl-carousel1 .item {
		height: 600px;
	}
}

@media (max-width: 767px) and (min-width: 568px) {
	.bannner-owl-carousel1 .item {
		height: 500px;
	}
}

@media (max-width: 767px) {
	.bannner-owl-carousel1 {
		.banner-text {
			top: 200px;
			position: relative;
			z-index: 2;
			color: $white;
			text-align: center;
			&.banner-search {
				top: 230px;
				position: relative;
				z-index: 2;
				color: $white;
				text-align: center;
			}
		}
		.banner-title {
			font-size: 2.2rem;
		}
		.banner-subtitle {
			font-size: 1.5rem;
		}
		.banner-desc {
			font-size: 0.87rem;
			line-height: 1.5;
		}
	}
	.banner-search .form-group {
		margin-block-end: 10px !important;
	}
	.wideget-user-tab.wideget-user-tab3 .tabs-menu1 ul li a {
		margin: 0 0 4px 0 !important;
		border-radius: 3px !important;
		border-block-end: 1px solid #dbddf1 !important;
	}
}

@media (max-width: 1367px) and (min-width: 768px) {
	.bannner-owl-carousel1 {
		.banner-text {
			top: 250px;
			position: relative;
			z-index: 2;
			color: $white;
			text-align: center;
			&.banner-search {
				top: 210px;
				position: relative;
				z-index: 2;
				color: $white;
				text-align: center;
			}
		}
		.item {
			height: 500px;
		}
		.banner-title {
			font-size: 2.2rem;
		}
		.banner-subtitle {
			font-size: 1.8rem;
		}
		.banner-desc {
			font-size: 1rem;
			line-height: 1;
		}
	}
	.banner-search .form-group {
		margin-block-end: 10px !important;
	}
}

@media (min-width: 1367px) {
	.slider.slider-header img {
		height: 550px;
		width: 100%;
	}
}

@media (max-width: 1366px) and (min-width: 1024px) {
	.slider.slider-header img {
		height: 560px;
		width: 100%;
	}
}

.about-widget h2 {
	font-size: 40px;
}

.section-bg {
	background: url('../images/png/bg-1.png');
	background-size: cover;
}

.search-form {
	position: relative;
	.btn {
		position: absolute;
		top: 1px;
		 inset-inline-end: 5px;
		background-color: transparent;
		height: 40px;
		color: #636d8c;
		transition: none;
		font-size: 18px;
		padding-inline-end: 13px;
		z-index: 99;
	}
}

.product-imgs img {
	width: 150px;
	height: 150px;
}

.section-bg-background-color {
	position: relative;
	&:before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		inset-inline-start: 0;
		 inset-inline-end: 0;
		display: block;
		z-index: 0;
		top: 0;
		background: url('../images/png/banner/b2.png');
		background-size: cover;
		opacity: 0.6;
	}
}

.section-bg-background-color1:before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	inset-inline-start: 0;
	 inset-inline-end: 0;
	display: block;
	z-index: 0;
	top: 0;
	background: url('../images/png/banner/b6.png');
	background-size: cover;
	opacity: 0.6;
}

.newsimg {
	width: 60px;
	height: 60px;
	margin-block-end: 1.5rem;
}

.categories {
	margin-block-start: -35px;
}

.temp-categories {
	text-align: center;
	margin-block-end: 8px;
	li {
		a {
			background: rgba(0, 123, 255, 0.1) none repeat scroll 0 0;
			border-radius: 4px;
			color: $white;
			font-size: 14px;
			padding: 5px 12px;
			text-align: center;
			align-items: center;
			justify-content: center;
			border: 1px solid $black-05;
			background: $black-3;
			margin: 3px;
		}
		display: inline-block;
	}
}

.box-light-shadow {
	box-shadow: 0 5px 10px rgba(239, 239, 251, 0.3);
}

.box-dark-shadow {
	box-shadow: 0 5px 10px rgba(20, 18, 53, 0.3);
}

.box-danger-shadow {
	box-shadow: 0 5px 10px rgba(248, 66, 66, 0.3);
}

.box-success-shadow {
	box-shadow: 0 5px 10px rgba(1, 210, 119, 0.3);
}

.box-warning-shadow {
	box-shadow: 0 5px 10px rgba(255, 162, 43, 0.3);
}

.box-info-shadow {
	box-shadow: 0 5px 10px rgba(11, 148, 247, 0.3);
}

.box-purple-shadow,
.box-pink-shadow {
	box-shadow: 0 5px 10px rgba(245, 18, 123, 0.3);
}

.box-orange-shadow {
	box-shadow: 0 5px 10px rgba(230, 118, 5, 0.3);
}

.illustrate-img {
	padding: 7px;
	background: $white;
	border-radius: 12px;
	border: 7px solid #eaebf7;
	&.owl-carousel {
		overflow: hidden;
		margin-block-start: 1.8rem;
	}
	.owl-dots {
		position: relative;
		top: 2px;
	}
	&.owl-carousel button.owl-dot {
		margin: 10px 0px 0 10px !important;
	}
}

@media (min-width: 1366px) {
	.illustrate-img.owl-carousel {
		transform: translateX(55px);
	}
}


/*Software Category*/

.software-cat {
	border: 1px solid $border;
	padding: 12px 3px;
	border-radius: 3px;
	display: block;
	margin: 2px;
	background: $background;
	img {
		padding: 10px;
		display: block;
		margin: 0 auto;
		width: 50px;
	}
}

.psd-bg {
	background: #263364;
}

.htms-bg {
	background: #e34c26;
}

.ang-bg {
	background: #d92d30;
}

.wd-bg {
	background: #006fa5;
}

.ph-bg {
	background: #454886;
}

.re-bg {
	background: #5ed5f4;
}

.boots-bg {
	background: #543b79;
}

.vue-bg {
	background: #41b883;
}

.lar-bg {
	background: #ee5045;
}

.node-bg {
	background: #80bd01;
}

.web-bg {
	background: #3978bb;
}

.gu-bg {
	background: #eb4a4b;
}

.temp-cat {
	overflow: hidden;
	justify-content:center;
}

.temp-thumb {
	text-align: center;
	margin-block-end: 6px;
	float: $float-right;
}

.img-view img {
	border: 1px solid #dbddf1;
	box-shadow: 0 5px 10px rgba(21, 17, 148, 0.06) !important;
	border-radius: 4px;
	background: $white;
}

@media (max-width: 867px) {
	.temp-cat {
		overflow-x: auto;
	}
}

.licence-content {
	display: none;
	&.active {
		display: block;
	}
	.widget-spec li {
		color: #636d8c;
	}
}

.licence-price {
	position: absolute;
	top: 15px;
	 inset-inline-end: 18px;
	font-weight: 500;
	font-size: 35px;
}
.reviewselect .select2-container,
#commentForm #second .select2-container {
	width: 100% !important;
}

.author-user img {
	width: 30px;
	height: 30px;
	margin: 7px 3px 0px 0;
}

.previous.list-inline-item.disabled {
	opacity: 0.3;
}

.manged-ad .text-over {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.ripple {
	position: relative;
	overflow: hidden;
	transform: translate3d(0, 0, 0);
	cursor: pointer;
	&:after {
		content: "";
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		inset-inline-start: 0;
		pointer-events: none;
		background-image: radial-gradient(circle, $black 10%, transparent 10.01%);
		background-repeat: no-repeat;
		background-position: 50%;
		transform: scale(10, 10);
		opacity: 0;
		transition: transform 0.5s, opacity 1s;
	}
	&:active:after {
		transform: scale(0, 0);
		opacity: .2;
		transition: 0s;
	}
}

@keyframes fadein {
	from {
		opacity: 0;
		transform: translate3d(0, 20px, 0);
	}
	to {
		opacity: 1;
		transform: none;
		transform: translate3d(0, 0, 0);
	}
}

.pulse {
	display: block;
	position: absolute;
	top: 4px;
	 inset-inline-end: 8px;
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background: $success;
	cursor: pointer;
	box-shadow: 0 0 0 rgba(1, 210, 119, 0.9);
	animation: pulse 2s infinite;
	&:hover {
		animation: none;
	}
}

@-webkit-keyframes pulse {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(1, 210, 119, 0.9);
	}
	70% {
		-webkit-box-shadow: 0 0 0 10px rgba(1, 210, 119, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(1, 210, 119, 0);
	}
}

@keyframes pulse {
	0% {
		-moz-box-shadow: 0 0 0 0 rgba(1, 210, 119, 0.9);
		box-shadow: 0 0 0 0 rgba(1, 210, 119, 0.7);
	}
	70% {
		-moz-box-shadow: 0 0 0 10px rgba(1, 210, 119, 0);
		box-shadow: 0 0 0 10px rgba(1, 210, 119, 0);
	}
	100% {
		-moz-box-shadow: 0 0 0 0 rgba(1, 210, 119, 0);
		box-shadow: 0 0 0 0 rgba(1, 210, 119, 0);
	}
}

.conatct-list {
	.media-icon {
		width: 40px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		line-height: 0;
		color: $white;
		font-size: 18px;
		position: relative;
		top: 2px;
		border-radius: 100%;
		background-color: $black-1;
	}
	.media-body {
		margin-inline-start: 20px;
	}
}

.row.row-sm div {
	padding: 0 5px;
}

.temp-cat.row li {
	padding: 0 3px;
}

.keywords-input {
	font-size: 14px !important;
}

input {
	&[type="search"] {
		&::-webkit-search-decoration,
		&::-webkit-search-cancel-button,
		&::-webkit-search-results-button,
		&::-webkit-search-results-decoration {
			-webkit-appearance: none;
		}
	}
	&[type=search] {
		&::-ms-clear,
		&::-ms-reveal {
			display: none;
			width: 0;
			height: 0;
		}
	}
	&[type=text]::-ms-clear {
		display: none;
	}
}

.keywords-input:placeholder {
	font-size: 14px !important;
}

.z-documentation-10 {
	position: relative;
	z-index: 10;
}


/** Horizontal-dashboard menu */

.hor-menu-nav {
	background: $white;
	margin-block-end: 1.5rem;
	border: 1px solid $border;
	border-radius: 4px;
	li {
		a {
			padding: 15px 14px !important;
			margin-inline-end: 1px;
			font-weight: 500;
			color: #2e384d !important;
			border-inline-start: 1px solid $border;
			font-size: 15px;
		}
		.nav-icon {
			margin-inline-end: 6px;
		}
	}
}

.header-text .form-control,
.header-text1 .form-control {
	border: 0 !important;
}

@media (min-width: 992px) {
	.sticky-wrapper.is-sticky {
		.horizontal-main {
			padding: 0.35rem;
			box-shadow: 2px 3px 4.7px 0.3px rgba(0, 0, 0, 0.24);
			.ad-post {
				margin-block-start: 0.63rem !important;
			}
		}
		.horizontalMenu>.horizontalMenu-list>li>a {
			>.fa {
				color: #2e384d;
			}
			&.active .fa {
				color: $white !important;
			}
		}
		.desktoplogo-1 {
			display: block !important;
			margin: 0;
			float: $float-left;
		}
		.header-style1 .top-postbtn {
			margin-block-start: 0 !important;
		}
		.horizontalMenu>.horizontalMenu-list>li {
			padding: 0.70rem 0;
		}
	}
	.desktoplogo-1,
	.sticky-wrapper.is-sticky .desktoplogo {
		display: none;
	}
}
/*-- Dashboard--*/
.my-dash .side-menu  .slide li a{
	position:relative;
}
.my-dash .slide{
	list-style-type: none;
}
.my-dash li{
	list-style-type: none;
}
.my-dash .side-menu li ul li a.active:before{
	display:none;
}
.my-dash .side-menu__item {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 0.75rem 1.25rem;
	font-size: 14px;
	-webkit-transition: border-inline-start-color 0.3s ease,
	background-color 0.3s ease;
	-o-transition: border-inline-start-color 0.3s ease,
	background-color 0.3s ease;
	transition: border-inline-start-color 0.3s ease,
	background-color 0.3s ease;
	border-block-start: 1px solid #efecfb;
}
.my-dash.app-sidebar .mCSB_draggerContainer {
     inset-inline-end: -11px;
}

.my-dash .side-menu__icon {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 25px;
}
.my-dash .side-menu__label {
  white-space: nowrap;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}
.my-dash .slide.is-expanded [data-bs-toggle='slide']{
	border-block-end: 0px solid  #efecfb;
}
.my-dash .side-menu  li a{
	border-block-start: 1px solid  #efecfb;
}
.my-dash .side-menu  li ul li a{
	border-block-start:0;
	border-block-end:0;
}
.my-dash.app-sidebar .side-menu li a.side-menu__item.active{
    background: transparent;
    color: $primary1;
    border-inline-end: 2px solid $primary1;
}
.my-dash.app-sidebar .slide-menu li.active>.a {
	font-weight: 500;
}
.my-dash .slide-menu li ul li {
	font-size:12px !important;
	font-weight:300;
	border-block-end: 0px solid #efecfb;
}
.my-dash .slide-menu li ul a{
	padding: 10px 10px 10px 65px;
}
.my-dash .side-menu .slide-menu li a{
	font-size:13px;
}
.my-dash.slide ul li a {
    color: #605e7e;
    font-weight: 400;
}
.my-dash.app-sidebar-footer {
    clear: both;
    display: block;
    color: #fff;
    position: fixed;
    bottom: 0px;
    background: #fff;
    width: 228px;
    z-index: 999;
    border-block-start: 1px solid #efecfb;
}
.my-dash .app-sidebar-footer a {
    padding: 10px 0;
    text-align: center;
    width: 20%;
	 color: #fff;
    font-size: 15px;
    display: block;
    float: $float-right;
    cursor: pointer;
	border-inline-end:1px solid #efecfb;
	border-block-end:1px solid #efecfb;
}
.my-dash .app-sidebar-footer a:hover{
	background: rgba(255,255,255,0.02);
}
.my-dash .slide ul ul a {
    font-size: 13px !important;
    padding-block-start: 0px;
    padding-inline-end: 0px;
    padding-block-end: 0px;
    padding-inline-start: 64px;
}
.my-dash.app-sidebar ul li a {
    color: #2e384d;
    font-weight: 400;
}
.my-dash .slide.is-expanded .slide-menu {
	max-height: 150vh;
	background: #fff;
}

.my-dash .slide.is-expanded .angle {
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
}
.my-dash .slide-menu {
    max-height: 0;
    overflow: hidden;
    -webkit-transition: max-height 0.3s ease;
    -o-transition: max-height 0.3s ease;
    transition: max-height 0.3s ease;
}
.my-dash .child-sub-menu  {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.3s ease;
  -o-transition: max-height 0.3s ease;
  transition: max-height 0.3s ease;
}
.my-dash .sub-slide.is-expanded .child-sub-menu{
    max-height: 150vh;
    background: #fff;
}
.my-dash .sub-slide.is-expanded .sub-angle {
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
}
.my-dash .sub-slide .sub-angle {
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    -o-transition: transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
	margin-inline-end: 20px;
}
.my-dash .slide-item {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-block-start: 0px;
    padding-inline-end: 0px;
    padding-block-end: 0px;
    padding-inline-start: 45px;
    font-size: 13px;
    color: #3e4b5b;
    height: 30px;
    line-height: 30px;
}
.my-dash.app-sidebar .side-menu .slide-menu li:last-child{
	padding-block-end:10px;
}
.my-dash.app-sidebar .side-menu .slide-menu li:last-child{
	border-block-end:0;
}
.my-dash .slide-item .icon {
  margin-inline-end: 5px;
}

.my-dash .angle {
  -webkit-transform-origin: center;
      -ms-transform-origin: center;
          transform-origin: center;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}
.my-dash .app-sidebar ul li a {
    color: #605e7e;
    font-weight: 400;
}
.my-dash .slide.is-expanded .slide-menu {
  max-height: 150vh;
}

.my-dash .slide.is-expanded .angle {
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
}
.my-dash .slide-menu {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.3s ease;
  -o-transition: max-height 0.3s ease;
  transition: max-height 0.3s ease;
}
.my-dash .child-sub-menu li a {
    border-block-start: 0px solid rgba(0,0,0,0.06) !important;
}
@media screen and (max-device-width: 992px) and (orientation: portrait) {
  .headerstyle1.active .sticky-wrapper.is-sticky {
	.horizontal-header {
	  position: -webkit-sticky !important;
	  position: sticky;
	  margin-inline-start: 240px;
	}

	position: fixed;
	top: 0;
	z-index: 9999999;
  }
}

.sticky-wrapper {
	z-index: 97;
	position: absolute;
	inset-inline-start: 0;
	 inset-inline-end: 0;
}

@media screen and (max-device-width: 992px) and (orientation: landscape) {
  .headerstyle1.active .sticky-wrapper.is-sticky {
	.horizontal-header {
	  position: -webkit-sticky !important;
	  position: sticky;
	  margin-inline-start: 240px;
	}

	position: fixed;
	top: 0;
	z-index: 9999999;
  }
}
.header-icons .notify-icon{
	height: 40px;
    line-height: 40px;
    text-align: center;
    vertical-align: middle;
    width: 40px;
    font-size: 21px;
    border-radius: 30px;
}
#gdpr-cookie-message {
	position: fixed;
	z-index: 1003;
	inset-inline-start: 0;
	bottom: 30px;
	max-width: 375px;
	background-color: rgb(255, 255, 255);
	padding: 20px;
	border-radius: 4px;
	border: 1px solid $border;
	box-shadow: 0 8px 20px 0 rgba(21, 17, 148, 0.2);
	margin-inset-inline-start: 30px;
	text-align: start;
	display: block !important;
	h4 {
		font-size: 18px;
		font-weight: 500;
		margin-bottom: 10px;
	}
	h5 {
		font-size: 15px;
		font-weight: 500;
		margin-bottom: 10px;
	}
	p,
	ul {
		color: #2e384d;
		font-size: 15px;
		line-height: 1.5em;
	}
	p:last-child {
		margin-bottom: 0;
		text-align: start;
	}
	li {
		width: 49%;
		display: -webkit-inline-box;
	}
	a {
		text-decoration: none;
		font-size: 15px;
		padding-bottom: 2px;
		transition: all .3s ease-in;
	}
	button {
		border: none;
		color: $white;
		font-size: 15px;
		padding: 7px 18px;
		border-radius: 4px;
		margin-top: 4px;
		cursor: pointer;
		transition: all .3s ease-in;
		margin:3px
	}
}

button {
	&#ihavecookiesBtn {
		border: none;
		color: $white;
		font-size: 15px;
		padding: 7px 18px;
		border-radius: 4px;
		margin-top: 4px;
		cursor: pointer;
		transition: all .3s ease-in;
	}
	&#gdpr-cookie-advanced {
		margin-inset-inline-start: 4px;
	}
}

@media (max-width: 390px) {
	button#gdpr-cookie-advanced {
		margin-inset-inline-start: 0 !important;
	}
	#gdpr-cookie-message p button {
		width: 100%;
	}
	.page-link{
		padding: 0.4rem 0.65rem !important;
	}
}

@media (max-width: 480px) {
	#gdpr-cookie-message {
		margin: 0 15px;
		display: block;
		 inset-inline-end: 0;
		box-shadow: 0 0 20px 0 rgba(40, 37, 89, 0.9);
		display: none !important;
	}
}

#gdpr-cookie-message {
	button:disabled {
		opacity: .3;
	}
	input[type=checkbox] {
		float: none;
		margin-top: 0;
		margin-inline-end: 5px;
	}
}

#gdpr-cookie-message button{
	background: $secondary;
}

#gdpr-cookie-message {
	h4,
	h5 {
		color: $primary1;
	}
	a {
		color: $primary1;
		border-bottom: 1px solid $primary1;
		&:hover {
			border-bottom: 1px solid $primary1;
			transition: all 0.3s ease-in;
			color: $primary1;
		}
	}
}
button#gdpr-cookie-advanced {
	color: $white;
	background-color: $primary1 !important;
}
.desktoplogo-1 {
	margin: 0;
	float: $float-left;
}
.licence-niceselect .nice-select{
	width: 60%;
	border-radius: 5px !important;

}
.sales-icon{
	font-size: 25px;
    padding: 2px 10px;
    background: $primary1;
    color: $white !important;
    border-radius: 5px;
}
.dropdown-footer{
	border-block-start-color: $white-2;
}
@media only screen and (max-width: 991px){
	.active .horizontalMenu .horizontal-overlapbg {
		opacity: 1;
		visibility: visible;
	}
	.horizontal-overlapbg {
		inset-inline-end: 0;
		width: calc(100% - 240px);
		height: 100vh;
		min-height: 100%;
		position: fixed;
		top: 0;
		opacity: 0;
		visibility: hidden;
		background-color: rgba(0, 0, 0, 0.45);
		cursor: pointer;
	}
}
.register-right .nav-tabs .nav-link {
    padding: 12px 25px;
    margin: 1px;
    text-align: center;
    display: block;
    border-radius: 4px;
}
.contact-bg:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    inset-inline-start: 0;
    inset-inline-end: 0;
    display: block;
    z-index: 0;
    top: 0;
	background-color: $primary6;
	border-radius: 10px;
	background-image: url(../images/png/banner/b2.png);
    transform: scaleX(-1);
}
.contact-bg{
	.card-header .card-title, span{
		color: $white;
		z-index: 9;
	}
	.media-body{
   		color: $white-8;
	}
	.border-top{
		border-block-start: 1px solid $white-1 !important;
	}
	// .conatct-list{
	// 	background: $black-1;
	// 	padding: 25px 15px;
	// 	border-radius: 10px;
	// }
}
.conatct-list-icon{
   padding: 7px 10px;
   border: 1px solid $white-2;
   border-radius: 5px;
   &:hover{
	background-color: $black-1;
	border-radius: 5px;
   }

}
#rootwizard .nav-tabs .nav-link{
  border-block-end: 1px solid $border;
  border-inline-end: 1px solid $border;
}
.modal-header .close.btn {
    z-index: 1;
}

/*--Styles--*/