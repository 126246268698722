/*-- Media --*/

.breadcrumb {
	display: flex;
	flex-wrap: wrap;
	margin-block-end: 0 !important;
	list-style: none;
	border-radius: 4px;
	inset-inline-start: 0;
	bottom: 0;
	padding: 0 !important;
	background-color: transparent !important;
}

.breadcrumb-item a {
	color: $white-5;
}

.header-text .breadcrumb-item a:hover {
	color: $white-7;
}

.page-header .breadcrumb-item {
	+.breadcrumb-item::before {
		color: #dce3ef;
	}
	a {
		color: #636d8c;
	}
}

.breadcrumb-item+.breadcrumb-item {
	&::before {
		display: inline-block;
		padding-inline-end: .5rem;
		padding-inline-start: .5rem;
		color: $white-3;
		content: "/";
	}
	&:hover::before {
		text-decoration: none;
	}
}

@media (max-width: 480px) {
	.breadcrumb-item+.breadcrumb-item {
		&::before {
			display: inline-block;
			padding-inline-end: .2rem;
			padding-inline-start: .2rem;
			//color: #2e384d;
			content: "/";
		}
		padding-inline-start: 0;
	}
	.page-header {
		display: block !important;
		padding: 10px 0 !important;
		.form-control.page-select {
			width: 100% !important;
			margin-block-end: 1rem;
		}
	}
}

.breadcrumb1 {
	display: flex;
	flex-wrap: wrap;
	padding: .75rem 1rem;
	margin-block-end: 1rem;
	list-style: none;
	background-color: #f3f3fd;
	border-radius: .25rem;
}

.breadcrumb-item1 {
	a {
		color: #7b838f;
		&:hover {
			text-decoration: underline;
		}
	}
	+.breadcrumb-item1 {
		&::before {
			display: inline-block;
			padding-inline-end: .5rem;
			padding-inline-start: .5rem;
			content: "/";
		}
		&:hover::before {
			text-decoration: none;
		}
	}
	&.active {
		color: $primary1;
	}
}

.breadcrumb-1,
.breadcrumb-2 {
	ol {
		position: relative;
		display: block;
		width: 100%;
		text-align: end;
	}
	li {
		text-align: end;
		display: contents;
	}
}

.breadcrumb-3,
.breadcrumb-4 {
	ol {
		position: relative;
		display: block;
		width: 100%;
		text-align: center;
	}
	li {
		display: contents;
		text-align: center;
	}
}


/*-- Media --*/