.selectgroup {
	display: -ms-inline-flexbox;
	display: inline-flex;
}

.selectgroup-item {
	-ms-flex-positive: 1;
	flex-grow: 1;
	position: relative;
	+.selectgroup-item {
		margin-inline-start: -1px;
	}
	&:not(:first-child) .selectgroup-button {
		border-start-start-radius: 0;
		border-end-start-radius: 0;
	}
	&:not(:last-child) .selectgroup-button {
		border-start-end-radius: 0;
		border-end-end-radius: 0;
	}
}

.selectgroup-input {
	opacity: 0;
	position: absolute;
	z-index: -1;
	top: 0;
	inset-inline-start: 0;
}

.selectgroup-button {
	display: block;
	border: 1px solid $border;
	text-align: center;
	padding: .375rem 1rem;
	position: relative;
	cursor: pointer;
	border-radius: 4px;
	color: #636d8c;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	font-size: .9375rem;
	line-height: 1.5rem;
	min-width: 2.375rem;
}

.selectgroup-button-icon {
	padding-inline-start: .5rem;
	padding-inline-end: .5rem;
	font-size: 1rem;
}

.selectgroup-pills {
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-align: start;
	align-items: flex-start;
	.selectgroup-item {
		margin-inline-end: .5rem;
		-ms-flex-positive: 0;
		flex-grow: 0;
	}
	.selectgroup-button {
		border-radius: 50px !important;
	}
}