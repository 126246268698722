/*---Custom Control---*/

.custom-control {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.custom-controls-stacked .custom-control {
	margin-block-end: .25rem;
}

.custom-control-label {
	vertical-align: middle;
	&:before {
		border: 1px solid rgba(167, 180, 201, 0.5);
		background-color: $white;
		background-size: .5rem;
	}
}

.custom-control-description {
	line-height: 1.5rem;
}

.custom-control {
	position: relative;
	display: block;
	min-height: 1.5rem;
	padding-inline-start: 1.5rem;
}

.custom-control-inline {
	display: -ms-inline-flexbox;
	display: inline-flex;
	margin-inline-end: 1rem;
}

.custom-control-input {
	position: absolute;
	z-index: -1;
	opacity: 0;
	&:checked~.custom-control-label::before {
		color: $white;
	}
	&:active~.custom-control-label::before {
		color: $white;
		background-color: #d4e1f4;
	}
	&:disabled~.custom-control-label {
		color: #868e96;
		&::before {
			background-color: #f3f3fd;
		}
	}
}

.custom-control-label {
	margin-block-end: 0;
	position: static !important;
	&::before {
		position: absolute;
		top: .25rem;
		inset-inline-start: 0;
		display: block;
		width: 1rem;
		height: 1rem;
		pointer-events: none;
		content: "";
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		background-color: #f3f3fd;
	}
	&::after {
		position: absolute;
		top: .25rem;
		inset-inline-start: 0;
		display: block;
		width: 1rem;
		height: 1rem;
		content: "";
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 50% 50%;
	}
}

.form-checkbox .custom-control-label::before {
	border-radius: 4px;
}

.br.form-checkbox .custom-control-label::before {
	border-radius: 50px;
}

.form-checkbox .custom-control-input {
	&:checked~.custom-control-label::after {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
	}
	&:indeterminate~.custom-control-label::after {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
	}
	&:disabled {
		&:checked~.custom-control-label::before,
		&:indeterminate~.custom-control-label::before {
			background-color: rgba(5, 117, 230, 0.5);
		}
	}
}

.custom-radio {
	.custom-control-label::before {
		border-radius: 50%;
	}
	.custom-control-input {
		&:checked~.custom-control-label::after {
			background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
		}
		&:disabled:checked~.custom-control-label::before {
			background-color: rgba(5, 117, 230, 0.5);
		}
	}
}

.custom-control-input.is-valid~.custom-control-label,
.was-validated .custom-control-input:valid~.custom-control-label {
	color: $success;
}

.custom-control-input.is-valid~.custom-control-label::before,
.was-validated .custom-control-input:valid~.custom-control-label::before {
	background-color: #9eff3b;
}

.custom-control-input.is-valid~ {
	.valid-feedback,
	.valid-tooltip {
		display: block;
	}
}

.was-validated .custom-control-input:valid~ {
	.valid-feedback,
	.valid-tooltip {
		display: block;
	}
}

.custom-control-input.is-valid:checked~.custom-control-label::before,
.was-validated .custom-control-input:valid:checked~.custom-control-label::before {
	background-color: #78ed00;
}

.custom-control-input.is-valid:focus~.custom-control-label::before,
.was-validated .custom-control-input:valid:focus~.custom-control-label::before {
	box-shadow: 0 0 0 1px #eaebf7, 0 0 0 2px rgba(94, 186, 0, 0.25);
}

.custom-control-input.is-invalid~.custom-control-label,
.was-validated .custom-control-input:invalid~.custom-control-label {
	color: $danger;
}

.custom-control-input.is-invalid~.custom-control-label::before,
.was-validated .custom-control-input:invalid~.custom-control-label::before {
	background-color: #ec8080;
}

.custom-control-input.is-invalid~ {
	.invalid-feedback,
	.invalid-tooltip {
		display: block;
	}
}

.was-validated .custom-control-input:invalid~ {
	.invalid-feedback,
	.invalid-tooltip {
		display: block;
	}
}

.custom-control-input.is-invalid:checked~.custom-control-label::before,
.was-validated .custom-control-input:invalid:checked~.custom-control-label::before {
	background-color: #e23e3d;
}

.custom-control-input.is-invalid:focus~.custom-control-label::before,
.was-validated .custom-control-input:invalid:focus~.custom-control-label::before {
	box-shadow: 0 0 0 1px #eaebf7, 0 0 0 2px rgba(205, 32, 31, 0.25);
}


/*---Custom Control---*/