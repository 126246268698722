ul.resp-tabs-list {
  margin: 0px;
  padding: 0px;
}

.resp-tabs-list li {
  font-weight: 600;
  font-size: 13px;
  display: inline-block;
  padding: 13px 15px;
  margin: 0 4px 0 0;
  list-style: none;
  cursor: pointer;
  float: $float-right;
}

.resp-tabs-container {
  padding: 0px;
  background-color: transparent;
  clear: left;
}

h2.resp-accordion {
  cursor: pointer;
  padding: 5px;
  display: none;
}

.resp-tab-content {
  display: none;
  padding-block-end: 0;
}

.resp-tab-active {
  border-block-end: none;
  margin-block-end: 0px !important;
  padding: 12px 14px 14px 14px !important;
  border-block-end: 0 !important;
  border-block-end: none;
}

.resp-content-active, .resp-accordion-active {
  display: block;
}

.resp-tab-content {}

h2 {
  &.resp-accordion {
    font-size: 13px;
    border: 1px solid #c1c1c1;
    border-block-start: 0px solid #c1c1c1;
    margin: 0px;
    padding: 10px 15px;
  }

  &.resp-tab-active {
    border-block-end: 0px solid #c1c1c1 !important;
    margin-block-end: 0px !important;
    padding: 10px 15px !important;
  }

  &.resp-tab-title:last-child {
    border-block-end: 12px solid #c1c1c1 !important;
    background: blue;
  }
}

/*-----------Vertical tabs-----------*/

.resp-vtabs {
  ul.resp-tabs-list {
    float: none;
    margin-block-start: 0 !important;
    color: #fff;
  }

  .resp-tabs-list li {
    display: block;
    padding: 25px 15px !important;
    margin: 0 0 4px;
    cursor: pointer;
    float: none;
    margin: 0 auto;
    text-align: center;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
    border-inline-start: 0 !important;
    border-block-start: 0 !important;
    border-block-end: 0;
  }

  .resp-tabs-container {
    padding: 0px;
    background-color: transparent;
    clear: none;
    border-block-end: 0 !important;
  }

  .resp-tab-content {
    border: none;
    word-wrap: break-word;
    height: 100%;
  }

  li {
    &.resp-tab-active {
      position: relative;
      z-index: 1;
      margin: 0 auto;
      text-align: center;
      border-block-start: 1px solid;
      border-inline-end: 0 !important;
    }

    &.active {
      border-inline-end: 0 !important;
      color: #fff;
    }
  }

  .resp-tab-active {
    &.active:before {
      content: '';
      position: absolute;
      width: 10px;
      height: 15px;
      top: 40px;
       inset-inline-end: 0px;
      bottom: 0;
      z-index: 1;
      display: block;
      border-radius: 50px 0px 0px 50px;
      background: #fff;
    }

    &:hover {
      border-inline-end: 0 !important;
      color: #fff;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        height: 15px;
        top: 40px;
         inset-inline-end: 0px;
        bottom: 0;
        z-index: 1;
        display: block;
        border-radius: 50px 0px 0px 50px;
        background: #fff;
      }
    }
  }
}

.resp-arrow {
  width: 0;
  height: 0;
  float: $float-right;
  margin-block-start: 3px;
  border-inline-start: 6px solid transparent;
  border-inline-end: 6px solid transparent;
  border-block-start: 12px solid #c1c1c1;
}

h2.resp-tab-active {
  span.resp-arrow {
    border: none;
    border-inline-start: 6px solid transparent;
    border-inline-end: 6px solid transparent;
    border-block-end: 12px solid #9B9797;
  }

  background: #DBDBDB;

  /* !important;*/
}

/*-----------Accordion styles-----------*/

.resp-easy-accordion {
  h2.resp-accordion {
    display: block;
  }

  .resp-tab-content {
    border: 1px solid #c1c1c1;

    &:last-child {
      border-block-end: 1px solid #c1c1c1;

      /* !important;*/
    }
  }
}

.resp-jfit {
  width: 100%;
  margin: 0px;
}

.resp-tab-content-active {
  display: block;
}

h2.resp-accordion:first-child {
  border-block-start: 1px solid #c1c1c1;

  /* !important;*/
}