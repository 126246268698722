@charset "UTF-8";

/*------ Buttons -------*/

.btn {
	cursor: pointer;
	display: inline-block;
	font-weight: 400;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	border: 1px solid transparent;
	padding: 9px 18px;
	line-height: 1.538;
	font-size: 0.875rem;
	font-weight: 500;
	border-radius: 3px;
	min-height: 38px;
	font-weight: 400;
	letter-spacing: .03em;
	font-size: .8125rem;
	min-width: 2.7rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	&:focus,
	&:hover {
		text-decoration: none;
	}
	&.focus,
	&:focus {
		outline: 0;
		box-shadow: 0 0 0 2px rgba(194, 197, 199, 0);
	}
	&.disabled,
	&:disabled {
		opacity: 0.65;
	}
	&:not(:disabled):not(.disabled) {
		cursor: pointer;
		&.active,
		&:active {
			background-image: none
		}
	}
}

@media screen and (prefers-reduced-motion: reduce) {
	.btn {
		transition: none;
	}
}

a.btn.disabled,
fieldset:disabled a.btn {
	pointer-events: none;
}

.btn-default {
	color: #212529;
	background: $background;
	box-shadow: 0 5px 10px rgba(168, 167, 197, 0.3);
	&:hover {
		color: #212529;
		background-color: #edeef8;
		border-color: #edeef8;
	}
}

.btn-success {
	color: $white;
	background-color: $success;
	border-color: $success;
	box-shadow: 0 5px 10px rgba(1, 210, 119, 0.3);
	&:hover {
		color: $white;
		background-color: #03bf6d;
		border-color: #03bf6d;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.1);
	}
	&.disabled,
	&:disabled {
		color: $white;
		background-color: $success;
		border-color: $success;
	}
	&:not(:disabled):not(.disabled) {
		&.active,
		&:active {
			color: $white;
			background-color: $success;
			border-color: $success;
		}
	}
}

.show>.btn-success.dropdown-toggle {
	color: $white;
	background-color: $success;
	border-color: $success;
}

.btn-success:not(:disabled):not(.disabled) {
	&.active:focus,
	&:active:focus {
		box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.1);
	}
}

.show>.btn-success.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.1);
}

.btn-info {
	color: $white;
	background-color: $info;
	border-color: $info;
	box-shadow: 0 5px 10px rgba(11, 148, 247, 0.3);
	&:hover {
		color: $white;
		background-color: #0a8eec;
		border-color: #0a8eec;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 2px rgba(11, 148, 247, 0.1);
	}
	&.disabled,
	&:disabled {
		color: $white;
		background-color: #0a8eec;
		border-color: #0a8eec;
	}
	&:not(:disabled):not(.disabled) {
		&.active,
		&:active {
			color: $white;
			background-color: #0a8eec;
			border-color: #0a8eec;
		}
	}
}

.show>.btn-info.dropdown-toggle {
	color: $white;
	background-color: #0a8eec;
	border-color: #0a8eec;
}

.btn-info:not(:disabled):not(.disabled) {
	&.active:focus,
	&:active:focus {
		box-shadow: 0 0 0 2px rgba(11, 148, 247, 0.1);
	}
}

.show>.btn-info.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(11, 148, 247, 0.1);
}

.btn-warning {
	color: $white;
	background-color: $warning;
	border-color: $warning;
	box-shadow: 0 5px 10px rgba(251, 149, 5, 0.3);
	&:hover {
		color: $white;
		background-color: #f59713;
		border-color: #f59713;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.1);
	}
	&.disabled,
	&:disabled {
		color: $white;
		background-color: $yellow;
		border-color: $yellow;
	}
	&:not(:disabled):not(.disabled) {
		&.active,
		&:active {
			color: $white;
			background-color: #f59713;
			border-color: #f59713;
		}
	}
}

.show>.btn-warning.dropdown-toggle {
	color: $white;
	background-color: #f59713;
	border-color: #f59713;
}

.btn-warning:not(:disabled):not(.disabled) {
	&.active:focus,
	&:active:focus {
		box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.1);
	}
}

.show>.btn-warning.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.1);
}

.btn-danger {
	color: $white !important;
	background-color: $danger;
	border-color: $danger;
	box-shadow: 0 5px 10px rgba(248, 66, 66, 0.3);
	&:hover {
		color: $white;
		background-color: #f34a4a;
		border-color: #f34a4a;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.1);
	}
	&.disabled,
	&:disabled {
		color: $white;
		background-color: $danger;
		border-color: $danger;
	}
	&:not(:disabled):not(.disabled) {
		&.active,
		&:active {
			color: $white;
			background-color: #f34a4a;
			border-color: #f34a4a;
		}
	}
}

.show>.btn-danger.dropdown-toggle {
	color: $white;
	background-color: #f34a4a;
	border-color: #f34a4a;
}

.btn-danger:not(:disabled):not(.disabled) {
	&.active:focus,
	&:active:focus {
		box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.1);
	}
}

.show>.btn-danger.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.1);
}

.btn-light {
	color: #3d4e67;
	background-color: #eeeff9;
	border-color: #e8ebf3;
	box-shadow: 0 5px 10px rgba(238, 239, 249, 0.3);
	&:hover {
		color: #3d4e67;
		background-color: #eeeff9;
		border-color: #eeeff9;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
	}
	&.disabled,
	&:disabled {
		color: #3d4e67;
		background-color: #eeeff9;
		border-color: #e8ebf3;
	}
	&:not(:disabled):not(.disabled) {
		&.active,
		&:active {
			color: #3d4e67;
			background-color: #e8e9f5;
			border-color: #e8e9f5;
		}
	}
}

.show>.btn-light.dropdown-toggle {
	color: #3d4e67;
	background-color: #e8e9f5;
	border-color: #e8e9f5;
}

.btn-light:not(:disabled):not(.disabled) {
	&.active:focus,
	&:active:focus {
		box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
	}
}

.show>.btn-light.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
}

.btn-dark {
	color: $white;
	background-color: $dark;
	border-color: $dark;
	box-shadow: 0 5px 10px rgba(52, 58, 64, 0.3);
	&:hover {
		color: $white;
		background-color: #23272b;
		border-color: #1d2124;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.1);
	}
	&.disabled,
	&:disabled {
		color: $white;
		background-color: $dark;
		border-color: $dark;
	}
	&:not(:disabled):not(.disabled) {
		&.active,
		&:active {
			color: $white;
			background-color: #1d2124;
			border-color: #171a1d;
		}
	}
}

.show>.btn-dark.dropdown-toggle {
	color: $white;
	background-color: #1d2124;
	border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled) {
	&.active:focus,
	&:active:focus {
		box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.1);
	}
}

.show>.btn-dark.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.1);
}

.btn-outline-success {
	color: $success;
	background-color: transparent;
	background-image: none;
	border-color: $success;
	box-shadow: 0 5px 10px rgba(1, 210, 119, 0.3);
	&:hover {
		color: $white;
		background-color: $success;
		border-color: $success;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.1);
	}
	&.disabled,
	&:disabled {
		color: $success;
		background-color: transparent;
	}
	&:not(:disabled):not(.disabled) {
		&.active,
		&:active {
			color: $white;
			background-color: $success;
			border-color: $success;
		}
	}
}

.show>.btn-outline-success.dropdown-toggle {
	color: $white;
	background-color: $success;
	border-color: $success;
}

.btn-outline-success:not(:disabled):not(.disabled) {
	&.active:focus,
	&:active:focus {
		box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.1);
	}
}

.show>.btn-outline-success.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.1);
}

.btn-outline-info {
	color: $info;
	background-color: transparent;
	background-image: none;
	border-color: $info;
	box-shadow: 0 5px 10px rgba(11, 148, 247, 0.3);
	&:hover {
		color: $white;
		background-color: $info;
		border-color: $info;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 2px rgba(69, 170, 242, 0.1);
	}
	&.disabled,
	&:disabled {
		color: $info;
		background-color: transparent;
	}
	&:not(:disabled):not(.disabled) {
		&.active,
		&:active {
			color: $white;
			background-color: $info;
			border-color: $info;
		}
	}
}

.show>.btn-outline-info.dropdown-toggle {
	color: $white;
	background-color: $info;
	border-color: $info;
}

.btn-outline-info:not(:disabled):not(.disabled) {
	&.active:focus,
	&:active:focus {
		box-shadow: 0 0 0 2px rgba(69, 170, 242, 0.1);
	}
}

.show>.btn-outline-info.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(69, 170, 242, 0.1);
}

.btn-outline-warning {
	color: $yellow !important;
	background-color: transparent;
	background-image: none;
	border-color: $yellow;
	box-shadow: 0 5px 10px rgba(255, 162, 43, 0.1);
	&:hover {
		color: $white !important;
		background-color: $yellow;
		border-color: $yellow;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.1);
	}
	&.disabled,
	&:disabled {
		color: $yellow;
		background-color: transparent;
	}
	&:not(:disabled):not(.disabled) {
		&.active,
		&:active {
			color: $white;
			background-color: $yellow;
			border-color: $yellow;
		}
	}
}

.show>.btn-outline-warning.dropdown-toggle {
	color: $white;
	background-color: $yellow;
	border-color: $yellow;
}

.btn-outline-warning:not(:disabled):not(.disabled) {
	&.active:focus,
	&:active:focus {
		box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.1);
	}
}

.show>.btn-outline-warning.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.1);
}

.btn-outline-danger {
	color: $danger;
	background-color: transparent;
	background-image: none;
	border-color: $danger;
	box-shadow: 0 5px 10px rgba(248, 66, 66, 0.3);
	&:hover {
		color: $white;
		background-color: $danger;
		border-color: $danger;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.1);
	}
	&.disabled,
	&:disabled {
		color: $danger;
		background-color: transparent;
	}
	&:not(:disabled):not(.disabled) {
		&.active,
		&:active {
			color: $white;
			background-color: $danger;
			border-color: $danger;
		}
	}
}

.show>.btn-outline-danger.dropdown-toggle {
	color: $white;
	background-color: $danger;
	border-color: $danger;
}

.btn-outline-danger:not(:disabled):not(.disabled) {
	&.active:focus,
	&:active:focus {
		box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.1);
	}
}

.show>.btn-outline-danger.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.1);
}

.btn-outline-light {
	color: #e8ebf3;
	background-color: transparent;
	background-image: none;
	border-color: #e8ebf3;
	&:hover {
		color: #3d4e67;
		background-color: #e8ebf3;
		border-color: #e8ebf3;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
	}
	&.disabled,
	&:disabled {
		color: #e8ebf3;
		background-color: transparent;
	}
	&:not(:disabled):not(.disabled) {
		&.active,
		&:active {
			color: #3d4e67;
			background-color: #e8ebf3;
			border-color: #e8ebf3;
		}
	}
}

.show>.btn-outline-light.dropdown-toggle {
	color: #3d4e67;
	background-color: #e8ebf3;
	border-color: #e8ebf3;
}

.btn-outline-light:not(:disabled):not(.disabled) {
	&.active:focus,
	&:active:focus {
		box-shadow: 0 0 0 2px rgba(134, 142, 150, 0.1);
	}
}

.show>.btn-outline-light.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(134, 142, 150, 0.1);
}

.btn-outline-gray {
	color: #5a7693;
	background-color: transparent;
	background-image: none;
	border-color: #5a7693;
	&:hover {
		color: $white !important;
		background-color: #5a7693;
		border-color: #5a7693;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 2px rgba(134, 142, 150, 0.1);
	}
	&.disabled,
	&:disabled {
		color: $white;
		background-color: transparent;
	}
	&:not(:disabled):not(.disabled) {
		&.active,
		&:active {
			color: $white;
			background-color: #5a7693;
			border-color: #5a7693;
		}
	}
}

.show>.btn-outline-gray.dropdown-toggle {
	color: $white;
	background-color: #5a7693;
	border-color: #5a7693;
}

.btn-outline-gray:not(:disabled):not(.disabled) {
	&.active:focus,
	&:active:focus {
		box-shadow: 0 0 0 2px rgba(134, 142, 150, 0.1);
	}
}

.show>.btn-outline-gray.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(134, 142, 150, 0.1);
}

.btn-outline-dark {
	color: $dark;
	background-color: transparent;
	background-image: none;
	border-color: $dark;
	&:hover {
		color: $white;
		background-color: $dark;
		border-color: $dark;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.1);
	}
	&.disabled,
	&:disabled {
		color: $dark;
		background-color: transparent;
	}
	&:not(:disabled):not(.disabled) {
		&.active,
		&:active {
			color: $white;
			background-color: $dark;
			border-color: $dark;
		}
	}
}

.show>.btn-outline-dark.dropdown-toggle {
	color: $white;
	background-color: $dark;
	border-color: $dark;
}

.btn-outline-dark:not(:disabled):not(.disabled) {
	&.active:focus,
	&:active:focus {
		box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.1);
	}
}

.show>.btn-outline-dark.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.1);
}

.btn-link {
	font-weight: 400;
	background-color: transparent;
	box-shadow: 0 5px 10px rgba(238, 239, 249, 0.3);
	&:hover {
		color: #295a9f;
		text-decoration: underline;
		background-color: transparent;
		border-color: transparent;
	}
	&.focus,
	&:focus {
		text-decoration: underline;
		border-color: transparent;
		box-shadow: none;
	}
	&.disabled,
	&:disabled {
		color: #5a7693;
		pointer-events: none;
	}
}

.btn-block {
	display: block;
	width: 100%;
	+.btn-block {
		margin-block-start: 0.5rem;
	}
}

input {
	&[type=button].btn-block,
	&[type=reset].btn-block,
	&[type=submit].btn-block {
		width: 100%;
	}
}

.fade {
	transition: opacity 0.15s linear;
	&:not(.show) {
		opacity: 0;
	}
}

@media screen and (prefers-reduced-motion: reduce) {
	.fade {
		transition: none;
	}
}

.collapse:not(.show) {
	display: none;
	background: $white;
}

.collapsing {
	position: relative;
	height: 0;
	overflow: hidden;
	transition: height 0.35s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
	.collapsing {
		transition: none;
	}
}

.btn-icon {
	padding-inline-start: 0.5rem;
        padding-inline-end: 0.5rem;
   	text-align: center;
}

.btn.btn-app {
	position: relative;
	padding: 15px 5px;
	margin: 0 0 10px 10px;
	min-width: 80px;
	height: 60px;
	box-shadow: none;
	border-radius: 0;
	text-align: center;
	color: #8489a4;
	border: 1px solid #e8ebf3;
	background-color: #eeeff9;
	font-size: 12px;
	box-shadow: 0 5px 10px rgba(238, 239, 249, 0.3);
	> {
		.fa,
		.glyphicon,
		.ion {
			font-size: 20px;
			display: block;
		}
	}
	&:hover {
		background: #eeeff9;
		color: #3f5068;
		border-color: #e8ebf3;
	}
	&:active,
	&:focus {
		box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	}
	>.badge {
		position: absolute;
		top: -3px;
		 inset-inline-end: -10px;
		font-weight: 400;
	}
}

.btn-pill {
	border-radius: 10rem;
	padding-inline-start: 1.5em;
	padding-inline-end: 1.5em;
}

.btn-square {
	border-radius: 0;
}

.btn-facebook {
	color: $white;
	background-color: #3a5895;
	border-color: #3a5895;
	box-shadow: 0 5px 10px rgba(58, 88, 149, 0.3);
	&:hover,
	&.active {
		color: $white;
		background-color: #36528c;
		border-color: #36528c;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 2px rgba(58, 88, 149, 0.5);
	}
	&.disabled,
	&:disabled {
		color: $white;
		background-color: #36528c;
		border-color: #36528c;
	}
	&:not(:disabled):not(.disabled) {
		&.active,
		&:active {
			color: $white;
			background-color: #36528c;
			border-color: #36528c;
		}
	}
}

.show>.btn-facebook.dropdown-toggle {
	color: $white;
	background-color: #36528c;
	border-color: #36528c;
}

.btn-facebook:not(:disabled):not(.disabled) {
	&.active:focus,
	&:active:focus {
		box-shadow: 0 0 0 2px rgba(58, 88, 149, 0.5);
	}
}

.show>.btn-facebook.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(58, 88, 149, 0.5);
}

.btn-twitter {
	color: $white;
	background-color: #1da1f2;
	border-color: #1da1f2;
	box-shadow: 0 5px 10px rgba(29, 161, 242, 0.3);
	&:hover {
		color: $white;
		background-color: #0d8ddc;
		border-color: #0c85d0;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 2px rgba(29, 161, 242, 0.5);
	}
	&.disabled,
	&:disabled {
		color: $white;
		background-color: #1da1f2;
		border-color: #1da1f2;
	}
	&:not(:disabled):not(.disabled) {
		&.active,
		&:active {
			color: $white;
			background-color: #0c85d0;
			border-color: #0b7ec4;
		}
	}
}

.show>.btn-twitter.dropdown-toggle {
	color: $white;
	background-color: #0c85d0;
	border-color: #0b7ec4;
}

.btn-twitter:not(:disabled):not(.disabled) {
	&.active:focus,
	&:active:focus {
		box-shadow: 0 0 0 2px rgba(29, 161, 242, 0.5);
	}
}

.show>.btn-twitter.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(29, 161, 242, 0.5);
}

.btn-google {
	color: $white;
	background-color: #d34836;
	border-color: #d34836;
	box-shadow: 0 5px 10px rgba(211, 72, 54, 0.1);
	&.active,
	&:hover {
		color: $white;
		background-color: #d04237;
		border-color: #d04237;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 2px rgba(211, 72, 54, 0.5);
	}
	&.disabled,
	&:disabled {
		color: $white;
		background-color: #d04237;
		border-color: #d04237;
	}
	&:not(:disabled):not(.disabled) {
		&.active,
		&:active {
			color: $white;
			background-color: #d04237;
			border-color: #d04237;
		}
	}
}

.show>.btn-google.dropdown-toggle {
	color: $white;
	background-color: #d04237;
	border-color: #d04237;
}

.btn-google:not(:disabled):not(.disabled) {
	&.active:focus,
	&:active:focus {
		box-shadow: 0 0 0 2px rgba(211, 72, 54, 0.5);
	}
}

.show>.btn-google.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(211, 72, 54, 0.5);
}

.btn-youtube {
	color: $white;
	background-color: red;
	border-color: red;
	box-shadow: 0 5px 10px rgba(255, 0, 0, 0.3);
	&:hover {
		color: $white;
		background-color: #d90000;
		border-color: #c00;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.5);
	}
	&.disabled,
	&:disabled {
		color: $white;
		background-color: red;
		border-color: red;
	}
	&:not(:disabled):not(.disabled) {
		&.active,
		&:active {
			color: $white;
			background-color: #c00;
			border-color: #bf0000;
		}
	}
}

.show>.btn-youtube.dropdown-toggle {
	color: $white;
	background-color: #c00;
	border-color: #bf0000;
}

.btn-youtube:not(:disabled):not(.disabled) {
	&.active:focus,
	&:active:focus {
		box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.5);
	}
}

.show>.btn-youtube.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.5);
}

.btn-vimeo {
	color: $white;
	background-color: #1ab7ea;
	border-color: #1ab7ea;
	box-shadow: 0 5px 10px rgba(26, 183, 234, 0.3);
	&:hover {
		color: $white;
		background-color: #139ecb;
		border-color: #1295bf;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 2px rgba(26, 183, 234, 0.5);
	}
	&.disabled,
	&:disabled {
		color: $white;
		background-color: #1ab7ea;
		border-color: #1ab7ea;
	}
	&:not(:disabled):not(.disabled) {
		&.active,
		&:active {
			color: $white;
			background-color: #1295bf;
			border-color: #108cb4;
		}
	}
}

.show>.btn-vimeo.dropdown-toggle {
	color: $white;
	background-color: #1295bf;
	border-color: #108cb4;
}

.btn-vimeo:not(:disabled):not(.disabled) {
	&.active:focus,
	&:active:focus {
		box-shadow: 0 0 0 2px rgba(26, 183, 234, 0.5);
	}
}

.show>.btn-vimeo.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(26, 183, 234, 0.5);
}

.btn-dribbble {
	color: $white;
	background-color: #ea4c89;
	border-color: #ea4c89;
	box-shadow: 0 5px 10px rgba(234, 76, 137, 0.3);
	&:hover {
		color: $white;
		background-color: #e62a72;
		border-color: #e51e6b;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 2px rgba(234, 76, 137, 0.5);
	}
	&.disabled,
	&:disabled {
		color: $white;
		background-color: #ea4c89;
		border-color: #ea4c89;
	}
	&:not(:disabled):not(.disabled) {
		&.active,
		&:active {
			color: $white;
			background-color: #e51e6b;
			border-color: #dc1a65;
		}
	}
}

.show>.btn-dribbble.dropdown-toggle {
	color: $white;
	background-color: #e51e6b;
	border-color: #dc1a65;
}

.btn-dribbble:not(:disabled):not(.disabled) {
	&.active:focus,
	&:active:focus {
		box-shadow: 0 0 0 2px rgba(234, 76, 137, 0.5);
	}
}

.show>.btn-dribbble.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(234, 76, 137, 0.5);
}

.btn-github {
	color: $white;
	background-color: #181717;
	border-color: #181717;
	box-shadow: 0 5px 10px rgba(24, 23, 23, 0.3);
	&:hover {
		color: $white;
		background-color: #040404;
		border-color: $black;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 2px rgba(24, 23, 23, 0.5);
	}
	&.disabled,
	&:disabled {
		color: $white;
		background-color: #181717;
		border-color: #181717;
	}
	&:not(:disabled):not(.disabled) {
		&.active,
		&:active {
			color: $white;
			background-color: $black;
			border-color: $black;
		}
	}
}

.show>.btn-github.dropdown-toggle {
	color: $white;
	background-color: $black;
	border-color: $black;
}

.btn-github:not(:disabled):not(.disabled) {
	&.active:focus,
	&:active:focus {
		box-shadow: 0 0 0 2px rgba(24, 23, 23, 0.5);
	}
}

.show>.btn-github.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(24, 23, 23, 0.5);
}

.btn-instagram {
	color: $white;
	background-color: #e4405f;
	border-color: #e4405f;
	box-shadow: 0 5px 10px rgba(228, 64, 95, 0.3);
	&:hover {
		color: $white;
		background-color: #de1f44;
		border-color: #d31e40;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 2px rgba(228, 64, 95, 0.5);
	}
	&.disabled,
	&:disabled {
		color: $white;
		background-color: #e4405f;
		border-color: #e4405f;
	}
	&:not(:disabled):not(.disabled) {
		&.active,
		&:active {
			color: $white;
			background-color: #d31e40;
			border-color: #c81c3d;
		}
	}
}

.show>.btn-instagram.dropdown-toggle {
	color: $white;
	background-color: #d31e40;
	border-color: #c81c3d;
}

.btn-instagram:not(:disabled):not(.disabled) {
	&.active:focus,
	&:active:focus {
		box-shadow: 0 0 0 2px rgba(228, 64, 95, 0.5);
	}
}

.show>.btn-instagram.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(228, 64, 95, 0.5);
}

.btn-pinterest {
	color: $white;
	background-color: #bd081c;
	border-color: #bd081c;
	box-shadow: 0 5px 10px rgba(189, 8, 28, 0.3);
	&:hover {
		color: $white;
		background-color: #980617;
		border-color: #8c0615;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 2px rgba(189, 8, 28, 0.5);
	}
	&.disabled,
	&:disabled {
		color: $white;
		background-color: #bd081c;
		border-color: #bd081c;
	}
	&:not(:disabled):not(.disabled) {
		&.active,
		&:active {
			color: $white;
			background-color: #8c0615;
			border-color: #800513;
		}
	}
}

.show>.btn-pinterest.dropdown-toggle {
	color: $white;
	background-color: #8c0615;
	border-color: #800513;
}

.btn-pinterest:not(:disabled):not(.disabled) {
	&.active:focus,
	&:active:focus {
		box-shadow: 0 0 0 2px rgba(189, 8, 28, 0.5);
	}
}

.show>.btn-pinterest.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(189, 8, 28, 0.5);
}

.btn-vk {
	color: $white;
	background-color: #6383a8;
	border-color: #6383a8;
	box-shadow: 0 5px 10px rgba(99, 131, 168, 0.3);
	&:hover {
		color: $white;
		background-color: #527093;
		border-color: #4d6a8b;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 2px rgba(99, 131, 168, 0.5);
	}
	&.disabled,
	&:disabled {
		color: $white;
		background-color: #6383a8;
		border-color: #6383a8;
	}
	&:not(:disabled):not(.disabled) {
		&.active,
		&:active {
			color: $white;
			background-color: #4d6a8b;
			border-color: #496482;
		}
	}
}

.show>.btn-vk.dropdown-toggle {
	color: $white;
	background-color: #4d6a8b;
	border-color: #496482;
}

.btn-vk:not(:disabled):not(.disabled) {
	&.active:focus,
	&:active:focus {
		box-shadow: 0 0 0 2px rgba(99, 131, 168, 0.5);
	}
}

.show>.btn-vk.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(99, 131, 168, 0.5);
}

.btn-rss {
	color: $white;
	background-color: orange;
	border-color: orange;
	box-shadow: 0 5px 10px rgba(255, 165, 0, 0.3);
	&:hover {
		color: $white;
		background-color: #d98c00;
		border-color: #cc8400;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 2px rgba(255, 165, 0, 0.5);
	}
	&.disabled,
	&:disabled {
		color: $white;
		background-color: orange;
		border-color: orange;
	}
	&:not(:disabled):not(.disabled) {
		&.active,
		&:active {
			color: $white;
			background-color: #cc8400;
			border-color: #bf7c00;
		}
	}
}

.show>.btn-rss.dropdown-toggle {
	color: $white;
	background-color: #cc8400;
	border-color: #bf7c00;
}

.btn-rss:not(:disabled):not(.disabled) {
	&.active:focus,
	&:active:focus {
		box-shadow: 0 0 0 2px rgba(255, 165, 0, 0.5);
	}
}

.show>.btn-rss.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(255, 165, 0, 0.5);
}

.btn-flickr {
	color: $white;
	background-color: #0063dc;
	border-color: #0063dc;
	box-shadow: 0 5px 10px rgba(0, 99, 220, 0.3);
	&:hover {
		color: $white;
		background-color: #0052b6;
		border-color: #004ca9;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 2px rgba(0, 99, 220, 0.5);
	}
	&.disabled,
	&:disabled {
		color: $white;
		background-color: #0063dc;
		border-color: #0063dc;
	}
	&:not(:disabled):not(.disabled) {
		&.active,
		&:active {
			color: $white;
			background-color: #004ca9;
			border-color: #00469c;
		}
	}
}

.show>.btn-flickr.dropdown-toggle {
	color: $white;
	background-color: #004ca9;
	border-color: #00469c;
}

.btn-flickr:not(:disabled):not(.disabled) {
	&.active:focus,
	&:active:focus {
		box-shadow: 0 0 0 2px rgba(0, 99, 220, 0.5);
	}
}

.show>.btn-flickr.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(0, 99, 220, 0.5);
}

.btn-bitbucket {
	color: $white;
	background-color: #0052cc;
	border-color: #0052cc;
	&:hover {
		color: $white;
		background-color: #0043a6;
		border-color: #003e99;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 2px rgba(0, 82, 204, 0.5);
	}
	&.disabled,
	&:disabled {
		color: $white;
		background-color: #0052cc;
		border-color: #0052cc;
	}
	&:not(:disabled):not(.disabled) {
		&.active,
		&:active {
			color: $white;
			background-color: #003e99;
			border-color: #00388c;
		}
	}
}

.show>.btn-bitbucket.dropdown-toggle {
	color: $white;
	background-color: #003e99;
	border-color: #00388c;
}

.btn-bitbucket:not(:disabled):not(.disabled) {
	&.active:focus,
	&:active:focus {
		box-shadow: 0 0 0 2px rgba(0, 82, 204, 0.5);
	}
}

.show>.btn-bitbucket.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(0, 82, 204, 0.5);
}

.btn-blue {
	color: $white;
	background-color: $blue;
	border-color: $blue;
	&:hover {
		color: $white;
		background-color: #2077f3;
		border-color: #2077f3;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 2px rgba(5, 117, 230, 0.5);
	}
	&.disabled,
	&:disabled {
		color: $white;
		background-color: $blue;
		border-color: $blue;
	}
	&:not(:disabled):not(.disabled) {
		&.active,
		&:active {
			color: $white;
			background-color: #2077f3;
			border-color: #2c60a9;
		}
	}
}

.show>.btn-blue.dropdown-toggle {
	color: $white;
	background-color: #2077f3;
	border-color: #2c60a9;
}

.btn-blue:not(:disabled):not(.disabled) {
	&.active:focus,
	&:active:focus {
		box-shadow: 0 0 0 2px rgba(5, 117, 230, 0.5);
	}
}

.show>.btn-blue.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(5, 117, 230, 0.5);
}

.btn-indigo {
	color: $white;
	background-color: $indigo;
	border-color: $indigo;
	&:hover {
		color: $white;
		background-color: #485ac4;
		border-color: #3f51c1;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 2px rgba(101, 116, 205, 0.5);
	}
	&.disabled,
	&:disabled {
		color: $white;
		background-color: $indigo;
		border-color: $indigo;
	}
	&:not(:disabled):not(.disabled) {
		&.active,
		&:active {
			color: $white;
			background-color: #3f51c1;
			border-color: #3b4db7;
		}
	}
}

.show>.btn-indigo.dropdown-toggle {
	color: $white;
	background-color: #3f51c1;
	border-color: #3b4db7;
}

.btn-indigo:not(:disabled):not(.disabled) {
	&.active:focus,
	&:active:focus {
		box-shadow: 0 0 0 2px rgba(101, 116, 205, 0.5);
	}
}

.show>.btn-indigo.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(101, 116, 205, 0.5);
}

.btn-purple {
	color: $white;
	background-color: $purple;
	border-color: $purple;
	box-shadow: 0 5px 10px rgba(139, 60, 224, 0.3);
	&:hover {
		color: $white;
		background-color: #923ce6;
		border-color: #8c31e4;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 2px rgba(165, 94, 234, 0.5);
	}
	&.disabled,
	&:disabled {
		color: $white;
		background-color: #604dd8;
		border-color: #604dd8;
	}
	&:not(:disabled):not(.disabled) {
		&.active,
		&:active {
			color: $white;
			background-color: #8c31e4;
			border-color: #8526e3;
		}
	}
}

.show>.btn-purple.dropdown-toggle {
	color: $white;
	background-color: #8c31e4;
	border-color: #8526e3;
}

.btn-purple:not(:disabled):not(.disabled) {
	&.active:focus,
	&:active:focus {
		box-shadow: 0 0 0 2px rgba(165, 94, 234, 0.5);
	}
}

.show>.btn-purple.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(165, 94, 234, 0.5);
}

.btn-pink {
	color: $white;
	background-color: #ec296b;
	border-color: #ec296b;
	&:hover {
		color: $white;
		background-color: #e61470;
		border-color: #e61470;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 2px rgba(246, 109, 155, 0.5);
	}
	&.disabled,
	&:disabled {
		color: $white;
		background-color: #ec296b;
		border-color: #ec296b;
	}
	&:not(:disabled):not(.disabled) {
		&.active,
		&:active {
			color: $white;
			background-color: #e61470;
			border-color: #e61470;
		}
	}
}

.show>.btn-pink.dropdown-toggle {
	color: $white;
	background-color: #e61470;
	border-color: #e61470;
}

.btn-pink:not(:disabled):not(.disabled) {
	&.active:focus,
	&:active:focus {
		box-shadow: 0 0 0 2px rgba(246, 109, 155, 0.5);
	}
}

.show>.btn-pink.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(246, 109, 155, 0.5);
}

.btn-red {
	color: $white;
	background-color: $danger;
	border-color: $danger;
	&:hover {
		color: $white;
		background-color: #f34a4a;
		border-color: #f34a4a;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.5);
	}
	&.disabled,
	&:disabled {
		color: $white;
		background-color: $danger;
		border-color: $danger;
	}
	&:not(:disabled):not(.disabled) {
		&.active,
		&:active {
			color: $white;
			background-color: #f34a4a;
			border-color: #f34a4a;
		}
	}
}

.show>.btn-red.dropdown-toggle {
	color: $white;
	background-color: #f34a4a;
	border-color: #f34a4a;
}

.btn-red:not(:disabled):not(.disabled) {
	&.active:focus,
	&:active:focus {
		box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.5);
	}
}

.show>.btn-red.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.5);
}

.btn-orange {
	color: $white;
	background-color: #e67605;
	border-color: #e67605;
	&:hover {
		color: $white;
		background-color: #fd811e;
		border-color: #fc7a12;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 2px rgba(253, 150, 68, 0.5);
	}
	&.disabled,
	&:disabled {
		color: $white;
		background-color: #e67605;
		border-color: #e67605;
	}
	&:not(:disabled):not(.disabled) {
		&.active,
		&:active {
			color: $white;
			background-color: #fc7a12;
			border-color: #fc7305;
		}
	}
}

.show>.btn-orange.dropdown-toggle {
	color: $white;
	background-color: #fc7a12;
	border-color: #fc7305;
}

.btn-orange:not(:disabled):not(.disabled) {
	&.active:focus,
	&:active:focus {
		box-shadow: 0 0 0 2px rgba(253, 150, 68, 0.5);
	}
}

.show>.btn-orange.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(253, 150, 68, 0.5);
}

.btn-yellow {
	color: $white;
	background-color: $yellow;
	border-color: $yellow;
	&:hover {
		color: $white;
		background-color: #f59713;
		border-color: #f59713;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.5);
	}
	&.disabled,
	&:disabled {
		color: $white;
		background-color: $yellow;
		border-color: $yellow;
	}
	&:not(:disabled):not(.disabled) {
		&.active,
		&:active {
			color: $white;
			background-color: #f59713;
			border-color: #b6940b;
		}
	}
}

.show>.btn-yellow.dropdown-toggle {
	color: $white;
	background-color: #f59713;
	border-color: #b6940b;
}

.btn-yellow:not(:disabled):not(.disabled) {
	&.active:focus,
	&:active:focus {
		box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.5);
	}
}

.show>.btn-yellow.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.5);
}

.btn-green {
	color: $white;
	background-color: $success;
	border-color: $success;
	&:hover {
		color: $white;
		background-color: #3adfab;
		border-color: #3adfab;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.5);
	}
	&.disabled,
	&:disabled {
		color: $white;
		background-color: $success;
		border-color: $success;
	}
	&:not(:disabled):not(.disabled) {
		&.active,
		&:active {
			color: $white;
			background-color: #3adfab;
			border-color: #3adfab;
		}
	}
}

.show>.btn-green.dropdown-toggle {
	color: $white;
	background-color: #3adfab;
	border-color: #3adfab;
}

.btn-green:not(:disabled):not(.disabled) {
	&.active:focus,
	&:active:focus {
		box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.5);
	}
}

.show>.btn-green.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.5);
}

.btn-teal {
	color: $white;
	background-color: $teal;
	border-color: $teal;
	&:hover {
		color: $white;
		background-color: #05fafa;
		border-color: #05fafa;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 2px rgba(43, 203, 186, 0.5);
	}
	&.disabled,
	&:disabled {
		color: $white;
		background-color: $teal;
		border-color: $teal;
	}
	&:not(:disabled):not(.disabled) {
		&.active,
		&:active {
			color: $white;
			background-color: #05fafa;
			border-color: #05fafa;
		}
	}
}

.show>.btn-teal.dropdown-toggle {
	color: $white;
	background-color: #05fafa;
	border-color: #05fafa;
}

.btn-teal:not(:disabled):not(.disabled) {
	&.active:focus,
	&:active:focus {
		box-shadow: 0 0 0 2px rgba(43, 203, 186, 0.5);
	}
}

.show>.btn-teal.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(43, 203, 186, 0.5);
}

.btn-cyan {
	color: $white;
	background-color: #17a2b8;
	border-color: #17a2b8;
	&:hover {
		color: $white;
		background-color: #138496;
		border-color: #117a8b;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 2px rgba(23, 162, 184, 0.5);
	}
	&.disabled,
	&:disabled {
		color: $white;
		background-color: #17a2b8;
		border-color: #17a2b8;
	}
	&:not(:disabled):not(.disabled) {
		&.active,
		&:active {
			color: $white;
			background-color: #117a8b;
			border-color: #10707f;
		}
	}
}

.show>.btn-cyan.dropdown-toggle {
	color: $white;
	background-color: #117a8b;
	border-color: #10707f;
}

.btn-cyan:not(:disabled):not(.disabled) {
	&.active:focus,
	&:active:focus {
		box-shadow: 0 0 0 2px rgba(23, 162, 184, 0.5);
	}
}

.show>.btn-cyan.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(23, 162, 184, 0.5);
}

.btn-white {
	color: #3d4e67;
	background-color: $white;
	border-color: rgba(167, 180, 201, 0.6);
	&:hover {
		color: #3d4e67;
		background-color: #e9ecfb;
		border-color: #e9ecfb;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 2px $white-5;
	}
	&.disabled,
	&:disabled {
		color: #3d4e67;
		background-color: $white;
		border-color: $white;
	}
	&:not(:disabled):not(.disabled) {
		&.active,
		&:active {
			color: #3d4e67;
			background-color: #e9ecfb;
			border-color: #e9ecfb;
		}
	}
}

.show>.btn-white.dropdown-toggle {
	color: #3d4e67;
	background-color: #e9ecfb;
	border-color: #e9ecfb;
}

.btn-white:not(:disabled):not(.disabled) {
	&.active:focus,
	&:active:focus {
		box-shadow: 0 0 0 2px $white-5;
	}
}

.show>.btn-white.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px $white-5;
}

.btn-gray {
	color: $white;
	background-color: #5a7693;
	border-color: #5a7693;
	&:hover {
		color: $white;
		background-color: #727b84;
		border-color: #2c2c2c;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 2px rgba(134, 142, 150, 0.5);
	}
	&.disabled,
	&:disabled {
		color: $white;
		background-color: #5a7693;
		border-color: #5a7693;
	}
	&:not(:disabled):not(.disabled) {
		&.active,
		&:active {
			color: $white;
			background-color: #2c2c2c;
			border-color: #666e76;
		}
	}
}

.show>.btn-gray.dropdown-toggle {
	color: $white;
	background-color: #2c2c2c;
	border-color: #666e76;
}

.btn-gray:not(:disabled):not(.disabled) {
	&.active:focus,
	&:active:focus {
		box-shadow: 0 0 0 2px rgba(134, 142, 150, 0.5);
	}
}

.show>.btn-gray.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(134, 142, 150, 0.5);
}

.btn-gray-dark {
	color: $white;
	background-color: $dark;
	border-color: $dark;
	&:hover {
		color: $white;
		background-color: #23272b;
		border-color: #1d2124;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.5);
	}
	&.disabled,
	&:disabled {
		color: $white;
		background-color: $dark;
		border-color: $dark;
	}
	&:not(:disabled):not(.disabled) {
		&.active,
		&:active {
			color: $white;
			background-color: #1d2124;
			border-color: #171a1d;
		}
	}
}

.show>.btn-gray-dark.dropdown-toggle {
	color: $white;
	background-color: #1d2124;
	border-color: #171a1d;
}

.btn-gray-dark:not(:disabled):not(.disabled) {
	&.active:focus,
	&:active:focus {
		box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.5);
	}
}

.show>.btn-gray-dark.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.5);
}

.btn-azure {
	color: $white;
	background-color: $info;
	border-color: $info;
	&:hover {
		color: $white;
		background-color: #219af0;
		border-color: #1594ef;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 2px rgba(69, 170, 242, 0.5);
	}
	&.disabled,
	&:disabled {
		color: $white;
		background-color: $info;
		border-color: $info;
	}
	&:not(:disabled):not(.disabled) {
		&.active,
		&:active {
			color: $white;
			background-color: #1594ef;
			border-color: #108ee7;
		}
	}
}

.show>.btn-azure.dropdown-toggle {
	color: $white;
	background-color: #1594ef;
	border-color: #108ee7;
}

.btn-azure:not(:disabled):not(.disabled) {
	&.active:focus,
	&:active:focus {
		box-shadow: 0 0 0 2px rgba(69, 170, 242, 0.5);
	}
}

.show>.btn-azure.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(69, 170, 242, 0.5);
}

.btn-lime {
	color: $white;
	background-color: #63e600;
	border-color: #63e600;
	&:hover {
		color: $white;
		background-color: #69b829;
		border-color: #63ad27;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 2px rgba(123, 210, 53, 0.5);
	}
	&.disabled,
	&:disabled {
		color: $white;
		background-color: #63e600;
		border-color: #63e600;
	}
	&:not(:disabled):not(.disabled) {
		&.active,
		&:active {
			color: $white;
			background-color: #63ad27;
			border-color: #5da324;
		}
	}
}

.show>.btn-lime.dropdown-toggle {
	color: $white;
	background-color: #63ad27;
	border-color: #5da324;
}

.btn-lime:not(:disabled):not(.disabled) {
	&.active:focus,
	&:active:focus {
		box-shadow: 0 0 0 2px rgba(123, 210, 53, 0.5);
	}
}

.show>.btn-lime.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(123, 210, 53, 0.5);
}

.btn-option {
	background: 0 0;
	color: #8489a4;
	&:hover {
		color: #5f6877;
	}
	&:focus {
		box-shadow: none;
		color: #5f6877;
	}
}

.btn-group-sm>.btn,
.btn-sm {
	font-size: .75rem;
	min-width: 1.625rem;
	min-height: 0;
	padding: .25rem .5rem;
	line-height: 1.33333333;
	border-radius: 3px;
}

.btn-group-lg>.btn,
.btn-lg {
	font-size: 1.09rem;
	min-width: 2.75rem;
	font-weight: 400;
	padding: .5rem 1rem;
	font-size: 1.125rem;
	line-height: 1.625;
	border-radius: 3px;
}

.btn-list {
	margin-block-end: -.5rem;
	font-size: 0;
	> {
		.btn,
		.dropdown {
			margin-block-end: 0.5rem;
		}
		.btn:not(:last-child),
		.dropdown:not(:last-child) {
			margin-inline-end: 0.5rem;
		}
	}
}

.btn-loading {
	color: transparent !important;
	pointer-events: none;
	position: relative;
	&:after {
		content: '';
		-webkit-animation: loader .5s infinite linear;
		animation: loader .5s infinite linear;
		border: 2px solid $white;
		border-radius: 50%;
		border-inline-end-color: transparent !important;
		border-top-color: transparent !important;
		display: block;
		height: 1.4em;
		width: 1.4em;
		position: absolute;
		inset-inline-start: calc(50% - (1.4em / 2));
		top: calc(50% - (1.4em / 2));
		-webkit-transform-origin: center;
		transform-origin: center;
		position: absolute !important;
	}
}

.btn-group-sm>.btn-loading.btn:after {
	height: 1em;
	width: 1em;
	inset-inline-start: calc(50% - (1em / 2));
	top: calc(50% - (1em / 2));
}

.btn-loading {
	&.btn-sm:after {
		height: 1em;
		width: 1em;
		inset-inline-start: calc(50% - (1em / 2));
		top: calc(50% - (1em / 2));
	}
	&.btn-secondary:after {
		border-color: #3d4e67;
	}
}

.btn-send {
	background: none repeat scroll 0 0 #00a8b3;
	color: $white;
	&:hover {
		background: none repeat scroll 0 0 #00a8b3;
		color: $white;
		background: none repeat scroll 0 0 #009da7;
	}
}

.btn-overlay span {
	display: block;
	margin: .5rem;
	font-size: 16px;
	font-weight: 600;
}

.btn-floating {
	&.btn-sm i {
		font-size: .96154rem;
		line-height: 36.15385px;
	}
	i {
		display: inline-block;
		width: inherit;
		text-align: center;
		color: $white;
	}
	width: 35px;
	height: 35px;
	line-height: 1.7;
	position: relative;
	z-index: 1;
	vertical-align: middle;
	display: inline-block;
	overflow: hidden;
	-webkit-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	padding: 0;
	cursor: pointer;
	background: $white-1;
	box-shadow: 0 5px 11px 0 $black-1,
	0 4px 15px 0 $black-1;
}

.btn-group {
	position: relative;
	display: -ms-inline-flexbox;
	display: inline-flex;
	vertical-align: middle;
}

.btn-group-vertical {
	position: relative;
	display: -ms-inline-flexbox;
	display: inline-flex;
	vertical-align: middle;
	>.btn {
		position: relative;
		-ms-flex: 0 1 auto;
		flex: 0 1 auto;
	}
}

.btn-group>.btn {
	position: relative;
	-ms-flex: 0 1 auto;
	flex: 0 1 auto;
}

.btn-group-vertical>.btn:hover,
.btn-group>.btn:hover {
	z-index: 1;
}

.btn-group-vertical>.btn {
	&.active,
	&:active,
	&:focus {
		z-index: 1;
	}
}

.btn-group {
	>.btn {
		&.active,
		&:active,
		&:focus {
			z-index: 1;
		}
	}
	.btn+ {
		.btn,
		.btn-group {
			margin-inline-start: -1px;
		}
	}
	.btn-group+ {
		.btn,
		.btn-group {
			margin-inline-start: -1px;
		}
	}
}

.btn-group-vertical {
	.btn+ {
		.btn,
		.btn-group {
			margin-inline-start: -1px;
		}
	}
	.btn-group+ {
		.btn,
		.btn-group {
			margin-inline-start: -1px;
		}
	}
}

.btn-toolbar {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-pack: start;
	justify-content: flex-start;
	.input-group {
		width: auto;
	}
}

.btn-group> {
	.btn:first-child {
		margin-inline-start: 0;
	}
	.btn-group:not(:last-child)>.btn,
	.btn:not(:last-child):not(.dropdown-toggle) {
		border-start-end-radius: 0;
		border-end-end-radius: 0;
	}
	.btn-group:not(:first-child)>.btn,
	.btn:not(:first-child) {
		border-start-start-radius: 0;
		border-end-start-radius: 0;
	}
}

.btn-group-sm>.btn+.dropdown-toggle-split,
.btn-sm+.dropdown-toggle-split {
	padding-inline-end: .375rem;
	padding-inline-start: .375rem;
}

.btn-group-lg>.btn+.dropdown-toggle-split,
.btn-lg+.dropdown-toggle-split {
	padding-inline-end: .75rem;
	padding-inline-start: .75rem;
}

.btn-group-vertical {
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-align: start;
	align-items: flex-start;
	-ms-flex-pack: center;
	justify-content: center;
	.btn,
	.btn-group {
		width: 100%;
	}
	> {
		.btn+ {
			.btn,
			.btn-group {
				margin-block-start: -1px;
				margin-inline-start: 0;
			}
		}
		.btn-group {
			+ {
				.btn,
				.btn-group {
					margin-block-start: -1px;
					margin-inline-start: 0;
				}
			}
			&:not(:last-child)>.btn {
				border-end-end-radius: 0;
				border-end-start-radius: 0;
			}
		}
		.btn:not(:last-child):not(.dropdown-toggle) {
			border-end-end-radius: 0;
			border-end-start-radius: 0;
		}
		.btn-group:not(:first-child)>.btn,
		.btn:not(:first-child) {
			border-start-start-radius: 0;
			border-start-end-radius: 0;
		}
	}
}

.btn-group-toggle> {
	.btn,
	.btn-group>.btn {
		margin-block-end: 0;
	}
	.btn input {
		&[type=checkbox],
		&[type=radio] {
			position: absolute;
			clip: rect(0, 0, 0, 0);
			pointer-events: none;
		}
	}
	.btn-group>.btn input {
		&[type=checkbox],
		&[type=radio] {
			position: absolute;
			clip: rect(0, 0, 0, 0);
			pointer-events: none;
		}
	}
}

btn.dropdown-toggle~.dropdown-menu {
	background-color: #f4f4f4 !important;
	background-color: $white !important;
	border: 0 solid #4285f4 !important;
	box-shadow: 0 0 3px rgba(25, 25, 25, 0.3) !important;
	top: 0 !important;
	margin: 0 !important;
	padding: 0 !important;
}

.btn.dropdown-toggle {
	&.btn-primary~.dropdown-menu .dropdown-plus-title {
		border-color: #0575e6 !important;
	}
	&.btn-success~.dropdown-menu .dropdown-plus-title {
		border-color: #4cae4c !important;
	}
	&.btn-info~.dropdown-menu .dropdown-plus-title {
		border-color: #46b8da !important;
	}
	&.btn-warning~.dropdown-menu .dropdown-plus-title {
		border-color: #eea236 !important;
	}
	&.btn-danger~.dropdown-menu .dropdown-plus-title {
		border-color: #d43f3a !important;
	}
}

.btn-orange {
	color: $white;
	background-color: #e67605;
	border-color: #e67605;
}
.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active{
  border-color:transparent;
}
.btn-profile{
   margin-inline-start: 35px;
    margin-inline-end: 35px;
}