/*--input-group--*/

.input-group-text,
.input-group-btn,
.input-group-text {
	font-size: .9375rem;
}

.input-group-text>.btn,
.input-group-btn>.btn {
	height: 100%;
	border-color: rgba(0, 40, 100, 0.12);
}

.input-group-text> {
	.btn {
		height: 100%;
		border-color: rgba(0, 40, 100, 0.12);
	}
	.input-group-text {
		border-inline-end: 0;
	}
}

.input-group-text>.input-group-text {
	border-inline-start: 0;
}

.input-group-sm> {
	.input-group-text>select {
		&.btn:not([size]):not([multiple]),
		&.input-group-text:not([size]):not([multiple]) {
			height: calc(1.8125rem + 2px);
		}
	}
	.input-group-text>select {
		&.btn:not([size]):not([multiple]),
		&.input-group-text:not([size]):not([multiple]) {
			height: calc(1.8125rem + 2px);
		}
	}
	select.form-control:not([size]):not([multiple]) {
		height: calc(1.8125rem + 2px);
	}
}

select.form-control-sm:not([size]):not([multiple]) {
	height: calc(1.8125rem + 2px);
}

.input-group-lg> {
	.input-group-text>select {
		&.btn:not([size]):not([multiple]),
		&.input-group-text:not([size]):not([multiple]) {
			height: calc(2.6875rem + 2px);
		}
	}
	.input-group-text>select {
		&.btn:not([size]):not([multiple]),
		&.input-group-text:not([size]):not([multiple]) {
			height: calc(2.6875rem + 2px);
		}
	}
	select.form-control:not([size]):not([multiple]) {
		height: calc(2.6875rem + 2px);
	}
}

select.form-control-lg:not([size]):not([multiple]) {
	height: calc(2.6875rem + 2px);
}

.input-group {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-align: stretch;
	align-items: stretch;
	width: 100%;
	> {
		.custom-file,
		.form-select,
		.form-control {
			position: relative;
			-ms-flex: 1 1 auto;
			flex: 1 1 auto;
			width: 1%;
			margin-block-end: 0;
		}
		.custom-file:focus,
		.form-select:focus,
		.form-control:focus {
			z-index: 3;
		}
		.custom-file+ {
			.custom-file,
			.form-select,
			.form-control {
				margin-inline-start: -1px;
			}
		}
		.form-select+ {
			.custom-file,
			.form-select,
			.form-control {
				margin-inline-start: -1px;
			}
		}
		.form-control+ {
			.custom-file,
			.form-select,
			.form-control {
				margin-inline-start: -1px;
			}
		}
		.form-select:not(:last-child),
		.form-control:not(:last-child) {
			border-start-end-radius: 0;
			border-end-end-radius: 0;
		}
		.form-select:not(:first-child),
		.form-control:not(:first-child) {
			border-start-start-radius: 0;
			border-end-start-radius: 0;
		}
		.custom-file {
			display: -ms-flexbox;
			display: flex;
			-ms-flex-align: center;
			align-items: center;
			&:not(:last-child) .custom-file-label {
				border-start-end-radius: 0;
				border-end-end-radius: 0;
				&::after {
					border-start-end-radius: 0;
					border-end-end-radius: 0;
				}
			}
			&:not(:first-child) .custom-file-label {
				border-start-start-radius: 0;
				border-end-start-radius: 0;
				&::after {
					border-start-start-radius: 0;
					border-end-start-radius: 0;
				}
			}
		}
	}
}

.input-group-text,
.input-group-text {
	display: -ms-flexbox;
	display: flex;
}

.input-group-text .btn,
.input-group-text .btn {
	position: relative;
	z-index: 2;
}

.input-group-text {
	.btn+ {
		.btn,
		.input-group-text {
			margin-inline-start: 0px;
		}
	}
	.input-group-text+ {
		.btn,
		.input-group-text {
			margin-inline-start: 0px;
		}
	}
}

.input-group-text {
	.btn+ {
		.btn,
		.input-group-text {
			margin-inline-start: 0px;
		}
	}
	.input-group-text+ {
		.btn,
		.input-group-text {
			margin-inline-start: 0px;
		}
	}
	margin-inline-end: 0px;
}

.input-group-text {
	margin-inline-start: 0px;
}

.input-group-text {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	padding: .375rem .75rem;
	margin-block-end: 0;
	font-size: .9375rem;
	font-weight: 400;
	line-height: 1.6;
	color: #3d4e67;
	text-align: center;
	white-space: nowrap;
	background-color: #f3f3fd;
	border: 1px solid $border;
	border-radius: 4px;
	input {
		&[type=checkbox],
		&[type=radio] {
			margin-block-start: 0;
		}
	}
}

.input-group> {
	.input-group-text {
		&:last-child> {
			.btn:not(:last-child):not(.dropdown-toggle),
			.input-group-text:not(:last-child) {
				border-start-end-radius: 0;
				border-end-end-radius: 0;
			}
		}
		&:not(:last-child)> {
			.btn,
			.input-group-text {
				border-start-end-radius: 0;
				border-end-end-radius: 0;
			}
		}
	}
	.input-group-text> {
		.btn,
		.input-group-text {
			border-start-end-radius: 0;
			border-end-end-radius: 0;
		}
	}
	.input-group-text> {
		.btn,
		.input-group-text {
			border-start-start-radius: 0;
			border-end-start-radius: 0;
		}
	}
	.input-group-text {
		&:first-child> {
			.btn:not(:first-child),
			.input-group-text:not(:first-child) {
				border-start-start-radius: 0;
				border-end-start-radius: 0;
			}
		}
		&:not(:first-child)> {
			.btn,
			.input-group-text {
				border-start-start-radius: 0;
				border-end-start-radius: 0;
			}
		}
	}
}

.input-group-text {
	margin-inline-start: -1px;
}


/*--input-group--*/