/*----------------- Ribbon Stylesheet -----------------*/

/*--Ribbons--*/

.ribbone {
	width: 100%;
	position: relative;
	background-size: cover;
	text-transform: uppercase;
	color: $white;
}

@media (max-width: 500px) {
	.ribbone {
		width: 100%;
	}
}

.ribbon1 {
	position: absolute;
	top: -6.1px;
	inset-inline-start: 10px;
	z-index: 1;
	&:after {
		position: absolute;
		content: "";
		width: 0;
		height: 0;
		border-inline-start: 20px solid transparent;
		border-inline-end: 24px solid transparent;
		border-block-start: 13px solid #f8463f;
	}
	span {
		position: relative;
		display: block;
		text-align: center;
		background: #f8463f;
		font-size: 14px;
		line-height: 1;
		padding: 12px 8px 10px;
		border-start-end-radius: 8px;
		&:after {
			position: absolute;
			content: "";
		}
		&:before {
			position: absolute;
			content: "";
			height: 6px;
			width: 6px;
			inset-inline-start: -6px;
			top: 0;
			background: #f8463f;
		}
		&:after {
			height: 6px;
			width: 8px;
			inset-inline-start: -8px;
			top: 0;
			border-radius: 8px 8px 0 0;
			background: #c02031;
		}
	}
}


/*--arrow Ribbon--*/

.arrow-ribbon {
	color: $white;
	padding: 2px 8px;
	position: absolute;
	top: 10px;
	inset-inline-start: 0;
	z-index: 98;
	font-size: 18px;
	&.bg-purple:before {
		position: absolute;
		 inset-inline-end: 0;
		top: 0;
		bottom: 0;
		content: "";
		 inset-inline-end: -15px;
		border-block-start: 15px solid transparent;
		border-inline-start: 15px solid #6d33ff;
		border-block-end: 15px solid transparent;
		width: 0;
	}
	&.bg-danger:before {
		position: absolute;
		 inset-inline-end: 0;
		top: 0;
		bottom: 0;
		content: "";
		 inset-inline-end: -15px;
		border-block-start: 15px solid transparent;
		border-inline-start: 15px solid $danger;
		border-block-end: 15px solid transparent;
		width: 0;
	}
	&.bg-primary:before {
		position: absolute;
		 inset-inline-end: 0;
		top: 0;
		bottom: 0;
		content: "";
		 inset-inline-end: -15px;
		border-block-start: 15px solid transparent;
		border-block-end: 15px solid transparent;
		width: 0;
	}
	&.bg-secondary:before {
		position: absolute;
		 inset-inline-end: 0;
		top: 0;
		bottom: 0;
		content: "";
		 inset-inline-end: -15px;
		border-block-start: 15px solid transparent;
		border-inline-start: 15px solid $secondary;
		border-block-end: 15px solid transparent;
		width: 0;
	}
	&.bg-success:before {
		position: absolute;
		 inset-inline-end: 0;
		top: 0;
		bottom: 0;
		content: "";
		 inset-inline-end: -15px;
		border-block-start: 15px solid transparent;
		border-inline-start: 15px solid #09d37b;
		border-block-end: 15px solid transparent;
		width: 0;
	}
	&.bg-info:before {
		position: absolute;
		 inset-inline-end: 0;
		top: 0;
		bottom: 0;
		content: "";
		 inset-inline-end: -15px;
		border-block-start: 15px solid transparent;
		border-inline-start: 15px solid $info;
		border-block-end: 15px solid transparent;
		width: 0;
	}
	&.bg-warning:before {
		position: absolute;
		 inset-inline-end: 0;
		top: 0;
		bottom: 0;
		content: "";
		 inset-inline-end: -15px;
		border-block-start: 15px solid transparent;
		border-inline-start: 15px solid $yellow;
		border-block-end: 15px solid transparent;
		width: 0;
	}
	&.bg-pink:before {
		position: absolute;
		 inset-inline-end: 0;
		top: 0;
		bottom: 0;
		content: "";
		 inset-inline-end: -15px;
		border-block-start: 15px solid transparent;
		border-inline-start: 15px solid #f5127b;
		border-block-end: 15px solid transparent;
		width: 0;
	}
}

.arrow-ribbon2 {
	color: $white;
	padding: 3px 8px;
	position: absolute;
	top: 10px;
	inset-inline-start: -1px;
	z-index: 98;
	font-size: 30px;
	&:before {
		position: absolute;
		 inset-inline-end: 0;
		top: 0;
		bottom: 0;
		content: "";
		 inset-inline-end: -24px;
		border-block-start: 24px solid transparent;
		border-block-end: 24px solid transparent;
		width: 0;
	}
}

.ribbon {
	width: 150px;
	height: 150px;
	overflow: hidden;
	position: absolute;
	z-index: 10;
}

.power-ribbon {
	width: 56px;
	height: 56px;
	overflow: hidden;
	position: absolute;
	z-index: 1;
	span {
		position: absolute;
		display: block;
		width: 82px;
		padding: 8px 0;
		color: $white;
		font: 500 16px/1 'Roboto', sans-serif;
		text-shadow: 0 1px 1px $black-2;
		text-transform: capitalize;
		text-align: center;
	}
}

.power-ribbon-top-left {
	span {
		 inset-inline-end: -5px;
		top: 1px;
		transform: rotate(-45deg);
		i {
			transform: rotate(45deg);
			padding-block-start: 2px;
			padding-inline-start: 7px;
		}
	}
	top: -6px;
	inset-inline-start: -9px;
}

.ribbon {
	&::after,
	&::before {
		position: absolute;
		z-index: -1;
		content: '';
		display: block;
		border: 5px solid;
	}
	span {
		position: absolute;
		display: block;
		width: 225px;
		padding: 8px 0;
		box-shadow: 0 5px 10px $black-1;
		color: $white;
		text-shadow: 0 1px 1px $black-2;
		text-transform: capitalize;
		text-align: center;
	}
}

.ribbon-top-left {
	top: -10px;
	inset-inline-start: -10px;
	&::after {
		border-top-color: transparent;
		border-inline-start-color: transparent;
	}
	&::before {
		border-top-color: transparent;
		border-inline-start-color: transparent;
		top: 0;
		 inset-inline-end: 50px;
	}
	&::after {
		bottom: 50px;
		inset-inline-start: 0;
	}
	span {
		 inset-inline-end: -8px;
		top: 30px;
		transform: rotate(-45deg);
	}
}

.ribbon-top-right {
	top: -10px;
	 inset-inline-end: -10px;
	&::after {
		border-top-color: transparent;
		border-inline-end-color: transparent;
	}
	&::before {
		border-top-color: transparent;
		border-inline-end-color: transparent;
		top: 0;
		inset-inline-start: 36px;
	}
	&::after {
		bottom: 36px;
		 inset-inline-end: 0;
	}
	span {
		inset-inline-start: -8px;
		top: 30px;
		transform: rotate(45deg);
	}
}

.power-ribbon-top-right {
	span {
		inset-inline-start: -5px;
		top: 1px;
		transform: rotate(45deg);
		i {
			transform: rotate(-45deg);
			padding-block-start: 2px;
			padding-inline-end: 7px;
		}
		inset-inline-start: -5px;
		top: 1px;
		transform: rotate(45deg);
	}
	top: -6px;
	 inset-inline-end: -9px;
}


/*--Ribbon1--*/

.ribbon-1 {
	position: absolute;
	top: -6.1px;
	 inset-inline-end: 10px;
	color: $white;
	&:after {
		position: absolute;
		content: "";
		width: 0;
		height: 0;
		border-inline-start: 20px solid transparent;
		border-inline-end: 24px solid transparent;
		z-index: 99;
	}
	span {
		position: relative;
		display: block;
		text-align: center;
		font-size: 14px;
		line-height: 1;
		padding: 12px 8px 10px;
		border-start-end-radius: 8px;
		z-index: 99;
		&:after {
			position: absolute;
			content: "";
		}
		&:before {
			position: absolute;
			content: "";
			height: 6px;
			width: 6px;
			inset-inline-start: -6px;
			top: 0;
		}
		&:after {
			height: 6px;
			width: 8px;
			inset-inline-start: -8px;
			top: 0;
			border-radius: 8px 8px 0 0;
		}
	}
	i {
		color: $white;
		font-size: 16px;
		padding-inline-start: 7px;
		padding-inline-end: 7px;
	}
}


/*--Ribbon2--*/

.ribbon-2 {
	position: absolute;
	top: -6.1px;
	 inset-inline-end: 10px;
	color: $white;
	&:after {
		position: absolute;
		content: "";
		width: 0;
		height: 0;
		border-inline-start: 20px solid transparent;
		border-inline-end: 24px solid transparent;
		border-block-start: 13px solid #520995;
		z-index: 99;
	}
	span {
		position: relative;
		display: block;
		text-align: center;
		background: #520995;
		font-size: 14px;
		line-height: 1;
		padding: 12px 8px 10px;
		border-start-end-radius: 8px;
		z-index: 99;
		&:after {
			position: absolute;
			content: "";
		}
		&:before {
			position: absolute;
			content: "";
			height: 6px;
			width: 6px;
			inset-inline-start: -6px;
			top: 0;
			background: #520995;
		}
		&:after {
			height: 6px;
			width: 8px;
			inset-inline-start: -8px;
			top: 0;
			border-radius: 8px 8px 0 0;
			background: #520995;
		}
	}
	i {
		color: $white;
		font-size: 16px;
		padding-inline-start: 7px;
		padding-inline-end: 7px;
	}
}


/*--Ribbon3--*/

.ribbon-3 {
	position: absolute;
	top: -6.1px;
	 inset-inline-end: 10px;
	color: $white;
	&:after {
		position: absolute;
		content: "";
		width: 0;
		height: 0;
		border-inline-start: 20px solid transparent;
		border-inline-end: 24px solid transparent;
		border-block-start: 13px solid #0ccad8;
		z-index: 99;
	}
	span {
		position: relative;
		display: block;
		text-align: center;
		background: #0ccad8;
		font-size: 14px;
		line-height: 1;
		padding: 12px 8px 10px;
		border-start-end-radius: 8px;
		z-index: 99;
	}
}

.ribbon-1 span:after {
	position: absolute;
	content: "";
}

.ribbon-3 {
	span {
		&:before {
			position: absolute;
			content: "";
			height: 6px;
			width: 6px;
			inset-inline-start: -6px;
			top: 0;
			background: #0ccad8;
		}
		&:after {
			height: 6px;
			width: 8px;
			inset-inline-start: -8px;
			top: 0;
			border-radius: 8px 8px 0 0;
			background: #0ccad8;
		}
	}
	i {
		color: $white;
		font-size: 16px;
		padding-inline-start: 7px;
		padding-inline-end: 7px;
	}
}

/*----------------- Ribbon Stylesheet -----------------*/